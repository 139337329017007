import { faPen, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect } from "react";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import { useDispatch, useSelector } from 'react-redux';
import { Link } from "react-router-dom";
import { Button, Col, Container, Row } from "reactstrap";
import AppBreadcrumb from "../../components/ui/AppBreadcrumb";
import { CREATE_ANNOUNCEMENT, DELETE_ANNOUNCEMENT, EDIT_ANNOUNCEMENT } from '../../navigation/ROUTE_CONSTANTS';
import { getAdminListAnnouncements } from "../../redux/actions/announcements.actions";
import { setNavigateTo } from "../../redux/actions/ui.actions";
import { ANNOUNCEMENT_SEVERITY_UI_NAMES } from "../../redux/reducers/announcements.reducers";
import { customStyles } from "../../theme/assets/dataTableCustomStyles";

const ClientAdminAnnouncements = () => {

    const dispatch = useDispatch();

    const adminAnnouncementList = useSelector((state) => state.adminAnnouncements.adminAnnouncementList);
    const isRoleClientAdmin = useSelector((state) => state.users.isRoleClientAdmin);

    const createAnnouncement = () => {
        dispatch(setNavigateTo(CREATE_ANNOUNCEMENT));
    }

    useEffect(() => {
        document.title = process.env.REACT_APP_SITE_TITLE + ' - Announcements';
        dispatch(getAdminListAnnouncements());
    }, [dispatch]);

    const columns = [
        {
            name: 'Sent at',
            selector: (row) => { return new Date(row.created_at).toLocaleString() },
            sortable: true,
            width: '20%'
        },
        {
            name: 'Title',
            selector: row => row.title,
            sortable: true,
            width: '20%'
        },
        {
            name: 'Type',
            selector: (row) => ANNOUNCEMENT_SEVERITY_UI_NAMES[row.severity],
            sortable: true,
            width: '10%'
        },
        {
            name: 'Message',
            selector: (row) => <div style={{ whiteSpace: 'normal' }}>{row.message}</div>,
            sortable: true,
            width: '40%'
        },
        {
            name: 'Actions',
            center: true,
            cell: (row) => <>
                <Link className='text-dark btn btn-link btn-light' to={EDIT_ANNOUNCEMENT.replace(':announcementId', row.id)} >
                    <FontAwesomeIcon icon={faPen} title="Edit" />
                </Link>
                <Link className='text-dark btn btn-link btn-light' to={DELETE_ANNOUNCEMENT.replace(':announcementId', row.id)} >
                    <FontAwesomeIcon icon={faTrash} title="Delete" />
                </Link>
            </>,
            width: '10%'
        }
    ]

    return (
        <Container className="min-vh-72">
            <Row>
                <Col>
                    <AppBreadcrumb headText="Announcements" />
                </Col>
            </Row>
            <Row className="pb-1 mb-2 mt-2">
                <Col md={3}>
                    <h3 className="mb-4 mt-2">Announcements</h3>
                </Col>
                <Col md={{ offset: 6, size: 3 }}>
                    {isRoleClientAdmin && <Button color={'success'} onClick={createAnnouncement} block>
                        Create new announcement
                    </Button>}
                </Col>
            </Row>
            <Row className="mb-5">
                <Col>
                    {adminAnnouncementList && adminAnnouncementList.length === 0 ?
                        (
                            <div className="alert alert-primary mt-2">
                                Empty list
                            </div>
                        ) : (
                            <DataTableExtensions filter={true} export={false} print={false} filterPlaceholder="Search announcement"
                                columns={columns}
                                data={adminAnnouncementList} >
                                <DataTable
                                    columns={columns}
                                    data={adminAnnouncementList}
                                    noHeader
                                    pagination
                                    highlightOnHover
                                    customStyles={customStyles}
                                />
                            </DataTableExtensions>
                        )}
                </Col>
            </Row>
        </Container>
    )
}

export default ClientAdminAnnouncements;