import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { EXERCISES } from '../../navigation/ROUTE_CONSTANTS';
import { createExercise, getExercises, getOneExercise, removeExercise, updateExercise } from '../../services/exercisesService';
import { genericError } from '../actions/error.actions';
import {
    CREATE_EXERCISE,
    DELETE_EXERCISE,
    getExercise,
    getExerciseSuccess,
    getListExercisesSuccess,
    GET_EXERCISE,
    GET_LIST_EXERCISES,
    UPDATE_EXERCISE
} from '../actions/exercises.actions';
import { disableOverlay, enableOverlay, setFormSubmittedSuccess, setNavigateTo, setToast } from '../actions/ui.actions';
import { TOAST_SEVERITY_SUCCESS } from '../reducers/ui.reducer';

/**
 * getListExercisesEffect
 */
function* getListExercisesEffect() {
    yield put(enableOverlay('Loading exercises ...'));
    try {
        const exercisesList = yield call(getExercises);
        if (exercisesList) {
            yield put(getListExercisesSuccess(exercisesList));
        }
    } catch (err) {
        console.log('error:', err);
        yield put(genericError(err));
    }
    yield put(disableOverlay());
}

/**
 * getOneExerciseEffect
 */
function* getOneExerciseEffect({ payload }) {
    yield put(enableOverlay('Loading exercise ...'));
    try {
        const exercise = yield call(getOneExercise, payload);
        if (exercise) {
            yield put(getExerciseSuccess(exercise));
        }
    } catch (err) {
        console.log('error:', err);
        yield put(genericError(err));
    }
    yield put(disableOverlay());
}

/**
 * createExercisesEffect
 */
function* createExerciseEffect({ payload }) {
    try {
        yield put(enableOverlay('Creating exercise ...'));
        const exercise = yield call(createExercise, payload);
        if (exercise) {
            yield put(setToast({
                severity: TOAST_SEVERITY_SUCCESS,
                detail: 'The exercise has been created!',
            }));
            yield put(setNavigateTo(EXERCISES));
        }
    } catch (err) {
        console.log('error:', err);
        yield put(genericError(err));
    }
    yield put(disableOverlay());
}

/**
 * updateExerciseEffect
 */
function* updateExerciseEffect({ payload }) {
    try {
        yield put(enableOverlay('Updating exercise ...'));
        const exercise = yield call(updateExercise, payload);
        if (exercise) {
            yield put(setToast({
                severity: TOAST_SEVERITY_SUCCESS,
                detail: 'The exercise has been updated!',
            }));
            yield put(setFormSubmittedSuccess(true));
            yield put(getExercise(exercise.id));
        }
    } catch (err) {
        console.log('error:', err);
        yield put(genericError(err));
    }
    yield put(disableOverlay());
}

/**
 * deleteExerciseEffect
 */
function* deleteExerciseEffect({ payload }) {
    try {
        yield put(enableOverlay('Deleting exercise ...'));
        const exercise = yield call(removeExercise, payload);
        if (exercise) {
            yield put(setToast({
                severity: TOAST_SEVERITY_SUCCESS,
                detail: 'The exercise has been deleted!',
            }));
            yield put(setNavigateTo(EXERCISES));
        }
    } catch (err) {
        console.log('error:', err);
        yield put(genericError(err));
    }
    yield put(disableOverlay());
}

export function* watchGetListExercises() {
    yield takeEvery(GET_LIST_EXERCISES, getListExercisesEffect);
}

export function* watchGetOneExercise() {
    yield takeEvery(GET_EXERCISE, getOneExerciseEffect);
}

export function* watchCreateExercise() {
    yield takeEvery(CREATE_EXERCISE, createExerciseEffect);
}

export function* watchUpdateExercise() {
    yield takeEvery(UPDATE_EXERCISE, updateExerciseEffect);
}

export function* watchDeleteExercise() {
    yield takeEvery(DELETE_EXERCISE, deleteExerciseEffect);
}

function* exercisesSaga() {
    yield all([
        fork(watchGetListExercises),
        fork(watchGetOneExercise),
        fork(watchCreateExercise),
        fork(watchUpdateExercise),
        fork(watchDeleteExercise),
    ]);
}

export default exercisesSaga;
