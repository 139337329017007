import { all, call, delay, fork, put, takeEvery } from "redux-saga/effects";
import {
    CREATE_USER,
    createUserSuccess,
    RESET_PASSWORD,
    RESET_PASSWORD_CONFIRMATION,
    UPDATE_USER_PROFILE,
    retrieveFacultiesLoggedInSuccess,
    RETRIEVE_FACULTIES_LOGGED_IN,
    GET_USER,
    getUserSuccess,
    GET_FACULTY_LIST,
    getFacultyListSuccess,
    GET_USER_SUCCESS,
    getUser,
    getListStudentsSuccess,
    GET_LIST_STUDENTS,
} from "../actions/users.actions";
import {
    closeNotificationModal,
    disableOverlay,
    enableOverlay,
    openNotificationModal,
    setToast,
} from "../actions/ui.actions";
import { genericError } from "../actions/error.actions";
import {
    checkUserRegistration,
    getFacultyList,
    getLoggedUser,
    getStudentsList,
    postUser,
    resetPasswordByEmail,
    resetPasswordCredentials,
    retrieveFacultiesLoggedIn,
    updateUser,
} from "../../services/userService";
import { ICON_SUCCESS } from "../../components/ui/NotificationModal";
import { loginSuccess } from "../actions/auth.actions";
import { LOGIN_FORM } from "../../navigation/ROUTE_CONSTANTS";
import { USER_ROLE_STUDENT } from "../reducers/auth.reducer";
import { getMyCubicle, resetMyCubicle } from "../actions/my_cubicle.actions";
import { getProcedures } from "../actions/procedures.actions";
import { setDefaultTimeZone } from "../../utils/date_time";
import { TOAST_SEVERITY_SUCCESS } from '../reducers/ui.reducer';
import { getListAnnouncements } from "../actions/announcements.actions";

/**
 * getUserEffect
 */
function* getUserEffect() {
    try {
        const userResult = yield call(getLoggedUser);
        if (userResult) {
            localStorage.setItem('ca-user', JSON.stringify(userResult));
            yield put(getUserSuccess(userResult));
            yield put(getListAnnouncements());
            if (userResult.role === USER_ROLE_STUDENT) {
                yield put(getMyCubicle());
            } else {
                yield put(resetMyCubicle());
            }
            yield put(getProcedures());
        }
    } catch (err) {
        console.log('error:', err);
        yield put(genericError(err));
    }
}

/**
 * getUserSuccessEffect
 */
function* getUserSuccessEffect() {
    // TODO Must be replaced by Client.timezone (?) from Backend
    const defaultTimeZone = 'America/Los_Angeles';
    try {
        yield call(setDefaultTimeZone, defaultTimeZone);
    } catch (err) {
        console.log('error:', err);
        yield put(genericError(err));
    }
}

/**
 * createUserEffect
 * @param {*} param0
 */
function* createUserEffect({ payload }) {
    try {
        yield put(enableOverlay('Checking email...'));
        const email = { email: payload.user.email };
        const result = yield call(checkUserRegistration, email);
        if (result) {
            yield put(disableOverlay());
            yield put(enableOverlay('Creating new account...'));
            const created = yield call(postUser, payload.user);
            if (created) {
                yield put(loginSuccess({
                    access: created.tokens.access,
                    refresh: created.tokens.refresh,
                }));
                localStorage.setItem('ca-access', created.tokens.access);
                localStorage.setItem('ca-refresh', created.tokens.refresh);
                localStorage.setItem('ca-user', JSON.stringify(created.user));
                yield put(disableOverlay());
                yield put(openNotificationModal({
                    title: 'Success!',
                    message: 'Your account was created!',
                    iconType: ICON_SUCCESS,
                }));
                yield delay(3000);
                yield put(closeNotificationModal());
                yield put(createUserSuccess(true));
            }
        }
    } catch (err) {
        console.log('error:', err);
        yield put(genericError(err));
        yield put(disableOverlay());
    }
}

/**
 * updateUserProfileEffect
 */
function* updateUserProfileEffect({ payload }) {
    yield put(enableOverlay('Updating profile ...'));
    try {
        const updateProfile = yield call(updateUser, payload);
        if (updateProfile) {
            yield put(setToast({
                severity: TOAST_SEVERITY_SUCCESS,
                summary: 'Success!',
                detail: 'You profile was updated!',
            }));
            yield put(getUser());
        }
    } catch (err) {
        console.log('error:', err);
        yield put(genericError(err));
    }
    yield put(disableOverlay());
}

/**
 * resetPasswordEffect
 * @param {*} param0
 */
function* resetPasswordEffect({ payload }) {
    try {
        yield put(enableOverlay('Sending email...'));
        const sending = yield call(resetPasswordByEmail, payload.email);
        if (sending) {
            yield put(disableOverlay());
            yield put(openNotificationModal({
                title: 'Success!',
                message: 'A reset password email was send!',
                iconType: ICON_SUCCESS,
            }));
            yield delay(3000);
            yield put(closeNotificationModal());
            payload.history.push(LOGIN_FORM);
        }
    } catch (err) {
        console.log('error:', err);
        yield put(genericError(err));
        yield put(disableOverlay());
    }
}

/**
 * resetPasswordConfirmationEffect
 * @param {*} param0
 */
function* resetPasswordConfirmationEffect({ payload }) {
    try {
        yield put(enableOverlay('Resetting password...'));
        const reset = yield call(resetPasswordCredentials, payload.newCredentials);
        if (reset) {
            yield put(disableOverlay());
            yield put(openNotificationModal({
                title: 'Success!',
                message: 'Your password was successfully changed!',
                iconType: ICON_SUCCESS,
            }));
            yield delay(3000);
            yield put(closeNotificationModal());
            payload.history.push(LOGIN_FORM);
        }
    } catch (err) {
        console.log('error:', err);
        yield put(genericError(err));
        yield put(disableOverlay());
    }
}

/**
 * getFacultyListEffect
 */
function* getFacultyListEffect() {
    try {
        const facultyList = yield call(getFacultyList);
        if (facultyList) {
            yield put(getFacultyListSuccess(facultyList));
        }
    } catch (err) {
        console.log('error:', err);
        yield put(genericError(err));
    }
}

/**
 * getStudentsListEffect
 */
 function* getStudentsListEffect() {
    try {
        const studentsList = yield call(getStudentsList);
        if (studentsList) {
            yield put(getListStudentsSuccess(studentsList));
        }
    } catch (err) {
        console.log('error:', err);
        yield put(genericError(err));
    }
}

/**
 * retrieveFacultiesLoggedIn
 */
function* retrieveFacultiesLoggedInEffect({ payload }) {
    try {
        const facultiesLoggedIn = yield call(retrieveFacultiesLoggedIn, payload);
        yield put(retrieveFacultiesLoggedInSuccess(facultiesLoggedIn));
    } catch (err) {
        console.log('error:', err);
        yield put(genericError(err));
    }
}

/**
 * Watchers
 */
export function* watchGetUser() {
    yield takeEvery(GET_USER, getUserEffect);
}

export function* watchGetUserSuccess() {
    yield takeEvery(GET_USER_SUCCESS, getUserSuccessEffect);
}

export function* watchCreateUser() {
    yield takeEvery(CREATE_USER, createUserEffect);
}

export function* watchUpdateUserProfile() {
    yield takeEvery(UPDATE_USER_PROFILE, updateUserProfileEffect);
}

export function* watchResetPassword() {
    yield takeEvery(RESET_PASSWORD, resetPasswordEffect);
}

export function* watchResetPasswordConfirmation() {
    yield takeEvery(RESET_PASSWORD_CONFIRMATION, resetPasswordConfirmationEffect);
}

export function* watchGetFacultyList() {
    yield takeEvery(GET_FACULTY_LIST, getFacultyListEffect);
}

export function* watchGetStudentsList() {
    yield takeEvery(GET_LIST_STUDENTS, getStudentsListEffect);
}

export function* watchRetrieveFacultiesLoggedInEffect() {
    yield takeEvery(
        RETRIEVE_FACULTIES_LOGGED_IN,
        retrieveFacultiesLoggedInEffect
    );
}

function* usersSaga() {
    yield all([
        fork(watchGetUser),
        fork(watchGetUserSuccess),
        fork(watchCreateUser),
        fork(watchUpdateUserProfile),
        fork(watchResetPassword),
        fork(watchResetPasswordConfirmation),
        fork(watchGetFacultyList),
        fork(watchRetrieveFacultiesLoggedInEffect),
        fork(watchGetStudentsList)
    ]);
}

export default usersSaga;
