import React, { useEffect } from 'react'
import { Container, Row, Col, Button } from 'reactstrap';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import AppBreadcrumb from '../../components/ui/AppBreadcrumb';
import { setNavigateTo } from '../../redux/actions/ui.actions';
import { getExercise, deleteExercise } from '../../redux/actions/exercises.actions';
import { EXERCISES } from '../../navigation/ROUTE_CONSTANTS';


const ClientAdminExerciseDelete = () => {

    const dispatch = useDispatch();

    const { exerciseId } = useParams();
    const selectedExercise = useSelector((state) => state.exercises.selectedExercise);

    useEffect(() => {
        document.title = process.env.REACT_APP_SITE_TITLE + ' - Delete exercise ';
    });

    useEffect(() => {
        if (exerciseId) {
            dispatch(getExercise(exerciseId));
        }
    }, [exerciseId, dispatch]);

    const handleCancel = () => {
        dispatch(setNavigateTo(EXERCISES));
    }

    const handleDeleteExercise = () => {
        dispatch(deleteExercise(exerciseId))
    }

    return (<Container className='mb-5 pb-5'>
        <Row>
            <Col>
                <AppBreadcrumb headText="Delete Exercise" crumbList={[{ linkTo: EXERCISES, label: 'Exercise' }]} />
            </Col>
        </Row>
        <Row>
            <Col>
                <div className='vstack col-md-4 mx-auto mt-4 text-center'>
                    <h4 className='pt-2 pb-3 text-center'>Delete Exercise</h4>
                    <h5 className='mt-3 mb-4 pt-2 pb-2'>Are you sure you want to remove this exercise?</h5>
                    <p>Name:<strong> {selectedExercise?.name}</strong></p>
                    <p>Shortname:<strong> {selectedExercise?.short_name}</strong></p>
                </div>
            </Col>
        </Row>
        <Row>
            <p className="mt-4 mb-4 text-center">
                <Button color={'success'} className=" me-2" onClick={handleDeleteExercise}>
                    Delete Exercise
                </Button>
                <Button color={'secondary'} onClick={handleCancel} >
                    Cancel
                </Button>
            </p>
        </Row>
    </Container>)
}

export default ClientAdminExerciseDelete;