import React, { useEffect, useState } from 'react'
import { Container, Row, Col, Button } from 'reactstrap';
import AppBreadcrumb from '../../components/ui/AppBreadcrumb';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setNavigateTo } from '../../redux/actions/ui.actions';
import { CLIENT_ADMIN_ANNOUNCEMENTS } from '../../navigation/ROUTE_CONSTANTS';
import { getOneAnnouncement } from '../../services/announcementsService';
import { deleteAnnouncement } from '../../redux/actions/announcements.actions';

function ClientAdminAnnouncementDelete() {

    const dispatch = useDispatch();
    const { announcementId } = useParams();
    const [announcement, setAnnouncement] = useState({});

    useEffect(() => {
        document.title = process.env.REACT_APP_SITE_TITLE + ' - Delete announcement ';
        getOneAnnouncement(announcementId).then((data) => {
            setAnnouncement(data);
        })
    }, [announcementId]);

    const handleCancel = () => {
        dispatch(setNavigateTo(CLIENT_ADMIN_ANNOUNCEMENTS));
    }

    const handleDelete = () => {
        dispatch(deleteAnnouncement(announcementId));
    }

    return (
        <Container className='mb-5 pb-5'>
            <Row>
                <Col>
                    <AppBreadcrumb headText="Delete Announcement" crumbList={[{ linkTo: CLIENT_ADMIN_ANNOUNCEMENTS, label: 'Announcements' }]} />
                </Col>
            </Row>
            <Row>
                <Col>
                    <div className='vstack col-md-4 mx-auto mt-4 text-center'>
                        <h4 className='pt-2 pb-3 text-center'>Delete announcement</h4>
                        <h5 className='mt-3 mb-4 pt-2 pb-2'>Are you sure you want to remove this announcement?</h5>
                        <p>Title: <strong>{announcement.title}</strong></p>
                        <p>Message: <strong>{announcement.message}</strong></p>
                    </div>
                </Col>
            </Row>
            <Row>
                <p className="mt-4 mb-4 text-center">
                    <Button color={'success'} className="me-2" onClick={handleDelete}>
                        Delete Announcement
                    </Button>
                    <Button color={'secondary'} onClick={handleCancel} >
                        Cancel
                    </Button>
                </p>
            </Row>
        </Container>
    )
}

export default ClientAdminAnnouncementDelete