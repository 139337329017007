import axios from 'axios';
import { closeModalProgress, updateModalProgress } from '../redux/actions/ui.actions';
import { API_FILES_UPLOADS, API_FILE_DETAILS } from "./API_CONSTANTS";

export const saveAndUpdateFiles = (files, dispatch) => {
    return new Promise(async (resolve, reject) => {
        let filesSaved = [];
        for (let file of files) {
            const mimeType = file.file.type || 'application/octet-stream';
            const dataAWS = await getUrlForPostAWS({ file_name: file.file.name, mime_type: mimeType });

            let formData = new FormData();
            // Append order is important
            formData.append('acl', dataAWS.presigned_post.fields.acl);
            formData.append('Content-Type', mimeType);
            formData.append('key', dataAWS.presigned_post.fields.key);
            formData.append('AWSAccessKeyId', dataAWS.presigned_post.fields.AWSAccessKeyId);
            formData.append('policy', dataAWS.presigned_post.fields.policy);
            formData.append('signature', dataAWS.presigned_post.fields.signature);
            formData.append('file', file.file);

            try {
                await saveFileOnAWS(dataAWS.presigned_post.url, formData, file.file.name, dispatch);
                await confirmFileSaved(dataAWS.file_uuid);
                filesSaved.push({
                    file_uuid: dataAWS.file_uuid,
                    id: file.id,
                    uploaded: true,
                });
            } catch (err) {
                console.log('ERROR', err);
                reject(err);
            }
        }

        dispatch(closeModalProgress());
        resolve(filesSaved);
    })
}

export const getUrlForPostAWS = (file) => {
    return new Promise((resolve, reject) => {
        try {
            axios.post(API_FILES_UPLOADS, file)
                .then((res) => {
                    console.log("Post url AWS: ", res);
                    resolve(res.data);
                })
                .catch((err) => {
                    console.log("Post url AWS error: ", err);
                    reject("Error getting url from AWS");
                });
        } catch (error) {
            console.error("Error getting url from AWS!", error);
            reject("Error getting url from AWS!");
        }
    });
}

export const saveFileOnAWS = (url, formData, fileName, dispatch) => {

    const axiosConfig = {
        skipAddSessionInterceptor: true,
        onUploadProgress: ({ loaded,  total }) => {
            let percentage = 0;
            if (total > 0) {
                percentage = Math.floor((loaded * 100) / total);
            }
            dispatch(updateModalProgress({
                message: `Uploading ${fileName}...`,
                percentage,
            }));
        }
    };

    return new Promise((resolve, reject) => {
        try {
            axios.post(url, formData, axiosConfig)
                .then((res) => {
                    console.log("File uploaded to AWS: ", res);
                    resolve(res.data);
                })
                .catch((err) => {
                    console.log("File upload to AWS error: ", err);
                    reject("File upload to AWS error");
                });
        } catch (error) {
            console.error("File upload to AWS error!", error);
            reject("File upload to AWS error!");
        }
    });
}

export const confirmFileSaved = (id) => {
    return new Promise((resolve, reject) => {
        try {
            axios.patch(API_FILES_UPLOADS + '/' + id)
                .then((res) => {
                    console.log("Uploaded file confirmed:", res);
                    resolve(res.data);
                })
                .catch((err) => {
                    console.log("Uploaded file confirmation error: ", err);
                    reject("Uploaded file confirmation error");
                });
        } catch (error) {
            console.error("Error updating file information!", error);
            reject("Error updating file information!");
        }
    });
}

export const getOneFile = (fileUuid) => {
    return new Promise((resolve, reject) => {
        try {
            axios.get(API_FILE_DETAILS + '/' + fileUuid)
                .then((res) => {
                    console.log("One file retrieved:", res);
                    resolve(res.data);
                })
                .catch((err) => {
                    console.log("Get one file error: ", err);
                    reject("Get one file error");
                });
        } catch (error) {
            console.error("Get one file error!", error);
            reject("Get one file error!");
        }
    });
}