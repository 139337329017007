import axios from 'axios';
import { buildPaginationUrlParams } from '../utils/index';
import { API_FACULTY_CLASSES, API_FACULTY_CLASSES_STUDENTS } from './API_CONSTANTS';

export const getClasses = (paginationParams = {}) => {
    paginationParams = {
        ...paginationParams,
        page: 1,
        limit: 99,
    };

    const url = API_FACULTY_CLASSES + buildPaginationUrlParams(paginationParams);

    return new Promise((resolve, reject) => {
        try {
            axios.get(url)
                .then((res) => {
                    console.log('Classes loaded: ', res);
                    resolve(res.data);
                })
                .catch((err) => {
                    console.log('Get classes error: ', err);
                    reject('Get classes error');
                });
        } catch (error) {
            console.error('Get classes error: ', error);
            reject('Get classes error');
        }
    });
};

export const getOneClass = (classId) => {
    const url = API_FACULTY_CLASSES + '/' + classId;

    return new Promise((resolve, reject) => {
        try {
            axios.get(url)
                .then((res) => {
                    console.log('Class loaded: ', res);
                    resolve(res.data);
                })
                .catch((err) => {
                    console.log('Get one class error: ', err);
                    reject('Get one class error');
                });
        } catch (error) {
            console.error('Get one class error: ', error);
            reject('Get one class error');
        }
    });
}

export const createClass = (data) => {
    const url = API_FACULTY_CLASSES;

    return new Promise((resolve, reject) => {
        try {
            axios.post(url, data)
                .then((res) => {
                    console.log('class created: ', res);
                    resolve(res.data);
                })
                .catch((err) => {
                    console.log('Create class error: ', err);
                    reject('Create class error');
                });
        } catch (error) {
            console.error('Create class error: ', error);
            reject('Create class error');
        }
    });
}

export const addStudentToClass = (data) => {
    const url = API_FACULTY_CLASSES_STUDENTS;
    return new Promise((resolve, reject) => {
        try {
            axios.post(url, data).then((res) => {
                console.log('Student added into class: ', res);
                resolve(res.data);
            })
            .catch((err) => {
                console.log('Add student to class error: ', err);
                reject('Add student to class error');
            });
        } catch (error) {
            console.error('Add student to class error: ', error);
            reject('Add student to class error');
        }
    })
}

export const removeStudentFromClass = (studentId) => {
    const url = API_FACULTY_CLASSES_STUDENTS + '/' + studentId;
    return new Promise((resolve, reject) => {
        try {
            axios.delete(url)
                .then((res) => {
                    console.log('Student removed: ', res);
                    resolve(res);
                })
                .catch((err) => {
                    console.log('Remove student error: ', err);
                    reject('Remove student error');
                });
        } catch (error) {
            console.error('Remove student error: ', error);
            reject('Remove student error');
        }
    });
}

export const updateStudentToClass = (data) => {
    const url = API_FACULTY_CLASSES_STUDENTS + '/' + data.id;

    return new Promise((resolve, reject) => {
        try {
            axios.put(url, { class_grade: data.class_grade })
                .then((res) => {
                    console.log('Student updated: ', res);
                    resolve(res.data);
                })
                .catch((err) => {
                    console.log('Update student error: ', err);
                    reject('Update student error');
                });
        } catch (error) {
            console.error('Update student error: ', error);
            reject('Update class error');
        }
    });
}

export const updateClass = (data) => {
    const url = API_FACULTY_CLASSES + '/' + data.id;

    return new Promise((resolve, reject) => {
        try {
            axios.put(url, data)
                .then((res) => {
                    console.log('Class updated: ', res);
                    resolve(res.data);
                })
                .catch((err) => {
                    console.log('Update class error: ', err);
                    reject('Update class error');
                });
        } catch (error) {
            console.error('Update class error: ', error);
            reject('Update class error');
        }
    });
}

export const removeClass = (classId) => {
    const url = API_FACULTY_CLASSES + '/' + classId;

    return new Promise((resolve, reject) => {
        try {
            axios.delete(url)
                .then((res) => {
                    console.log('Class removed: ', res);
                    resolve(res);
                })
                .catch((err) => {
                    console.log('Remove class error: ', err);
                    reject('Remove class error');
                });
        } catch (error) {
            console.error('Remove class error: ', error);
            reject('Remove class error');
        }
    });
}
