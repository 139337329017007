import { ErrorMessage, Field, Form, Formik } from 'formik';
import { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Button, Card, CardBody, Col, Container, FormGroup, Input, Label, Row } from 'reactstrap';
import * as Yup from 'yup';
import AttachmentLinks from '../../../components/general/AttachmentLinks';
import AppBreadcrumb from '../../../components/ui/AppBreadcrumb';
import DragAndDrop from '../../../components/ui/DragAndDrop';
import SectionTitle from '../../../components/ui/SectionTitle';
import { EXERCISES, EXERCISES_DETAILS, EXERCISES_PROCEDURES, PROCEDURES } from '../../../navigation/ROUTE_CONSTANTS';
import { clearSelectedExercise, getExercise } from '../../../redux/actions/exercises.actions';
import { clearSelectedProcedure, createProcedure, getSelectedProcedure, updateProcedure } from '../../../redux/actions/procedures.actions';
import { setFormSubmittedSuccess, setNavigateTo } from '../../../redux/actions/ui.actions';
import { buildExerciseLabel, buildProcedureLabel } from '../../../utils';


function ProcedureForm() {
    const dispatch = useDispatch();
    const { procedureId, exerciseId } = useParams();

    const selectedProcedure = useSelector((state) => state.procedures.selectedProcedure);
    const selectedExercise = useSelector((state) => state.exercises.selectedExercise);
    const isAllowedToEdit = useSelector((state) => state.users.isRoleClientAdmin);
    const formSubmittedSuccess = useSelector(state => state.ui.formSubmittedSuccess);

    const [editMode, setEditMode] = useState(false);
    const [pageTitle, setPageTitle] = useState('');
    const [uploading, setUploading] = useState(false);
    const [formSubmitStarted, setFormSubmitStarted] = useState(false);

    useEffect(() => {
        dispatch(setFormSubmittedSuccess(false));
        if (procedureId) {
            dispatch(getSelectedProcedure(procedureId));
            setEditMode(false);
        } else {
            dispatch(clearSelectedProcedure());
            setEditMode(true);
        }
    }, [procedureId, dispatch]);

    useEffect(() => {
        if (!exerciseId) {
            dispatch(clearSelectedExercise());
        } else if (!selectedExercise.name) {
            dispatch(getExercise(exerciseId));
        }
    }, [exerciseId, dispatch, selectedExercise.name]);

    useEffect(() => {
        if (procedureId) {
            if (editMode) {
                setPageTitle('Edit ' + buildProcedureLabel(selectedProcedure));
            } else {
                setPageTitle(buildProcedureLabel(selectedProcedure));
            }
        } else {
            setPageTitle('Create Procedure');
        }
    }, [editMode, procedureId, selectedProcedure]);

    useEffect(() => {
        document.title = process.env.REACT_APP_SITE_TITLE + ' - ' + pageTitle;
    }, [pageTitle]);

    const startUploadAndSubmit = (e) => {
        e.preventDefault();
        setUploading(true);
    }

    useEffect(() => {
        if (formSubmittedSuccess && formSubmitStarted) {
            if (exerciseId && !procedureId) {
                dispatch(setNavigateTo(EXERCISES_PROCEDURES.replace(':exerciseId', exerciseId)));
            } else {
                dispatch(setNavigateTo(PROCEDURES));
            }
        }
    }, [dispatch, exerciseId, formSubmitStarted, formSubmittedSuccess, procedureId]);

    const handleSaveProcedure = (formData) => {
        setFormSubmitStarted(true);
        formData.difficulty = parseInt(formData.difficulty);
        formData.short_name = formData.short_name || null;
        if (procedureId) {
            formData.id = procedureId;
            dispatch(updateProcedure(formData));
        } else {
            dispatch(createProcedure(formData));
        }
    }

    return (
        <Container className='mb-5 pb-5'>
            <Row>
                <Col>
                </Col>
            </Row>
            <Row>
                <Col>
                    {exerciseId &&
                        <AppBreadcrumb headText={pageTitle} crumbList={[
                            { linkTo: EXERCISES, label: 'Exercises' },
                            { linkTo: EXERCISES_DETAILS.replace(':exerciseId', exerciseId), label: buildExerciseLabel(selectedExercise) }
                        ]}
                        />
                    }
                    {!exerciseId &&
                        <AppBreadcrumb headText={pageTitle} crumbList={[{ linkTo: PROCEDURES, label: 'Procedures' }]} />
                    }
                </Col>
            </Row>
            <Formik
                enableReinitialize={true}
                initialValues={{
                    name: selectedProcedure.name || '',
                    short_name: selectedProcedure.short_name || '',
                    need_details: selectedProcedure.need_details || false,
                    description: selectedProcedure.description || '',
                    difficulty: selectedProcedure.difficulty || 0,
                    resources: selectedProcedure.resources || [],
                }}
                validationSchema={Yup.object({
                    name: Yup.string()
                        .min(1, 'Name must be at least 1 characters length.')
                        .max(120, 'Name must be smaller than 120 characters length.')
                        .required('Please enter a name'),
                    short_name: Yup.string()
                        .max(32, 'Name must be smaller than 32 characters length.')
                        .nullable()
                        .notRequired(),
                    difficulty: Yup.number()
                        .min(0, 'Difficulty must be a positive number.')
                        .nullable()
                        .notRequired()
                })}
                onSubmit={handleSaveProcedure}
            >{({ submitForm, setFieldValue }) => (
                <Form>
                    <Row className='mb-2'>
                        {editMode &&
                            <Fragment>
                                <Col md={6}>
                                    <Field type="text" name="name" placeholder="Name" as={Input} />
                                    <ErrorMessage component="div" className="text-danger" name="name" />
                                </Col>
                                <Col md={2}>
                                    <Field type="text" name="short_name" placeholder="Short Name" as={Input} />
                                    <ErrorMessage component="div" className="text-danger" name="short_name" />
                                </Col>
                            </Fragment>
                        }
                        {!editMode &&
                            <Col>
                                <h4 className='mb-4'>{pageTitle}</h4>
                            </Col>
                        }
                        {isAllowedToEdit &&
                            <Col className='text-end'>
                                {selectedProcedure.id &&
                                    <Button color='success' type='button' outline className='me-3 px-4' size='sm' onClick={() => setEditMode(!editMode)}>{editMode ? 'Cancel' : 'Edit Procedure'}</Button>
                                }
                                {editMode &&
                                    <Button color='success' type='button' onClick={startUploadAndSubmit} className='me-3 px-4' size='sm'>Save Procedure</Button>
                                }
                            </Col>
                        }
                    </Row>
                    <Row>
                        <Col sm={12} md={6}>
                            <SectionTitle title='Description' />
                            {editMode &&
                                <Fragment>
                                    <FormGroup>
                                        <Field id="description" name="description" type="textarea" rows={12} as={Input} />
                                    </FormGroup>
                                    <FormGroup>
                                        <Field type="text" name="difficulty" placeholder="Difficulty" as={Input} />
                                        <ErrorMessage component="div" className="text-danger" name="difficulty" />
                                    </FormGroup>
                                    <FormGroup >
                                        <Field type="checkbox" id="need_details" name="need_details" as={Input} />
                                        <Label className='mx-2' check for="need_details">Require details</Label>
                                        <ErrorMessage component="div" className="text-danger" name="need_details" />
                                    </FormGroup>
                                </Fragment>
                            }
                            {!editMode &&
                                <Fragment>
                                    <Card className='border-0'>
                                        <CardBody>
                                            <p>{selectedProcedure.description}</p>
                                            <p>Difficulty: <strong>{selectedProcedure.difficulty}</strong></p>
                                            <p>Require details: <strong>{selectedProcedure.need_details ? 'Yes' : 'No'}</strong></p>
                                        </CardBody>
                                    </Card>
                                </Fragment>
                            }
                        </Col>
                        <Col sm={12} md={6}>
                            <SectionTitle title='Resources' />
                            <div className="my-3 mx-2">
                                {editMode &&
                                    <Field name="resources" as={DragAndDrop} {...{ submitForm, uploading, setFieldValue, setUploading }} />
                                }
                                {!editMode &&
                                    <AttachmentLinks attachments={selectedProcedure.resources} />
                                }
                            </div>
                        </Col>
                    </Row>
                </Form>
            )}
            </Formik>
        </Container>
    );
}

export default ProcedureForm;