import { Col, Row } from "reactstrap";

const SectionTitle = ({ title, children }) => {
    return (
        <div className="background-light-grey p-3 border-bottom-grey mb-3 h5">
            <Row>
                <Col>
                    {title}
                </Col>
                <Col className='text-end'>
                    <div style={{margin: '-9px 0'}}>
                        {children}
                    </div>
                </Col>
            </Row>
        </div>
    )
};

export default SectionTitle;
