import axios from 'axios';
import { buildPaginationUrlParams } from '../utils';
import { API_CUBICLE_LOGS, API_URL_BASIC_FACULTY_REPORT, API_URL_BASIC_STUDENT_REPORT, API_URL_ON_GOING_QUEUE } from './API_CONSTANTS';

// Create cubicle log
export const createCubicleLog = (cubicleLog) => {
    const url = API_CUBICLE_LOGS;

    return new Promise((resolve, reject) => {
        try {
            axios.post(url, cubicleLog)
                .then((res) => {
                    console.log('Cubicle log created: ', res);
                    resolve(res.data);
                })
                .catch((err) => {
                    console.log('Create cubicle log error: ', err);
                    reject('Create cubicle log error');
                });
        } catch (error) {
            console.error('Create cubicle log error: ', error);
            reject('Create cubicle log error');
        }
    });
}

// Update cubicle log
export const updateCubicleLog = ({ cubicleLogId, payload }) => {
    const url = API_CUBICLE_LOGS + '/' + cubicleLogId;

    return new Promise((resolve, reject) => {
        try {
            axios.put(url, payload)
                .then((res) => {
                    console.log('Cubicle log updated: ', res);
                    resolve(res.data);
                })
                .catch((err) => {
                    console.log('Update cubicle log error: ', err);
                    reject('Update cubicle log error');
                });
        } catch (error) {
            console.error('Update cubicle log error: ', error);
            reject('Update cubicle log error');
        }
    });
}

export const getOnGoingQueue = () => {
    const url = API_URL_ON_GOING_QUEUE;

    return new Promise((resolve, reject) => {
        try {
            axios.get(url)
                .then((res) => {
                    console.log('Cubicle log on going queue loaded: ', res);
                    resolve(res.data);
                })
                .catch((err) => {
                    console.log('Cubicle log on going queue error: ', err);
                    reject('Cubicle log on going queue error');
                });
        } catch (error) {
            console.error('Cubicle log on going queue error: ', error);
            reject('Cubicle log on going queue error');
        }
    });
};

export const getOneCubicleLog = (cubicleLogId) => {
    const url = API_CUBICLE_LOGS + '/' + cubicleLogId;

    return new Promise((resolve, reject) => {
        try {
            axios.get(url)
                .then((res) => {
                    console.log('Cubicle log loaded: ', res);
                    resolve(res.data);
                })
                .catch((err) => {
                    console.log('Getting one cubicle log error: ', err);
                    reject('Getting one cubicle log error');
                });
        } catch (error) {
            console.error('Getting one cubicle log error: ', error);
            reject('Getting one cubicle log error');
        }
    });
};

export const getBasicFacultyReport = ({ fromDateEpoch, toDateEpoch, paginationParams = {} }) => {
    paginationParams = {
        ...paginationParams,
        page: 1,
        limit: 9999,
    };

    // Dates are in epoch format to keep redux parameters in native types
    // https://redux.js.org/style-guide/#do-not-put-non-serializable-values-in-state-or-actions

    let fromDate = new Date(fromDateEpoch);
    let toDate = new Date(toDateEpoch);
    fromDate = fromDate.toISOString().replace(/[.:-]/g, '').substring(0, 15) + 'Z';
    toDate = toDate.toISOString().replace(/[.:-]/g, '').substring(0, 15) + 'Z';

    const url = API_URL_BASIC_FACULTY_REPORT + '/' + fromDate + '/' + toDate + buildPaginationUrlParams(paginationParams);

    return new Promise((resolve, reject) => {
        try {
            axios.get(url)
                .then((res) => {
                    console.log('Basic faculty report loaded: ', res);
                    resolve(res.data);
                })
                .catch((err) => {
                    console.log('Get basic faculty report error: ', err);
                    reject('Get basic faculty report error');
                });
        } catch (error) {
            console.error('Get basic faculty report error: ', error);
            reject('Get basic faculty report error');
        }
    });
};

export const getBasicStudentReport = ({ fromDateEpoch, toDateEpoch, paginationParams = {} }) => {
    paginationParams = {
        ...paginationParams,
        page: 1,
        limit: 9999,
    };

    // Dates are in epoch format to keep redux parameters in native types
    // https://redux.js.org/style-guide/#do-not-put-non-serializable-values-in-state-or-actions

    let fromDate = new Date(fromDateEpoch);
    let toDate = new Date(toDateEpoch);
    fromDate = fromDate.toISOString().replace(/[.:-]/g, '').substring(0, 15) + 'Z';
    toDate = toDate.toISOString().replace(/[.:-]/g, '').substring(0, 15) + 'Z';

    const url = API_URL_BASIC_STUDENT_REPORT + '/' + fromDate + '/' + toDate + buildPaginationUrlParams(paginationParams);

    return new Promise((resolve, reject) => {
        try {
            axios.get(url)
                .then((res) => {
                    console.log('Basic student report loaded: ', res);
                    resolve(res.data);
                })
                .catch((err) => {
                    console.log('Get basic student report error: ', err);
                    reject('Get basic student report error');
                });
        } catch (error) {
            console.error('Get basic student report error: ', error);
            reject('Get basic student report error');
        }
    });
};
