export const unifiedStyle = (props) => {

    const height = (props.location && props.location === 'WebcastsViewer') ? "88%" : "90%";

    return {
        display: "flex",
        height,
        boxSizing: "border-box",
        fontFamily: `${props.theme.fontFamily}`,
        border: `1px solid ${props.theme.borderColor.primary}`,
        position: "relative",
        float: "right",
        "*": {
            boxSizing: "border-box",
            fontFamily: `${props.theme.fontFamily}`,
            "::-webkit-scrollbar": {
                width: "8px",
                height: "4px",
            },
            "::-webkit-scrollbar-track": {
                background: "#ffffff00"
            },
            "::-webkit-scrollbar-thumb": {
                background: "#ccc",
                "&:hover": {
                    background: "#aaa"
                }
            }
        }
    }
}

export const unifiedSidebarStyle = (state, props) => {

    const sidebarView = (state.sidebarview) ? {
        //left: "0",
    } : {};

    const mq = [...props.theme.breakPoints];

    const width = (props.location && props.location === 'WebcastsViewer') ? "16rem" : "20rem";

    return {
        width,
        borderRight: `1px solid ${props.theme.borderColor.primary}`,
        height: "100%",
        position: "relative",
        display: "flex",
        order: "2",
        flexDirection: "column",
        "> .contacts, .chats, .groups, .userinfo": {
            height: "calc(100% - 64px)",
            [`@media ${mq[1]}`]: {
                width: '90vw',
            },
        },
        [`@media ${mq[0]}`]: {
            order: "1",
            top: "0",
            bottom: "0",
            width: "100%!important",
            zIndex: "2",
            backgroundColor: `${props.theme.backgroundColor.white}`,
            transition: "all .3s ease-out",
            ...sidebarView
        }
    }
}

export const unifiedMainStyle = (state, props) => {

    const mq = [...props.theme.breakPoints];

    return {
        width: "22rem",
        height: "100%",
        order: "1",
        display: state.sidebarview ? "none" : "flex",
        flexDirection: "row",
        [`@media ${mq[1]}`]: {
            order: "2",
        },
    }
}
