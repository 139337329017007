import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CUBICLE_STATUS_CHECKOUT } from '../../redux/reducers/my_cubicle.reducer';
import { getStudentExercisesDashboard } from '../../redux/actions/student_exercises.actions';

const ShouldUpdateStudentExercises = ({ studentExerciseId }) => {
    const dispatch = useDispatch();
    const [hasAnOnGoingCheckout, setHasAnOnGoingCheckout] = useState(null);
    const onGoingQueue = useSelector(state => state.cubicleLogs.onGoingQueue);

    useEffect(() => {
        let hasAnOnGoingCheckout = onGoingQueue.some((cubicleLog) =>
            cubicleLog.cubicle_status === CUBICLE_STATUS_CHECKOUT && cubicleLog.student_exercise === studentExerciseId
        );
        setHasAnOnGoingCheckout(hasAnOnGoingCheckout);
    }, [onGoingQueue, studentExerciseId]);

    useEffect(() => {
        return () => {
            if (hasAnOnGoingCheckout) {
                dispatch(getStudentExercisesDashboard());
            }
        }

    }, [dispatch, hasAnOnGoingCheckout]);

    return null;
};

export default ShouldUpdateStudentExercises;
