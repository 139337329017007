import {
    userLogin,
    userLoginWithHash,
    userLogoutAll,
} from "../../services/authService";
import {
    LOGIN,
    loginSuccess,
    LOGIN_WITH_HASH,
    LOGOUT,
    logoutSuccess,
    CONFIRM_LOGOUT,
    AUTOMATED_LOGOUT,
    logout,
} from "../actions/auth.actions";
import { genericError } from "../actions/error.actions";
import { getUser, resetUserState } from "../actions/users.actions";
import {
    disableOverlay,
    enableOverlay,
    setNavigateTo,
    setToast,
    openNotificationModal,
} from "../actions/ui.actions";
import { all, call, fork, put, select, takeEvery } from "redux-saga/effects";
import { cleanSession } from "../../utils/index";
import { TOAST_SEVERITY_SUCCESS } from "../reducers/ui.reducer";
import { resetMyCubicle } from "../actions/my_cubicle.actions";
import { LOGIN_FORM } from "../../navigation/ROUTE_CONSTANTS";
import { ICON_INFO, ICON_SUCCESS } from "../../components/ui/NotificationModal";
import { CometChat } from "@cometchat-pro/chat";
import { clearListAnnouncements } from "../actions/announcements.actions";

/**
 * logInEffect
 */
function* logInEffect({ payload }) {
    yield put(enableOverlay('Logging in ...'));
    try {
        let loginResult;
        if ('single_login_hash' in payload) {
            // Login with hash
            loginResult = yield call(userLoginWithHash, payload);
        } else {
            // Login with credentials
            loginResult = yield call(userLogin, payload);
        }

        if (loginResult) {
            yield put(loginSuccess(loginResult));
            yield put(getUser(loginResult.access));
            localStorage.setItem('ca-access', loginResult.access);
            localStorage.setItem('ca-refresh', loginResult.refresh);

            yield put(setToast({
                severity: TOAST_SEVERITY_SUCCESS,
                detail: 'You have been logged in!',
            }));
        }
    } catch (err) {
        console.log('error:', err);
        yield put(genericError(err));
    }
    yield put(disableOverlay());
}

/**
 * confirmLogoutEffect
 */
function* confirmLogoutEffect() {
    yield put(openNotificationModal({
        title: 'Warning!',
        message:
            'You are in an active workflow (queue). Clear all active processes first before logging off. Continue and reset all active sessions?',
        iconType: ICON_SUCCESS,
        handleAccept: 'logout',
        buttonResponse: true,
        buttonAcceptText: 'Continue',
    }));
}

/**
 * logoutEffect
 */
function* logoutEffect() {
    CometChat.logout();
    try {
        let refreshToken = localStorage.getItem('ca-refresh');
        yield call(userLogoutAll, refreshToken);
    } catch (error) {
        yield put(genericError(JSON.stringify(error)));
    }

    yield put(resetUserState());
    yield put(resetMyCubicle());
    yield put(clearListAnnouncements());
    yield put(logoutSuccess());
    yield cleanSession();
    yield put(setNavigateTo(LOGIN_FORM));
}

/**
 * automatedLogoutEffect
 */
function* automatedLogoutEffect({ payload }) {
    const myUserRole = yield select((state) => state.users.userLogged?.role);
    const shouldILogout = payload.roles.includes(myUserRole);
    if (shouldILogout) {
        yield put(logout());
        yield put(openNotificationModal({
            title: 'Logout Notification',
            message: 'You was logged out due to administration settings.',
            iconType: ICON_INFO,
        }));
    }
}

/**
 * Watchers
 */
export function* watchLogin() {
    yield takeEvery(LOGIN, logInEffect);
}

export function* watchLoginWithHash() {
    yield takeEvery(LOGIN_WITH_HASH, logInEffect);
}

export function* watchLogout() {
    yield takeEvery(LOGOUT, logoutEffect);
}

export function* watchConfirmLogout() {
    yield takeEvery(CONFIRM_LOGOUT, confirmLogoutEffect);
}

export function* watchAutomatedLogout() {
    yield takeEvery(AUTOMATED_LOGOUT, automatedLogoutEffect);
}

function* authSaga() {
    yield all([
        fork(watchLogin),
        fork(watchLoginWithHash),
        fork(watchLogout),
        fork(watchConfirmLogout),
        fork(watchAutomatedLogout),
        fork(watchAutomatedLogout),
    ]);
}

export default authSaga;
