import axios from 'axios';
import { closeModalProgress, updateModalProgress } from '../redux/actions/ui.actions';
import { buildPaginationUrlParams } from '../utils/index';
import { API_WEBCASTS, API_WEBCASTS_ADMIN, API_WEBCASTS_START_UPLOAD } from './API_CONSTANTS';

export const getWebcasts = (paginationParams = {}) => {
    paginationParams = {
        ...paginationParams,
        page: 1,
        limit: 99,
    };
    const url = API_WEBCASTS_ADMIN + buildPaginationUrlParams(paginationParams);

    return new Promise((resolve, reject) => {
        try {
            axios.get(url)
                .then((res) => {
                    console.log('Webcasts loaded', res);
                    resolve(res.data);
                })
                .catch((err) => {
                    console.log('Get webcasts error: ', err);
                    reject('Get webcasts error');
                })
        }
        catch (error) {
            console.log('Get webcasts error', error);
            reject('Get webcasts error');
        }
    })
}

export const getPublicWebcasts = (paginationParams = {}) => {
    paginationParams = {
        ...paginationParams,
        page: 1,
        limit: 99,
    };
    const url = API_WEBCASTS + buildPaginationUrlParams(paginationParams);

    return new Promise((resolve, reject) => {
        try {
            axios.get(url)
                .then((res) => {
                    console.log('Public webcasts loaded', res);
                    resolve(res.data);
                })
                .catch((err) => {
                    console.log('Get public webcasts error: ', err);
                    reject('Get public webcasts error');
                })
        }
        catch (error) {
            console.log('Get public webcasts error', error);
            reject('Get public webcasts error');
        }
    })
}

export const getOneWebcast = (webcastId) => {
    const url = API_WEBCASTS_ADMIN + '/' + webcastId;

    return new Promise((resolve, reject) => {
        try {
            axios.get(url)
                .then((res) => {
                    console.log('Webcast loaded: ', res);
                    resolve(res.data);
                })
                .catch((err) => {
                    console.log('Get one webcast error: ', err);
                    reject('Get one webcast error');
                });
        } catch (error) {
            console.error('Get one webcast error: ', error);
            reject('Get one webcast error');
        }
    });
}

export const getPublicWebcast = (webcastId) => {
    const url = API_WEBCASTS + '/' + webcastId;

    return new Promise((resolve, reject) => {
        try {
            axios.get(url)
                .then((res) => {
                    console.log('Public webcast loaded: ', res);
                    resolve(res.data);
                })
                .catch((err) => {
                    console.log('Get public webcast error: ', err);
                    reject('Get public webcast error');
                });
        } catch (error) {
            console.error('Get public webcast error: ', error);
            reject('Get public webcast error');
        }
    });
}

export const createWebcast = (webcast) => {
    const url = API_WEBCASTS_ADMIN;

    return new Promise((resolve, reject) => {
        try {
            axios.post(url, webcast)
                .then((res) => {
                    console.log('Webcast created: ', res);
                    resolve(res.data);
                })
                .catch((err) => {
                    console.log('Create webcast error: ', err);
                    reject('Create webcast error');
                });
        } catch (error) {
            console.error('Create webcast error: ', error);
            reject('Create webcast error');
        }
    });
}

export const updateWebcast = (webcast) => {
    const url = API_WEBCASTS_ADMIN + '/' + webcast.id;

    return new Promise((resolve, reject) => {
        try {
            axios.patch(url, webcast)
                .then((res) => {
                    console.log('Webcast updated: ', res);
                    resolve(res.data);
                })
                .catch((err) => {
                    console.log('Update webcast error: ', err);
                    reject('Update webcast error');
                });
        } catch (error) {
            console.error('Update webcast error: ', error);
            reject('Update webcast error');
        }
    });
}

export const removeWebcast = (webcastId) => {
    const url = API_WEBCASTS_ADMIN + '/' + webcastId;

    return new Promise((resolve, reject) => {
        try {
            axios.delete(url)
                .then((res) => {
                    console.log('Webcast removed: ', res);
                    resolve(res);
                })
                .catch((err) => {
                    console.log('Remove webcast error: ', err);
                    reject('Remove webcast error');
                });
        } catch (error) {
            console.error('Remove webcast error: ', error);
            reject('Remove webcast error');
        }
    });
}

export const uploadFileToDacast = (webcastId, files, dispatch) => {
    return new Promise(async (resolve, reject) => {
        let filesSaved = [];
        for (let file of files) {
            // Append a prefix to the file name to avoid name collision
            const namePrefix = crypto.randomUUID().substring(0, 6);
            const external_video_title = namePrefix + '_' + file.file.name;

            const dataDacastBucket = await getDataForPostToDacast({ id: webcastId, external_video_title });

            let formData = new FormData();
            // Append order is important
            formData.append('acl', dataDacastBucket['acl']);
            formData.append('key', dataDacastBucket['key']);
            formData.append('policy', dataDacastBucket['policy']);
            formData.append('success_action_status', dataDacastBucket['success_action_status']);
            formData.append('x-amz-algorithm', dataDacastBucket['x-amz-algorithm']);
            formData.append('x-amz-credential', dataDacastBucket['x-amz-credential']);
            formData.append('x-amz-date', dataDacastBucket['x-amz-date']);
            formData.append('x-amz-signature', dataDacastBucket['x-amz-signature']);
            formData.append('file', file.file);

            try {
                await sendFileToDacast(
                    'https://' + dataDacastBucket['bucket'],
                    formData,
                    external_video_title,
                    dispatch
                );
                filesSaved.push({
                    external_video_title,
                    id: file.id,
                    uploaded: true,
                });
            } catch (err) {
                console.log('ERROR', err);
                reject(err);
            }
        }

        dispatch(closeModalProgress());
        resolve(filesSaved);
    })
}

export const getDataForPostToDacast = (videoData) => {
    return new Promise((resolve, reject) => {
        try {
            axios.post(API_WEBCASTS_START_UPLOAD, videoData)
                .then((res) => {
                    console.log("Post url AWS: ", res);
                    resolve(res.data);
                })
                .catch((err) => {
                    console.log("Post url AWS error: ", err);
                    reject("Error getting url from AWS");
                });
        } catch (error) {
            console.error("Error getting url from AWS!", error);
            reject("Error getting url from AWS!");
        }
    });
}

export const sendFileToDacast = (url, formData, fileName, dispatch) => {

    const axiosConfig = {
        skipAddSessionInterceptor: true,
        onUploadProgress: ({ loaded,  total }) => {
            let percentage = 0;
            if (total > 0) {
                percentage = Math.floor((loaded * 100) / total);
            }
            dispatch(updateModalProgress({
                message: `Uploading ${fileName}...`,
                percentage,
            }));
        }
    };

    return new Promise((resolve, reject) => {
        try {
            axios.post(url, formData, axiosConfig)
                .then((res) => {
                    console.log("Video uploaded to AWS: ", res);
                    resolve(res.data);
                })
                .catch((err) => {
                    console.log("Video upload to AWS error: ", err);
                    reject("Video upload to AWS error");
                });
        } catch (error) {
            console.error("Video upload to AWS error!", error);
            reject("Video upload to AWS error!");
        }
    });
}
