import { GET_ADMIN_LIST_STUDENT_EXERCISES_SUCCESS, GET_LIST_STUDENT_EXERCISES_SUCCESS, GET_STUDENT_EXERCISES_DASHBOARD_SUCCESS } from '../actions/student_exercises.actions';

export const STUDENT_EXERCISE_STATUS_PLANNED = 'planned';
export const STUDENT_EXERCISE_STATUS_IN_PROGRESS = 'in_progress';
export const STUDENT_EXERCISE_STATUS_COMPLETED = 'completed';
export const STUDENT_EXERCISE_STATUS_CANCELLED = 'cancelled';

export const STUDENT_EXERCISE_STATUS_UI_NAMES = {
    [STUDENT_EXERCISE_STATUS_PLANNED]: 'Planned',
    [STUDENT_EXERCISE_STATUS_IN_PROGRESS]: 'In Progress',
    [STUDENT_EXERCISE_STATUS_COMPLETED]: 'Completed',
    [STUDENT_EXERCISE_STATUS_CANCELLED]: 'Cancelled',
};

const initialStudentExercisesState = {
    studentExercisesList: [],
    next: null,
    previous: null,
    count: 0,
    studentExercisesDashboard: [],
};

export const StudentExercisesReducer = (state = initialStudentExercisesState, action) => {
    switch (action.type) {
        case GET_LIST_STUDENT_EXERCISES_SUCCESS:
            return {
                ...state,
                studentExercisesList: action.payload.results,
                next: action.payload.next,
                previous: action.payload.previous,
                count: action.payload.count,
            }
        case GET_STUDENT_EXERCISES_DASHBOARD_SUCCESS:
            return {
                ...state,
                studentExercisesDashboard: action.payload,
            }
        default:
            return state;
    }
}

const initialAdminStudentExercisesState = {
    adminStudentExercisesList: [],
    next: null,
    previous: null,
    count: 0
};

export const AdminStudentExercisesReducer = (state = initialAdminStudentExercisesState, action) => {
    switch (action.type) {
        case GET_ADMIN_LIST_STUDENT_EXERCISES_SUCCESS:
            return {
                ...state,
                adminStudentExercisesList: action.payload.results,
                next: action.payload.next,
                previous: action.payload.previous,
                count: action.payload.count,
            }
        default:
            return state;
    }
}
