import { all, call, fork, put, select, takeEvery } from 'redux-saga/effects';
import * as cubicleLogsApi from "../../services/cubicleLogsService";
import { logout } from '../actions/auth.actions';
import {
    CREATE_CUBICLE_LOG,
    getBasicFacultyReportSuccess,
    getBasicStudentReportSuccess,
    getCubicleLogSuccess,
    getOnGoingQueueSuccess,
    GET_BASIC_FACULTY_REPORT,
    GET_BASIC_STUDENT_REPORT,
    GET_CUBICLE_LOG,
    GET_ON_GOING_QUEUE,
    SET_OCCUPIED_CUBICLE_LOG,
    SET_UNOCCUPIED_CUBICLE_LOG,
    UPDATE_CUBICLE_LOG
} from '../actions/cubicle_logs.actions';
import { genericError } from '../actions/error.actions';
import { getMyCubicle } from '../actions/my_cubicle.actions';
import { disableOverlay, enableOverlay, setFormDialogSubmitSuccess } from '../actions/ui.actions';
import { CUBICLE_STATUS_OCCUPIED, CUBICLE_STATUS_UNOCCUPIED } from '../reducers/my_cubicle.reducer';

/**
 * createCubicleLogEffect
 */
function* createCubicleLogEffect({ payload }) {
    try {
        yield put(enableOverlay('Creating cubicle log ...'));
        const myCubicleId = yield select((state) => state.myCubicle.id);
        payload.cubicle = myCubicleId;
        const cubicleLog = yield call(cubicleLogsApi.createCubicleLog, payload);
        if (cubicleLog) {
            yield put(getMyCubicle());
            yield put(setFormDialogSubmitSuccess(true));
        }
    } catch (err) {
        console.log('error:', err);
        yield put(genericError(JSON.stringify(err)));
    }
    yield put(disableOverlay());
}

/**
 * updateCubicleLogEffect
 */
function* updateCubicleLogEffect({ payload }) {
    try {
        yield put(enableOverlay('Updating cubicle log ...'));
        const lastCubicleLog = yield select((state) => state.myCubicle.last_cubicle_log);
        const cubicleLog = yield call(cubicleLogsApi.updateCubicleLog, { cubicleLogId: lastCubicleLog.id, payload });
        if (cubicleLog) {
            yield put(getMyCubicle());
        }
    } catch (err) {
        console.log('error:', err);
        yield put(genericError(JSON.stringify(err)));
    }
    yield put(disableOverlay());
}

/**
 * getCubicleLogEffect
 */
function* getCubicleLogEffect({ payload }) {
    try {
        yield put(enableOverlay('Getting cubicle log ...'));
        const cubicleLog = yield call(cubicleLogsApi.getOneCubicleLog, payload);
        if (cubicleLog) {
            yield put(getCubicleLogSuccess(cubicleLog));
        }
    } catch (err) {
        console.log('error:', err);
        yield put(genericError(JSON.stringify(err)));
    }
    yield put(disableOverlay());
}

/**
 * setOccupiedCubicleLogEffect
 */
function* setOccupiedCubicleLogEffect({ payload }) {
    const myCubicleId = payload;
    try {
        yield put(enableOverlay('Setting cubicle as occupied ...'));
        const cubicleLogData = {
            cubicle: myCubicleId,
            cubicle_status: CUBICLE_STATUS_OCCUPIED
        }
        const cubicleLog = yield call(cubicleLogsApi.createCubicleLog, cubicleLogData);
        if (cubicleLog) {
            yield put(getMyCubicle());
        }
    } catch (err) {
        console.log('error:', err);
        yield put(genericError(JSON.stringify(err)));
    }
    yield put(disableOverlay());
}

/**
 * setUnoccupiedCubicleLogEffect
 */
function* setUnoccupiedCubicleLogEffect() {
    const myCubicle = yield select((state) => state.myCubicle);
    try {
        yield put(enableOverlay('Setting cubicle as unoccupied ...'));
        const cubicleLogData = {
            cubicle: myCubicle.id,
            cubicle_status: CUBICLE_STATUS_UNOCCUPIED
        }
        const cubicleLog = yield call(cubicleLogsApi.createCubicleLog, cubicleLogData);
        if (cubicleLog) {
            yield put(getMyCubicle());
        }
    } catch (err) {
        console.log('error:', err);
        yield put(genericError(JSON.stringify(err)));
    }
    yield put(disableOverlay());
    yield put(logout());
}

/**
 * getOnGoingQueueEffect
 */
function* getOnGoingQueueEffect() {
    try {
        const onGoingQueue = yield call(cubicleLogsApi.getOnGoingQueue);
        yield put(getOnGoingQueueSuccess(onGoingQueue));
    } catch (err) {
        console.log('error:', err);
        yield put(genericError(err));
    }
}

/**
 * getBasicFacultyReportEffect
 */
function* getBasicFacultyReportEffect({ payload }) {
    yield put(enableOverlay('Loading report data ...'));
    try {
        const basicFacultyReport = yield call(cubicleLogsApi.getBasicFacultyReport, payload);
        yield put(getBasicFacultyReportSuccess(basicFacultyReport));
    } catch (err) {
        console.log('error:', err);
        yield put(genericError(err));
    }
    yield put(disableOverlay());
}

/**
 * getBasicStudentReportEffect
 */
function* getBasicStudentReportEffect({ payload }) {
    yield put(enableOverlay('Loading report data ...'));
    try {
        const basicStudentReport = yield call(cubicleLogsApi.getBasicStudentReport, payload);
        yield put(getBasicStudentReportSuccess(basicStudentReport));
    } catch (err) {
        console.log('error:', err);
        yield put(genericError(err));
    }
    yield put(disableOverlay());
}

export function* watchCreateCubicleLog() {
    yield takeEvery(CREATE_CUBICLE_LOG, createCubicleLogEffect);
}

function* watchUpdateCubicleLog() {
    yield takeEvery(UPDATE_CUBICLE_LOG, updateCubicleLogEffect);
}

function* watchGetCubicleLog() {
    yield takeEvery(GET_CUBICLE_LOG, getCubicleLogEffect);
}

export function* watchSetOccupiedCubicleLog() {
    yield takeEvery(SET_OCCUPIED_CUBICLE_LOG, setOccupiedCubicleLogEffect);
}

export function* watchSetUnoccupiedCubicleLog() {
    yield takeEvery(SET_UNOCCUPIED_CUBICLE_LOG, setUnoccupiedCubicleLogEffect);
}

export function* watchGetOnGoingQueue() {
    yield takeEvery(GET_ON_GOING_QUEUE, getOnGoingQueueEffect);
}

export function* watchGetBasicFacultyReport() {
    yield takeEvery(GET_BASIC_FACULTY_REPORT, getBasicFacultyReportEffect);
}

export function* watchGetBasicStudentReport() {
    yield takeEvery(GET_BASIC_STUDENT_REPORT, getBasicStudentReportEffect);
}

function* cubicleLogsSaga() {
    yield all([
        fork(watchCreateCubicleLog),
        fork(watchUpdateCubicleLog),
        fork(watchGetCubicleLog),
        fork(watchSetOccupiedCubicleLog),
        fork(watchSetUnoccupiedCubicleLog),
        fork(watchGetOnGoingQueue),
        fork(watchGetBasicFacultyReport),
        fork(watchGetBasicStudentReport),
    ]);
}

export default cubicleLogsSaga;
