import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getOneFile } from '../../services/files.services';

const ImageS3 = ({ attachment, imgAlt, className = null, style = null }) => {
    const dispatch = useDispatch();
    const [attachmentS3Url, setAttachmentS3Url] = useState(null);

    useEffect(() => {
        if (attachment && attachment.file_uuid) {
            getOneFile(attachment.file_uuid).then((response) => {
                setAttachmentS3Url(response.file_url);
            });
        }
    }, [attachment, dispatch]);

    let classNames = 'img-fluid';
    if (className) {
        classNames += ' ' + className;
    }

    return (
        attachmentS3Url && <img src={attachmentS3Url} className={classNames} alt={imgAlt} style={style} loading='lazy' />
    );
}

export default ImageS3;