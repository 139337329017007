import { useEffect, useState } from 'react';
import { Container, Row, Col, Button, FormGroup, Input } from 'reactstrap';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import AppBreadcrumb from '../../components/ui/AppBreadcrumb';
import { CLIENT_ADMIN_CUBICLE_GROUPS } from '../../navigation/ROUTE_CONSTANTS';
import { getOneCubicleGroup } from '../../services/cubicleGroupsService';
import { createCubicleGroup, updateCubicleGroup } from '../../redux/actions/cubicle_groups.actions';

function ClientAdminCubicleGroupForm() {

    const dispatch = useDispatch();
    const { cubicleGroupId } = useParams();
    const [initialFormValues, setInitialFormValues] = useState({
        name: '',
        heading_text: '',
    });
    const [pageTitle, setPageTitle] = useState('');

    useEffect(() => {
        if (cubicleGroupId) {
            setPageTitle('Edit Cubicle Group');
            getOneCubicleGroup(cubicleGroupId).then((data) => {
                setInitialFormValues({
                    name: data.name,
                    heading_text: data.heading_text,
                });
            })
        } else {
            setPageTitle('Create Cubicle Group');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cubicleGroupId]);

    useEffect(() => {
        document.title = process.env.REACT_APP_SITE_TITLE + ' - ' + pageTitle;
    }, [pageTitle]);

    return (
        <Container className='mb-5 pb-5'>
            <Row>
                <Col>
                    <AppBreadcrumb headText={pageTitle} crumbList={[{ linkTo: CLIENT_ADMIN_CUBICLE_GROUPS, label: 'Cubicle Groups' }]} />
                </Col>
            </Row>

            <Row>
                <Col>
                    <div className='vstack col-md-4 mx-auto'>
                        <h4 className='pt-2 pb-3 text-center'>{pageTitle}</h4>
                        <Formik
                            enableReinitialize={true}
                            initialValues={initialFormValues}
                            validationSchema={Yup.object({
                                name: Yup.string()
                                    .min(1, 'Name must be at least 1 characters length.')
                                    .max(120, 'Name must be smaller than 120 characters length.')
                                    .required('Please enter a name'),
                                heading_text: Yup.string()
                                    .min(1, 'Name must be at least 1 characters length.')
                                    .max(250, 'Name must be smaller than 250 characters length.')
                                    .notRequired(),
                            })}
                            onSubmit={(values) => {
                                let formData = {
                                    name: values.name,
                                    heading_text: values.heading_text,
                                }

                                if (cubicleGroupId) {
                                    formData.id = cubicleGroupId;
                                    dispatch(updateCubicleGroup(formData));
                                } else {
                                    dispatch(createCubicleGroup(formData));
                                }
                            }}
                        >{() => (
                            <Form>
                                <FormGroup>
                                    <Field type="text" name="name" placeholder="Name" as={Input} />
                                    <ErrorMessage component="div" className="text-danger" name="name" />
                                </FormGroup>
                                <FormGroup>
                                    <Field type="text" name="heading_text" placeholder="Heading text" as={Input} />
                                    <ErrorMessage component="div" className="text-danger" name="heading_text" />
                                </FormGroup>

                                <div className='text-center'>
                                    <Button color='success' className='mt-2 px-5' type='submit'>
                                        <span className='d-inline-block fs-5 px-3'>{pageTitle}</span>
                                    </Button>
                                </div>
                            </Form>
                        )}
                        </Formik>
                    </div>
                </Col>
            </Row >

        </Container>
    )
}

export default ClientAdminCubicleGroupForm;