import { faAngleLeft, faBars, faX } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink as RouterNavLink, useLocation } from 'react-router-dom';
import { Collapse, Container, Nav, Navbar as RSNavbar, NavbarBrand, NavbarToggler } from 'reactstrap';
import { USER_HOME } from '../../navigation/ROUTE_CONSTANTS';
import { confirmLogout, logout } from '../../redux/actions/auth.actions';
import { setHomeTargetUrl as actionSetHomeTargetUrl, setStartLogoutProcess } from '../../redux/actions/ui.actions';
import { USER_ROLE_FACULTY, USER_ROLE_STUDENT } from '../../redux/reducers/auth.reducer';
import { buildMenuItems } from '../../utils/Navbar/BuildMenuItems';
import ItemDropdown from '../components/ItemDropdown';
import ItemTypeLink from '../components/ItemTypeLink';

const Navbar = () => {
    const dispatch = useDispatch();
    let location = useLocation();
    const [homeTargetUrl, setHomeTargetUrl] = useState('');

    const [brand, setBrand] = useState('');
    const [menuItems, setMenuItems] = useState([]);
    const [navbarIsOpen, setNavbarIsOpen] = useState(false);

    const userLogged = useSelector((state) => state.users.userLogged);
    const facultiesLoggedIn = useSelector((state) => state.facultiesLoggedIn);
    const myCubicle = useSelector((state) => state.myCubicle);
    const onGoingQueue = useSelector((state) => state.cubicleLogs.onGoingQueue);
    const showDropdownMobile = useSelector((state) => state.ui.showDropdownMobile);
    const startLogoutProcess = useSelector((state) => state.ui.startLogoutProcess);

    const handleShowConfirm = () => {
        dispatch(setStartLogoutProcess(true));
    };

    useEffect(() => {
        if (startLogoutProcess) {
            let mustConfirmLogout = false;
            if (!userLogged) {
                // Not logged in
            } else if (userLogged.role === USER_ROLE_STUDENT) {
                if (onGoingQueue.some((cubicleLog) => cubicleLog.student.username === userLogged.username)) {
                    mustConfirmLogout = true;
                }
            } else if (userLogged.role === USER_ROLE_FACULTY) {
                if (onGoingQueue.some((cubicleLog) =>
                    cubicleLog.accepted_by_faculty && cubicleLog.accepted_by_faculty.username === userLogged.username
                )) {
                    mustConfirmLogout = true;
                }
            }

            if (mustConfirmLogout) {
                dispatch(confirmLogout());
            } else {
                dispatch(logout());
            }

            dispatch(setStartLogoutProcess(false));
        }
    }, [userLogged, onGoingQueue, startLogoutProcess, dispatch]);

    const getItemTypeSeparator = (index) => (
        <div
            key={'D' + index}
            className="vr border-white border-top border-bottom border-10 ms-3 d-none d-md-block"
        ></div>
    );

    const getItem = (item, index) => {
        switch (item.type) {
            case 'link':
                return (
                    <ItemTypeLink linkProps={item.props} index={index} key={'link-' + index} />
                );
            case 'dropdown':
                return (
                    <ItemDropdown dropdownProps={item.props} index={index} key={'dropdown-' + index} />
                );
            case 'separator':
                return getItemTypeSeparator(index);
            default:
                return null;
        }
    };

    const getHomeUrl = () => {
        let homeUrl = USER_HOME;

        // TODO: Not needed anymore on phase 2
        // if (myCubicle?.cubicle_status) {
        //     switch (myCubicle.cubicle_status) {
        //         case CUBICLE_STATUS_START_CHECK:
        //             homeUrl = STUDENT_REQUEST_SUBMITTED;
        //             break;
        //         case CUBICLE_STATUS_HELP:
        //         case CUBICLE_STATUS_SPECIAL_REQUEST:
        //             homeUrl = STUDENT_REQUEST_HELP;
        //             break;
        //         case CUBICLE_STATUS_CHECKOUT:
        //             homeUrl = STUDENT_CHECKOUT;
        //             break;
        //         default:
        //             homeUrl = USER_HOME;
        //     }
        // }

        dispatch(actionSetHomeTargetUrl(homeUrl));
        return homeUrl;
    };

    useEffect(() => {
        setBrand(process.env.REACT_APP_SITE_TITLE);
        let homeTargetUrl = getHomeUrl();
        setHomeTargetUrl(homeTargetUrl);
        const items = buildMenuItems(
            userLogged,
            myCubicle,
            facultiesLoggedIn,
            homeTargetUrl,
            location,
            handleShowConfirm
        );
        setMenuItems(items);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        userLogged,
        myCubicle.name,
        myCubicle.cubicle_status,
        facultiesLoggedIn,
        location,
    ]);

    useEffect(() => {
        setNavbarIsOpen(false);
    }, [location]);

    return (
        <RSNavbar
            expand="md"
            container={false}
            className="p-0 m-0 mt-md-2"
            light
        >
            <Container className="mt-md-4 py-4 pt-md-2 pb-md-0 px-0 border-bottom position-relative">
                <NavbarBrand
                    className="ps-4 ps-md-0 py-0"
                    to={homeTargetUrl}
                    tag={RouterNavLink}
                >
                    <span className="fs-1 lh-1 text-joanna-mt">{brand}</span>
                </NavbarBrand>
                <NavbarToggler
                    style={{ zIndex: navbarIsOpen ? 1 : 0 }}
                    className={
                        'px-4 border-0 rounded-0 shadow-none position-absolute h-100 top-0 end-0 ' +
                        (navbarIsOpen ? 'bg-light border-start' : 'bg-white')
                    }
                    onClick={() => setNavbarIsOpen(!navbarIsOpen)}
                >
                    <FontAwesomeIcon
                        className="fs-3"
                        icon={navbarIsOpen ? faX : faBars}
                    />
                </NavbarToggler>
                <Collapse
                    style={{ marginTop: navbarIsOpen ? -1 : 0, zIndex: 1 }}
                    className="top-100 start-0 w-100 position-absolute position-md-relative justify-content-end align-self-end border-bottom border-top border-bottom-md-0 border-top-md-0 bg-light bg-md-white p-4 p-md-0"
                    isOpen={navbarIsOpen}
                    navbar
                >
                    <div className={'mb-2 ' + (showDropdownMobile ? '' : 'd-none') + ' d-md-none'}>
                        <FontAwesomeIcon className="fs-4" icon={faAngleLeft} />
                        <span className="ms-3">Back</span>
                    </div>
                    <Nav navbar>{menuItems.map(getItem)}</Nav>
                </Collapse>
            </Container>
        </RSNavbar>
    );
};

export default Navbar;
