import { createAction } from "@reduxjs/toolkit";

// All action types for Webcasts
export const GET_LIST_WEBCASTS = '[WEBCASTS] Getting list webcasts';
export const GET_LIST_WEBCASTS_SUCCESS = '[WEBCASTS] Getting list webcasts success!';
export const GET_LIST_PUBLIC_WEBCASTS = '[WEBCASTS] Getting list public webcasts';
export const GET_LIST_PUBLIC_WEBCASTS_SUCCESS = '[WEBCASTS] Getting list public webcasts success!';
export const GET_WEBCAST = '[WEBCAST] Getting webcast info';
export const GET_WEBCAST_SUCCESS = '[WEBCAST] Getting webcast info success!';
export const GET_PUBLIC_WEBCAST = '[WEBCAST] Getting public webcast info';
export const GET_PUBLIC_WEBCAST_SUCCESS = '[WEBCAST] Getting public webcast info success!';
export const CREATE_WEBCAST = '[WEBCAST] Webcast created';
export const CREATE_WEBCAST_SUCCESS = '[WEBCAST] Webcast created successfully';
export const UPDATE_WEBCAST = '[WEBCAST] Webcast updated';
export const UPDATE_WEBCAST_SUCCESS = '[WEBCAST] Webcast updated successfully';
export const DELETE_WEBCAST = '[WEBCAST] Webcast deleted';
export const DELETE_WEBCAST_SUCCESS = '[WEBCAST] Webcast deleted successfully';
export const CLEAR_SELECTED_WEBCAST = '[WEBCAST] Clear selected webcast';

// Webcast actions
export const getListWebcasts = createAction(GET_LIST_WEBCASTS);
export const getListWebcastsSuccess = createAction(GET_LIST_WEBCASTS_SUCCESS);
export const getListPublicWebcasts = createAction(GET_LIST_PUBLIC_WEBCASTS);
export const getListPublicWebcastsSuccess = createAction(GET_LIST_PUBLIC_WEBCASTS_SUCCESS);
export const getWebcast = createAction(GET_WEBCAST);
export const getWebcastSuccess = createAction(GET_WEBCAST_SUCCESS);
export const getPublicWebcast = createAction(GET_PUBLIC_WEBCAST);
export const getPublicWebcastSuccess = createAction(GET_PUBLIC_WEBCAST_SUCCESS);
export const createWebcast = createAction(CREATE_WEBCAST);
export const createWebcastSuccess = createAction(CREATE_WEBCAST_SUCCESS);
export const updateWebcast = createAction(UPDATE_WEBCAST);
export const updateWebcastSuccess = createAction(UPDATE_WEBCAST_SUCCESS);
export const deleteWebcast = createAction(DELETE_WEBCAST);
export const deleteWebcastSuccess = createAction(DELETE_WEBCAST_SUCCESS);
export const clearSelectedWebcast = createAction(CLEAR_SELECTED_WEBCAST);
