import { faPen, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Fragment, useEffect, useState } from 'react';
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import { useDispatch, useSelector } from 'react-redux';
import { Link } from "react-router-dom";
import { Button, Col, Container, Row } from 'reactstrap';
import AppBreadcrumb from '../../components/ui/AppBreadcrumb';
import { WEBCASTS_ADMIN_CREATE, WEBCASTS_ADMIN_DELETE, WEBCASTS_ADMIN_EDIT } from '../../navigation/ROUTE_CONSTANTS';
import { setNavigateTo } from "../../redux/actions/ui.actions";
import { getListWebcasts } from '../../redux/actions/webcasts.actions';
import { WEBCAST_SERVICE_TYPE_UI_NAMES, WEBCAST_STATUS_UI_NAMES } from '../../redux/reducers/webcasts.reducer';
import { customStyles } from '../../theme/assets/dataTableCustomStyles';
import { FORMAT_DATETIME_SHORT, isoStringToFormatted } from '../../utils/date_time';

const WebcastsList = () => {

    const webcastsList = useSelector((state) => state.webcasts.webcastsList);
    const isRoleClientAdmin = useSelector((state) => state.users.isRoleClientAdmin);
    const [data, setData] = useState([]);

    const dispatch = useDispatch();

    useEffect(() => {
        document.title = process.env.REACT_APP_SITE_TITLE + ' - Webcasts';
        dispatch(getListWebcasts())
    }, [dispatch]);

    useEffect(() => {
        setData(webcastsList)
    }, [webcastsList]);

    const columns = [
        {
            name: 'Title',
            selector: row => row.title,
            sortable: true,
            cell: (row) => (
                <Link to={WEBCASTS_ADMIN_EDIT.replace(':webcastId', row.id)} className='text-decoration-none'>
                    {row.title}
                </Link>),
            minWidth: '30%'
        },
        {
            name: 'Author',
            selector: row => row.author,
            sortable: true,
            center: true,
            className: 'text-body'
        },
        {
            name: 'Event Date',
            center: true,
            sortable: true,
            selector: (row) => row.event_time ? isoStringToFormatted(row.event_time, FORMAT_DATETIME_SHORT) : '',
            minWidth: '20%'
        },
        {
            name: 'Status',
            selector: row => row.status ? WEBCAST_STATUS_UI_NAMES[row.status] : '',
            sortable: true,
            center: true,
        },
        {
            name: 'Service',
            selector: row => row.service_type ? WEBCAST_SERVICE_TYPE_UI_NAMES[row.service_type] : '',
            sortable: true,
            center: true,
            width: '10%'
        },
        {
            name: 'Actions',
            center: true,
            cell: (row) => (
                <Fragment key={row.id}>
                    <Link className='text-dark btn btn-link btn-light' to={WEBCASTS_ADMIN_EDIT.replace(':webcastId', row.id)} >
                        <FontAwesomeIcon icon={faPen} title="Edit" />
                    </Link>
                    <Link className='text-dark btn btn-link btn-light' to={WEBCASTS_ADMIN_DELETE.replace(':webcastId', row.id)} >
                        <FontAwesomeIcon icon={faTrash} title="Delete" />
                    </Link>
                </Fragment>
            ),
        }
    ];

    const tableData = {
        columns,
        data
    };

    const createNewWebcast = () => {
        dispatch(setNavigateTo(WEBCASTS_ADMIN_CREATE));
    }

    return (<Container className="min-vh-72">
        <Row>
            <Col>
                <AppBreadcrumb headText="Webcasts" />
            </Col>
        </Row>
        <Row>
            <Col>
            </Col>
        </Row>
        <Row className='pb-1 mb-2 mt-2'>
            <Col md={3}>
                <h3 className='mb-4 mt-2'>Webcasts</h3>
            </Col>
            <Col md={{ offset: 6, size: 3 }}>
                {isRoleClientAdmin && <Button color={'success'} onClick={createNewWebcast} block>
                    Create new webcast
                </Button>}
            </Col>
        </Row>
        <Row className='mb-5'>
            <Col>
                {(!data || data.length === 0) ? (
                    <div className="alert alert-primary mt-2">
                        There are no webcasts available, please add one.
                    </div>
                ) : (
                    <DataTableExtensions filter={true} export={false} print={false} filterPlaceholder="Search webcast" {...tableData}>
                        <DataTable
                            columns={columns}
                            data={data ? data : []}
                            noHeader
                            pagination
                            highlightOnHover
                            customStyles={customStyles}
                        />
                    </DataTableExtensions>
                )}
            </Col >
        </Row>
    </Container>)
}

export default WebcastsList;