import { all, call, fork, put, select, takeEvery } from 'redux-saga/effects';
import {
    GET_LIST_ANNOUNCEMENTS,
    getListAnnouncementsSuccess,
    getAdminListAnnouncementsSuccess,
    GET_ADMIN_LIST_ANNOUNCEMENTS,
    HIDE_ANNOUNCEMENT,
    CREATE_ANNOUNCEMENT,
    UPDATE_ANNOUNCEMENT,
    DELETE_ANNOUNCEMENT
} from '../actions/announcements.actions';
import { genericError } from '../actions/error.actions';
import { disableOverlay, enableOverlay, setNavigateTo, setToast } from '../actions/ui.actions';
import { getAdminListAnnouncements, getListAnnouncements, hideAnnouncement, createAnnouncement, updateAnnouncement, removeAnnouncement } from '../../services/announcementsService';
import { CLIENT_ADMIN_ANNOUNCEMENTS } from '../../navigation/ROUTE_CONSTANTS';
import { TOAST_SEVERITY_SUCCESS } from '../reducers/ui.reducer';
import { USER_ROLE_FACULTY, USER_ROLE_STUDENT } from '../reducers/auth.reducer';

function* getAdminListAnnouncementsEffect() {
    yield put(enableOverlay('Loading announcement...'));
    try {
        const announcementsList = yield call(getAdminListAnnouncements);
        yield put(getAdminListAnnouncementsSuccess(announcementsList));
    } catch (err) {
        console.log('error:', err);
        yield put(genericError(err));
    }
    yield put(disableOverlay());
}

function* getListAnnouncementsEffect() {
    try {
        const announcementsList = yield call(getListAnnouncements);
        yield put(getListAnnouncementsSuccess(announcementsList));
    } catch (err) {
        console.log('error:', err);
        yield put(genericError(err));
    }
}

function* hideAnnouncementEffect({ payload }) {
    yield put(enableOverlay('Loading announcement...'));
    try {
        yield call(hideAnnouncement, payload);
        const announcementsList = yield call(getListAnnouncements);
        yield put(getListAnnouncementsSuccess(announcementsList));
    } catch (err) {
        console.log('error:', err);
        yield put(genericError(err));
    }
    yield put(disableOverlay());
}

function* createAnnouncementEffect({ payload }) {
    yield put(enableOverlay('Creating announcement...'));
    try {
        const users = yield select((state => state.usersByAdmin.usersList));
        let recipients = [];

        if (payload.recipientsFaculty) {
            recipients = [...recipients, ...users.filter(user => user.role === USER_ROLE_FACULTY)];
        }

        if (payload.recipientsStudents) {
            recipients = [...recipients, ...users.filter(user => user.role === USER_ROLE_STUDENT)];
        }
        const newAnnouncement = {
            ...payload,
            recipients
        }

        const announcement = yield call(createAnnouncement, newAnnouncement);
        if (announcement) {
            yield put(setToast({
                severity: TOAST_SEVERITY_SUCCESS,
                detail: 'The announcement has been created!',
            }));
            yield put(setNavigateTo(CLIENT_ADMIN_ANNOUNCEMENTS));
        }

    } catch (err) {
        console.log('error:', err);
        yield put(genericError(err));
    }
    yield put(disableOverlay());
}

/**
 * updateAnnouncementEffect
 */
function* updateAnnouncementEffect({ payload }) {
    try {
        yield put(enableOverlay('Updating announcement ...'));
        const announcement = yield call(updateAnnouncement, payload);
        if (announcement) {
            yield put(setToast({
                severity: TOAST_SEVERITY_SUCCESS,
                detail: 'The announcement has been updated!',
            }));
            yield put(setNavigateTo(CLIENT_ADMIN_ANNOUNCEMENTS));
        }
    } catch (err) {
        console.log('error:', err);
        yield put(genericError(err));
    }
    yield put(disableOverlay());
}

/**
 * deleteAnnouncementEffect
 */
function* deleteAnnouncementEffect({ payload }) {
    try {
        yield put(enableOverlay('Deleting announcement ...'));
        const announcement = yield call(removeAnnouncement, payload);
        if (announcement) {
            yield put(setToast({
                severity: TOAST_SEVERITY_SUCCESS,
                detail: 'The announcement has been deleted!',
            }));
            yield put(setNavigateTo(CLIENT_ADMIN_ANNOUNCEMENTS));
        }
    } catch (err) {
        console.log('error:', err);
        yield put(genericError(err));
    }
    yield put(disableOverlay());
}

export function* watchGetAdminListAnnouncements() {
    yield takeEvery(GET_ADMIN_LIST_ANNOUNCEMENTS, getAdminListAnnouncementsEffect);
}

export function* watchGetListAnnouncements() {
    yield takeEvery(GET_LIST_ANNOUNCEMENTS, getListAnnouncementsEffect);
}

export function* watchHideAnnouncements() {
    yield takeEvery(HIDE_ANNOUNCEMENT, hideAnnouncementEffect);
}

export function* watchCreateAnnouncement() {
    yield takeEvery(CREATE_ANNOUNCEMENT, createAnnouncementEffect);
}

export function* watchUpdateAnnouncement() {
    yield takeEvery(UPDATE_ANNOUNCEMENT, updateAnnouncementEffect);
}

export function* watchDeleteAnnouncement() {
    yield takeEvery(DELETE_ANNOUNCEMENT, deleteAnnouncementEffect);
}

function* announcementSaga() {
    yield all([
        fork(watchGetAdminListAnnouncements),
        fork(watchGetListAnnouncements),
        fork(watchHideAnnouncements),
        fork(watchCreateAnnouncement),
        fork(watchUpdateAnnouncement),
        fork(watchDeleteAnnouncement),
    ])
}

export default announcementSaga;