import { matchPath } from 'react-router-dom';

import {
    FACULTY_MY_ACCOUNT,
    STUDENT_MY_ACCOUNT,
    USER_HOME,
    CLIENT_ADMIN_CUBICLES,
    CLIENT_ADMIN_USERS,
    CLIENT_ADMIN_CUBICLE_GROUPS,
    CUBICLES_MAP,
    CLIENT_ADMIN,
    LOGIN_FORM,
    CLIENT_ADMIN_FACULTY_REPORT,
    CLIENT_ADMIN_STUDENT_REPORT,
    CLIENT_ADMIN_LOGOFF_TIMES,
    EXERCISES,
    PROCEDURES,
    EDIT_PROFILE,
    CLIENT_ADMIN_ANNOUNCEMENTS,
    FACULTY_CLASSES,
    WEBCASTS_LANDING,
    WEBCASTS_ADMIN,
} from '../../navigation/ROUTE_CONSTANTS';

import {
    USER_ROLE_CLIENT_ADMIN,
    USER_ROLE_FACULTY,
    USER_ROLE_STUDENT,
} from '../../redux/reducers/auth.reducer';

import { CUBICLE_VARIANT_NAVBAR } from '../../theme/components/Cubicle';

export const buildMenuItems = (
    userLogged,
    myCubicle,
    facultiesLoggedIn,
    homeTargetUrl,
    location,
    handleShowConfirm,
) => {
    let reportLink = null;
    switch (userLogged?.role) {
        case USER_ROLE_STUDENT:
            reportLink = STUDENT_MY_ACCOUNT;
            break;
        case USER_ROLE_FACULTY:
            reportLink = FACULTY_MY_ACCOUNT;
            break;
        default:
            reportLink = USER_HOME;
    }

    let menuItems = [];

    if (userLogged) {
        menuItems.push({
            type: 'link',
            props: {
                href: homeTargetUrl,
                text: 'Home',
                isActive:
                    matchPath(location.pathname, {
                        path: homeTargetUrl,
                        exact: true,
                    }) !== null,
            },
        });

        if (userLogged.role !== USER_ROLE_CLIENT_ADMIN) {
            menuItems.push({
                type: 'link',
                props: {
                    href: EXERCISES,
                    text: 'Exercises',
                    isActive:
                        matchPath(location.pathname, { path: EXERCISES }) !==
                        null,
                },
            });
        }

        menuItems.push({
            type: 'link',
            props: {
                href: WEBCASTS_LANDING,
                text: 'Webcasts',
                isActive:
                    matchPath(location.pathname, { path: WEBCASTS_LANDING }) !==
                    null,
            },
        });

        menuItems.push({
            type: 'link',
            props: {
                href: CUBICLES_MAP,
                text: 'Cubicles',
                isActive:
                    matchPath(location.pathname, { path: CUBICLES_MAP }) !==
                    null,
            },
        });
    }

    menuItems.push({ type: 'separator', props: {} });

    menuItems.push({
        type: 'dropdown',
        props: {
            text: `Faculty logged in: ${facultiesLoggedIn.length}`,
            cubicle: null,
            isActive: false,
            items:
                facultiesLoggedIn.length > 0
                    ? facultiesLoggedIn.map((faculty) => ({
                        href: '#',
                        text: faculty.first_name + ' ' + faculty.last_name,
                        disabled: false,
                    }))
                    : [
                        {
                            href: '#',
                            text: 'No faculty logged in',
                            disabled: false,
                        },
                    ],
        },
    });

    if (userLogged && userLogged.role === USER_ROLE_CLIENT_ADMIN) {
        menuItems.push({ type: 'separator', props: {} });

        menuItems.push({
            type: 'dropdown',
            props: {
                text: 'Admin',
                cubicle: null,
                isActive:
                    matchPath(location.pathname, {
                        path: CLIENT_ADMIN,
                        exact: false,
                    }) !== null,
                items: [
                    {
                        href: CLIENT_ADMIN_ANNOUNCEMENTS,
                        text: 'Announcements',
                        disabled: false,
                    },
                    {
                        href: CLIENT_ADMIN_CUBICLES,
                        text: 'Cubicles',
                        disabled: false,
                    },
                    {
                        href: CLIENT_ADMIN_CUBICLE_GROUPS,
                        text: 'Cubicle Groups',
                        disabled: false,
                    },
                    {
                        href: EXERCISES,
                        text: 'Exercises',
                        disabled: false,
                    },
                    {
                        href: CLIENT_ADMIN_LOGOFF_TIMES,
                        text: 'Logoff Times',
                        disabled: false,
                    },
                    {
                        href: PROCEDURES,
                        text: 'Procedures',
                        disabled: false,
                    },
                    {
                        href: CLIENT_ADMIN_USERS,
                        text: 'Users',
                        disabled: false,
                    },
                    {
                        href: WEBCASTS_ADMIN,
                        text: 'Webcasts',
                        disabled: false,
                    },
                ],
            },
        });
    }

    if (userLogged) {
        menuItems.push({ type: 'separator', props: {} });

        let myAccountMenu = {
            type: 'dropdown',
            props: {
                text: 'My account',
                cubicle: null,
                isActive:
                    matchPath(reportLink, {
                        path: location.pathname,
                        exact: true,
                    }) !== null,
                items: [
                    { href: EDIT_PROFILE, text: 'Profile', disabled: false },
                ],
            },
        };

        if (userLogged.role === USER_ROLE_FACULTY) {
            myAccountMenu.props.items.push({
                href: FACULTY_CLASSES,
                text: 'Classes',
                disabled: false,
            });
        }

        if ([USER_ROLE_STUDENT, USER_ROLE_FACULTY].includes(userLogged.role)) {
            myAccountMenu.props.items.push({
                href: reportLink,
                text: 'Reports',
                disabled: false,
            });
        } else if (userLogged.role === USER_ROLE_CLIENT_ADMIN) {
            myAccountMenu.props.items.push({
                href: CLIENT_ADMIN_FACULTY_REPORT,
                text: 'Faculty Report',
                disabled: false,
            });
            myAccountMenu.props.items.push({
                href: CLIENT_ADMIN_STUDENT_REPORT,
                text: 'Student Report',
                disabled: false,
            });
        }

        if (myCubicle?.name) {
            myAccountMenu.props.cubicle = {
                variant: CUBICLE_VARIANT_NAVBAR,
                cubicleData: myCubicle,
                cubicleLogData: myCubicle.last_cubicle_log,
            };
        }

        myAccountMenu.props.items.push({
            onclick: handleShowConfirm,
            text: 'Log out',
            disabled: false,
            className: 'd-none d-md-block',
        });

        menuItems.push(myAccountMenu);

        menuItems.push({
            type: 'link',
            props: {
                onclick: handleShowConfirm,
                text: 'Log out',
                disabled: false,
                className: 'd-block d-md-none',
            },
        });

    } else {
        // not userLogged
        menuItems.push({ type: 'separator', props: {} });
        menuItems.push({
            type: 'link',
            props: {
                href: LOGIN_FORM,
                text: 'Login',
                isActive:
                    matchPath(location.pathname, {
                        path: LOGIN_FORM,
                        exact: true,
                    }) !== null,
            },
        });
    }

    return menuItems;
};
