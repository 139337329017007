import { useEffect, useState } from 'react'
import { Container, Row, Col, Button } from 'reactstrap';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import AppBreadcrumb from '../../components/ui/AppBreadcrumb';
import { setNavigateTo } from '../../redux/actions/ui.actions';
import { CLIENT_ADMIN_LOGOFF_TIMES } from '../../navigation/ROUTE_CONSTANTS';
import { deleteLogoffTime } from '../../redux/actions/logoff_times.actions';
import { getOneLogoffTime } from '../../services/logoffTimesService';
import { FREQUENCY_UI_NAMES } from '../../redux/reducers/logoff_times.reducers';
import { FORMAT_DATETIME_SHORT, isoStringToFormatted } from '../../utils/date_time';

function ClientAdminLogoffTimeDelete() {
    const dispatch = useDispatch();

    const { logoffTimeId } = useParams();

    const [rowData, setRowData] = useState('');

    useEffect(() => {
        document.title = process.env.REACT_APP_SITE_TITLE + ' - Delete Logoff Time';
    });

    useEffect(() => {
        getOneLogoffTime(logoffTimeId).then((data) => setRowData(data))
    }, [logoffTimeId]);

    const handleCancel = () => {
        dispatch(setNavigateTo(CLIENT_ADMIN_LOGOFF_TIMES));
    }

    const handleDelete = () => {
        dispatch(deleteLogoffTime(rowData.id));
    }

    return (
        <Container className='mb-5 pb-5'>
            <Row>
                <Col>
                    <AppBreadcrumb headText="Delete Logoff Time" crumbList={[{ linkTo: CLIENT_ADMIN_LOGOFF_TIMES, label: 'Logoff Times' }]} />
                </Col>
            </Row>
            <Row>
                <Col>
                    <div className='vstack col-md-4 mx-auto mt-4 text-center'>
                        <h4 className='pt-2 pb-3 text-center'>Delete Logoff Time</h4>
                        <h5 className='mt-3 mb-4 pt-2 pb-2'>Are you sure you want to remove this logoff time?</h5>
                        <p>Logoff Base Date : <strong>{isoStringToFormatted(rowData.base_dt_utc, FORMAT_DATETIME_SHORT)}</strong></p>
                        <p>Logoff Frequency: <strong>{FREQUENCY_UI_NAMES[rowData.frequency]}</strong></p>
                    </div>
                </Col>
            </Row>
            <Row>
                <p className="mt-4 mb-4 text-center">
                    <Button color={'success'} className="me-2" onClick={handleDelete}>
                        Delete Logoff Time
                    </Button>
                    <Button color={'secondary'} onClick={handleCancel} >
                        Cancel
                    </Button>
                </p>
            </Row>
        </Container>
    )
}

export default ClientAdminLogoffTimeDelete;
