import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Col, Container, Row, UncontrolledAccordion } from 'reactstrap';
import FacultyHomeCubicleGrid, { CUBICLE_GRID_TYPE_ASSISTANCE_REQUEST, CUBICLE_GRID_TYPE_MY_LIST, CUBICLE_GRID_TYPE_OCCUPIED, CUBICLE_GRID_TYPE_SPECIAL_REQUEST } from '../../components/faculties/FacultyHomeCubicleGrid';
import ChatUI from '../../components/general/ChatUI';
import { getOnGoingQueue } from '../../redux/actions/cubicle_logs.actions';

const facultyHomeSections = [
    CUBICLE_GRID_TYPE_MY_LIST,
    CUBICLE_GRID_TYPE_SPECIAL_REQUEST,
    CUBICLE_GRID_TYPE_ASSISTANCE_REQUEST,
    CUBICLE_GRID_TYPE_OCCUPIED
];

const FacultyHome = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getOnGoingQueue());
        document.title = process.env.REACT_APP_SITE_TITLE + ' - Faculty Home';
    }, [dispatch]);

    return (
        <Container className='min-vh-72'>
            <Row className="mt-5 mb-4">
                <Col xs={12} lg={8}>
                    <UncontrolledAccordion defaultOpen={facultyHomeSections} stayOpen className='mb-5'>
                        {facultyHomeSections.map((section) => (
                            <FacultyHomeCubicleGrid gridType={section} key={section} />
                        ))}
                    </UncontrolledAccordion>
                </Col>
                <Col xs={12} lg={4}>
                    <ChatUI location='FacultyHome' />
                </Col>
            </Row>
        </Container>
    );
};

export default FacultyHome;