import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { WEBCASTS_ADMIN } from '../../navigation/ROUTE_CONSTANTS';
import { createWebcast, getOneWebcast, getPublicWebcast, getPublicWebcasts, getWebcasts, removeWebcast, updateWebcast } from '../../services/webcastsService';
import { genericError } from '../actions/error.actions';
import { disableOverlay, enableOverlay, setFormSubmittedSuccess, setNavigateTo, setToast } from '../actions/ui.actions';
import {
    CREATE_WEBCAST,
    DELETE_WEBCAST, getListPublicWebcastsSuccess, getListWebcastsSuccess, getPublicWebcastSuccess, getWebcast,
    getWebcastSuccess, GET_LIST_PUBLIC_WEBCASTS, GET_LIST_WEBCASTS, GET_PUBLIC_WEBCAST, GET_WEBCAST, UPDATE_WEBCAST
} from '../actions/webcasts.actions';
import { TOAST_SEVERITY_SUCCESS } from '../reducers/ui.reducer';

/**
 * getListWebcastsEffect
 */
function* getListWebcastsEffect({ payload }) {
    yield put(enableOverlay('Loading webcasts ...'));
    try {
        const webcastsList = yield call(getWebcasts, payload);
        if (webcastsList) {
            yield put(getListWebcastsSuccess(webcastsList));
        }
    } catch (err) {
        console.log('error:', err);
        yield put(genericError(err));
    }
    yield put(disableOverlay());
}

/**
 * getListPublicWebcastsEffect
 */
function* getListPublicWebcastsEffect({ payload }) {
    yield put(enableOverlay('Loading webcasts ...'));
    try {
        const webcastsPublicList = yield call(getPublicWebcasts, payload);
        if (webcastsPublicList) {
            yield put(getListPublicWebcastsSuccess(webcastsPublicList));
        }
    } catch (err) {
        console.log('error:', err);
        yield put(genericError(err));
    }
    yield put(disableOverlay());
}

/**
 * getOneWebcastEffect
 */
function* getOneWebcastEffect({ payload }) {
    yield put(enableOverlay('Loading webcast ...'));
    try {
        const webcast = yield call(getOneWebcast, payload);
        if (webcast) {
            yield put(getWebcastSuccess(webcast));
        }
    } catch (err) {
        console.log('error:', err);
        yield put(genericError(err));
    }
    yield put(disableOverlay());
}

/**
 * getPublicWebcastEffect
 */
function* getPublicWebcastEffect({ payload }) {
    yield put(enableOverlay('Loading webcast ...'));
    try {
        const webcast = yield call(getPublicWebcast, payload);
        if (webcast) {
            yield put(getPublicWebcastSuccess(webcast));
        }
    } catch (err) {
        console.log('error:', err);
        yield put(genericError(err));
    }
    yield put(disableOverlay());
}

/**
 * createWebcastsEffect
 */
function* createWebcastEffect({ payload }) {
    try {
        yield put(enableOverlay('Creating webcast ...'));
        const webcast = yield call(createWebcast, payload);
        if (webcast) {
            yield put(setToast({
                severity: TOAST_SEVERITY_SUCCESS,
                detail: 'The webcast has been created!',
            }));
            yield put(getWebcastSuccess(webcast));
            yield put(setFormSubmittedSuccess(true));
        }
    } catch (err) {
        console.log('error:', err);
        yield put(genericError(err));
    }
    yield put(disableOverlay());
}

/**
 * updateWebcastEffect
 */
function* updateWebcastEffect({ payload }) {
    try {
        yield put(enableOverlay('Updating webcast ...'));
        const webcast = yield call(updateWebcast, payload);
        if (webcast) {
            yield put(setToast({
                severity: TOAST_SEVERITY_SUCCESS,
                detail: 'The webcast has been updated!',
            }));
            yield put(setFormSubmittedSuccess(true));
            yield put(getWebcast(webcast.id));
        }
    } catch (err) {
        console.log('error:', err);
        yield put(genericError(err));
    }
    yield put(disableOverlay());
}

/**
 * deleteWebcastEffect
 */
function* deleteWebcastEffect({ payload }) {
    try {
        yield put(enableOverlay('Deleting webcast ...'));
        const webcast = yield call(removeWebcast, payload);
        if (webcast) {
            yield put(setToast({
                severity: TOAST_SEVERITY_SUCCESS,
                detail: 'The webcast has been deleted!',
            }));
            yield put(setNavigateTo(WEBCASTS_ADMIN));
        }
    } catch (err) {
        console.log('error:', err);
        yield put(genericError(err));
    }
    yield put(disableOverlay());
}

export function* watchGetListWebcasts() {
    yield takeEvery(GET_LIST_WEBCASTS, getListWebcastsEffect);
}

export function* watchGetListPublicWebcasts() {
    yield takeEvery(GET_LIST_PUBLIC_WEBCASTS, getListPublicWebcastsEffect);
}

export function* watchGetOneWebcast() {
    yield takeEvery(GET_WEBCAST, getOneWebcastEffect);
}

export function* watchGetPublicWebcast() {
    yield takeEvery(GET_PUBLIC_WEBCAST, getPublicWebcastEffect);
}

export function* watchCreateWebcast() {
    yield takeEvery(CREATE_WEBCAST, createWebcastEffect);
}

export function* watchUpdateWebcast() {
    yield takeEvery(UPDATE_WEBCAST, updateWebcastEffect);
}

export function* watchDeleteWebcast() {
    yield takeEvery(DELETE_WEBCAST, deleteWebcastEffect);
}

function* webcastsSaga() {
    yield all([
        fork(watchGetListWebcasts),
        fork(watchGetOneWebcast),
        fork(watchGetListPublicWebcasts),
        fork(watchGetPublicWebcast),
        fork(watchCreateWebcast),
        fork(watchUpdateWebcast),
        fork(watchDeleteWebcast),
    ]);
}

export default webcastsSaga;
