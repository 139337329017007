import axios from 'axios';
import { buildPaginationUrlParams } from '../utils/index';
import { API_EXERCISES } from './API_CONSTANTS';

export const getExercises = (paginationParams = {}) => {
    paginationParams = {
        ...paginationParams,
        page: 1,
        limit: 99,
    };
    const url = API_EXERCISES + buildPaginationUrlParams(paginationParams);

    return new Promise((resolve, reject) => {
        try {
            axios.get(url)
                .then((res) => {
                    console.log('Exercises loaded', res);
                    resolve(res.data);
                })
                .catch((err) => {
                    console.log('Get exercises error: ', err);
                    reject('Get exercises error');
                })
        }
        catch (error) {
            console.log('Get exercises error', error);
            reject('Get exercises error');
        }
    })
}

export const getOneExercise = (exerciseId) => {
    const url = API_EXERCISES + '/' + exerciseId;

    return new Promise((resolve, reject) => {
        try {
            axios.get(url)
                .then((res) => {
                    console.log('Exercise loaded: ', res);
                    resolve(res.data);
                })
                .catch((err) => {
                    console.log('Get one exercise error: ', err);
                    reject('Get one exercise error');
                });
        } catch (error) {
            console.error('Get one exercise error: ', error);
            reject('Get one exercise error');
        }
    });
}

export const createExercise = (exercise) => {
    const url = API_EXERCISES;

    return new Promise((resolve, reject) => {
        try {
            axios.post(url, exercise)
                .then((res) => {
                    console.log('Exercise created: ', res);
                    resolve(res.data);
                })
                .catch((err) => {
                    console.log('Create exercise error: ', err);
                    reject('Create exercise error');
                });
        } catch (error) {
            console.error('Create exercise error: ', error);
            reject('Create exercise error');
        }
    });
}

export const updateExercise = (exercise) => {
    const url = API_EXERCISES + '/' + exercise.id;

    return new Promise((resolve, reject) => {
        try {
            axios.patch(url, exercise)
                .then((res) => {
                    console.log('Exercise updated: ', res);
                    resolve(res.data);
                })
                .catch((err) => {
                    console.log('Update exercise error: ', err);
                    reject('Update exercise error');
                });
        } catch (error) {
            console.error('Update exercise error: ', error);
            reject('Update exercise error');
        }
    });
}

export const removeExercise = (exerciseId) => {
    const url = API_EXERCISES + '/' + exerciseId;

    return new Promise((resolve, reject) => {
        try {
            axios.delete(url)
                .then((res) => {
                    console.log('Exercise removed: ', res);
                    resolve(res);
                })
                .catch((err) => {
                    console.log('Remove exercise error: ', err);
                    reject('Remove exercise error');
                });
        } catch (error) {
            console.error('Remove exercise error: ', error);
            reject('Remove exercise error');
        }
    });
}
