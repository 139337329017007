import { configureStore } from '@reduxjs/toolkit';
import { logger } from 'redux-logger';
import createSagaMiddleware from 'redux-saga';
import { AdminConfigurationsReducer } from './reducers/admin.configurations.reducers';
import {
    AdminInvoiceReducer, AdminInvoicesReducer
} from './reducers/admin.invoices.reducers';
import { AdminSettingsReducer } from './reducers/admin.settings.reducers';
import { UsersByAdminReducer } from './reducers/admin.users.reducers';
import { AdminAnnouncementReducer, AnnouncementReducer } from './reducers/announcements.reducers';
import { AuthReducer } from './reducers/auth.reducer';
import { FacultyClassesReducer } from './reducers/classes.reducers';
import { AdminClientsReducer } from './reducers/clients.reducers';
import {
    CubicleQueueReducer, CubiclesAdminReducer, CubiclesReducer
} from './reducers/cubicles.reducers';
import {
    AdminCubicleGroupsReducer,
    CubicleGroupsReducer
} from './reducers/cubicle_groups.reducers';
import { CubicleLogsReducer } from './reducers/cubicle_logs.reducers';
import { ErrorReducer } from './reducers/error.reducer';
import { ExerciseReducer } from './reducers/exercises.reducer';
import { InvoicesReducer } from './reducers/invoices.reducer';
import { LayoutReducer } from './reducers/layout.reducer';
import { AdminLogoffTimesReducer } from './reducers/logoff_times.reducers';
import { MyCubicleReducer } from './reducers/my_cubicle.reducer';
import {
    AdminProceduresReducer,
    ProceduresReducer
} from './reducers/procedures.reducers';
import { AdminStudentExercisesReducer, StudentExercisesReducer } from './reducers/student_exercises.reducers';
import { UIReducer } from './reducers/ui.reducer';
import {
    FacultiesLoggedInReducer, UsersReducer
} from './reducers/users.reducer';
import { WebcastReducer } from './reducers/webcasts.reducer';
import appSaga from './saga';

const appReducer = {
    // list of reducers
    adminAnnouncements: AdminAnnouncementReducer,
    adminClients: AdminClientsReducer,
    adminConfigurations: AdminConfigurationsReducer,
    adminCubicleGroups: AdminCubicleGroupsReducer,
    adminInvoice: AdminInvoiceReducer,
    adminInvoices: AdminInvoicesReducer,
    adminLogoffTimes: AdminLogoffTimesReducer,
    adminProcedures: AdminProceduresReducer,
    adminSettings: AdminSettingsReducer,
    adminStudentExercises: AdminStudentExercisesReducer,
    announcements: AnnouncementReducer,
    auth: AuthReducer,
    cubicleGroups: CubicleGroupsReducer,
    cubicleLogs: CubicleLogsReducer,
    cubicleQueue: CubicleQueueReducer,
    cubicles: CubiclesReducer,
    cubiclesAdmin: CubiclesAdminReducer,
    errors: ErrorReducer,
    exercises: ExerciseReducer,
    facultiesLoggedIn: FacultiesLoggedInReducer,
    facultyClasses: FacultyClassesReducer,
    invoices: InvoicesReducer,
    layout: LayoutReducer,
    myCubicle: MyCubicleReducer,
    procedures: ProceduresReducer,
    studentExercises: StudentExercisesReducer,
    ui: UIReducer,
    users: UsersReducer,
    usersByAdmin: UsersByAdminReducer,
    webcasts: WebcastReducer,
};

// create the saga middleware
const sagaMiddleware = createSagaMiddleware();

const devMode = process.env.NODE_ENV === 'development';

export const store = configureStore({
    reducer: appReducer,
    devTools: devMode,
    middleware: (getDefaultMiddleware) => {
        let middleware = getDefaultMiddleware({ thunk: false }).concat(sagaMiddleware);
        if (devMode) {
            middleware.concat(logger);
        }
        return middleware;
    },
});

sagaMiddleware.run(appSaga);
