import React, { useEffect } from "react";
import { Container, Row, Col } from 'reactstrap';
import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPen, faTrash } from '@fortawesome/free-solid-svg-icons'
import AppBreadcrumb from "../../components/ui/AppBreadcrumb";
import { useDispatch, useSelector } from 'react-redux';
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import { Link } from "react-router-dom";
import { FACULTY_CLASSES_CREATE, FACULTY_CLASSES_DELETE, FACULTY_CLASSES_UPDATE } from '../../navigation/ROUTE_CONSTANTS';
import { setNavigateTo } from "../../redux/actions/ui.actions";
import { getListClasses } from "../../redux/actions/classes.actions";
import { customStyles } from '../../theme/assets/dataTableCustomStyles';

const FacultyClasses = () => {

    const dispatch = useDispatch();
    const classesList = useSelector((state) => state.facultyClasses.classesList);

    useEffect(() => {
        document.title = process.env.REACT_APP_SITE_TITLE + ' - Classes';
        dispatch(getListClasses());
    }, [dispatch]);

    const createClass = () => {
        dispatch(setNavigateTo(FACULTY_CLASSES_CREATE));
    }

    const columns = [
        {
            name: 'Name',
            selector: row => row.name,
            sortable: true,
            width: '30%'
        },
        {
            name: 'Description',
            selector: row => row.description,
            sortable: true,
            width: '50%'
        },
        {
            name: 'Actions',
            center: true,
            cell: (row) => (<>
                <Link className='text-dark btn btn-link btn-light' to={FACULTY_CLASSES_UPDATE.replace(':classId', row.id)} >
                    <FontAwesomeIcon icon={faPen} title="Edit" />
                </Link>
                <Link className='text-dark btn btn-link btn-light' to={FACULTY_CLASSES_DELETE.replace(':classId', row.id)} >
                    <FontAwesomeIcon icon={faTrash} title="Delete" />
                </Link>
            </>),
            width: '20%'
        }
    ]

    return (<Container>
        <Row>
            <Col>
                <AppBreadcrumb headText="Classes" />
            </Col>
        </Row>
        <Row>
            <Col>
            </Col>
        </Row>
        <Row className='pb-1 mb-2 mt-2'>
            <Col md={3}>
                <h3 className='mb-4 mt-2'>Classes</h3>
            </Col>
            <Col md={{ offset: 6, size: 3 }}>
                <Button color={'success'} onClick={createClass} block>
                    Create new class
                </Button>
            </Col>
        </Row>
        <Row className='mb-5'>
            <Col>
                {classesList && classesList.length === 0 ? (
                    <div className="alert alert-primary mt-2">
                        Empty list
                    </div>
                ) : (
                    <DataTableExtensions filter={true} export={false} print={false} filterPlaceholder="Search class"
                        columns={columns}
                        data={classesList} >
                        <DataTable
                            columns={columns}
                            data={classesList}
                            noHeader
                            pagination
                            highlightOnHover
                            customStyles={customStyles}
                        />
                    </DataTableExtensions>
                )}
            </Col >
        </Row>
    </Container>)
}

export default FacultyClasses;