import { Dropzone, FileItem } from "@dropzone-ui/react";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { saveAndUpdateFiles } from "../../services/files.services";

const DragAndDrop = ({ submitForm, uploading, name, setFieldValue, setUploading, accept = null, maxFiles = null }) => {
    const dispatch = useDispatch();
    const [files, setFiles] = useState([]);
    const [uploadedFiles, setUploadedFiles] = useState([]);

    useEffect(() => {
        setFiles([]);
    }, [])

    useEffect(() => {
        if (uploading) {
            // Select files for upload if not already uploaded
            let forUploadFiles = files.filter(file => {
                let fileFound = uploadedFiles.find(uploadedFile => uploadedFile.id === file.id);
                return !fileFound || !fileFound.uploaded
            });

            let currentUploadedFiles = [...uploadedFiles];
            saveAndUpdateFiles(forUploadFiles, dispatch).then((filesSaved) => {
                // Add uploaded files to the list of uploaded files
                filesSaved.forEach(fileSaved => {
                    currentUploadedFiles.push(fileSaved);
                });
                setUploadedFiles(currentUploadedFiles);

            }).catch((error) => {
                console.log(error);
            }).finally(() => {
                let formFieldValue = currentUploadedFiles.map(file => file.file_uuid);
                setFieldValue(name, formFieldValue);
                setUploading(false);
                submitForm();
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [uploading])

    const updateFiles = (incomingFiles) => {
        setFiles(incomingFiles);
    };

    const removeFile = (id) => {
        setFiles(files.filter((file) => file.id !== id));
        setUploadedFiles(uploadedFiles.filter((uploadedFile) => uploadedFile.id !== id));
    };

    return (
        <Dropzone
            minHeight={'12rem'}
            onChange={updateFiles}
            value={files}
            label='Browse to find for drag files here'
            accept={accept}
            maxFiles={maxFiles}
        >
            {files.length > 0 &&
                files.map((file) => (
                    <FileItem {...file} onDelete={removeFile} key={file.id} info />
                ))}
        </Dropzone>
    )
}

export default DragAndDrop;
