import { useEffect, useState } from 'react';
import { Container, Row, Col, Button, FormGroup, Input, Label } from 'reactstrap';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import AppBreadcrumb from '../../components/ui/AppBreadcrumb';
import { CLIENT_ADMIN_ANNOUNCEMENTS } from '../../navigation/ROUTE_CONSTANTS';
import Select from "react-select";
import { getOneAnnouncement } from '../../services/announcementsService';
import { createAnnouncement, updateAnnouncement } from '../../redux/actions/announcements.actions';
import { getUsersListByAdmin } from '../../redux/actions/admin.users.actions';
import { USER_ROLE_FACULTY, USER_ROLE_STUDENT } from '../../redux/reducers/auth.reducer';
import { ANNOUNCEMENT_SEVERITY_UI_NAMES } from '../../redux/reducers/announcements.reducers';

const ClientAdminAnnouncementForm = () => {

    const dispatch = useDispatch();
    const { announcementId } = useParams();
    const [pageTitle, setPageTitle] = useState('');
    const [actionButtonLabel, setActionButtonLabel] = useState('Send NOW');
    const [initialFormValues, setInitialFormValues] = useState({
        title: '',
        message: '',
        severity: '',
        recipientsStudents: false,
        recipientsFaculty: false,
        at_least_one_recipients: false // This is a dummy field to validate at least one recipient is selected
    })

    const handleSaveAnnouncement = (values) => {
        delete values.recipientsFaculty;
        delete values.recipientsStudents;
        if (announcementId) {
            values.id = announcementId;
            dispatch(updateAnnouncement(values));
        } else {
            dispatch(createAnnouncement(values));
        }
    }

    useEffect(() => {
        dispatch(getUsersListByAdmin());
        if (announcementId) {
            setPageTitle('Edit announcement');
            setActionButtonLabel('Edit announcement');
            getOneAnnouncement(announcementId).then((data) => {
                setInitialFormValues({
                    title: data?.title,
                    message: data?.message,
                    severity: data?.severity,
                    recipientsFaculty: data.recipients.some(recipient => recipient.user_role === USER_ROLE_FACULTY),
                    recipientsStudents: data.recipients.some(recipient => recipient.user_role === USER_ROLE_STUDENT),
                })
            })
        }
        else {
            setPageTitle('Create announcement');
            setActionButtonLabel('Send NOW');
        }
    }, [announcementId, dispatch])

    return (
        <Container className='mb-5 pb-5'>
            <Row>
                <Col>
                    <AppBreadcrumb headText={pageTitle} crumbList={[{ linkTo: CLIENT_ADMIN_ANNOUNCEMENTS, label: 'Announcements' }]} />
                </Col>
            </Row>

            <Row>
                <Col>
                    <div className='vstack mx-auto'>
                        <h4 className='pt-2 pb-3 text-center'>{pageTitle}</h4>
                        <Formik
                            enableReinitialize={true}
                            initialValues={initialFormValues}
                            validationSchema={Yup.object({
                                title: Yup.string()
                                    .min(3, 'Name must be at least 3 characters length.')
                                    .max(255, 'Name must be smaller than 255 characters length.')
                                    .required('Please enter the title'),
                                message: Yup.string()
                                    .required('Please enter the message of the announcement'),
                                severity: Yup.string()
                                    .required('Please select a announcement type'),
                                recipientsStudents: Yup.boolean(),
                                recipientsFaculty: Yup.boolean(),
                                at_least_one_recipients: Yup.boolean()
                                    .test(
                                        'at_least_one_recipients_rule',
                                        'Please select at least one recipient',
                                        (value, testContext) => (testContext.parent.recipientsStudents || testContext.parent.recipientsFaculty)
                                    )
                            })}
                            onSubmit={(values) => { handleSaveAnnouncement(values) }}
                        >{({ values, setFieldValue }) => (
                            <Form>
                                <Row>
                                    <Col>
                                        <FormGroup>
                                            <Field type="text" name="title" placeholder="Title" as={Input} />
                                            <ErrorMessage component="div" className="text-danger" name="title" />
                                        </FormGroup>
                                        <FormGroup className='mt-4'>
                                            <Label>Message</Label>
                                            <Field type='textarea' name='message' rows={5} as={Input} />
                                            <ErrorMessage component="div" className="text-danger" name="message" />
                                        </FormGroup>
                                        <div className='d-flex'>
                                            <Label className='mt-3' style={{ width: '15%' }}>Alert type</Label>
                                            <FormGroup className='mt-3' style={{ width: '100%' }}>
                                                <Field id="severity" name="severity" as={Select}
                                                    placeholder="Alert type"
                                                    value={values.severity ? { value: values.severity, label: ANNOUNCEMENT_SEVERITY_UI_NAMES[values.severity] } : ''}
                                                    onChange={option => setFieldValue('severity', option.value)}
                                                    options={Object.entries(ANNOUNCEMENT_SEVERITY_UI_NAMES).map((severity) => {
                                                        return { value: severity[0], label: severity[1] }
                                                    })}
                                                    isSearchable={false}
                                                />
                                                <ErrorMessage component="div" className="text-danger" name="severity" />
                                            </FormGroup>
                                        </div>
                                        <div className='d-flex'>
                                            <Label className='mt-3' style={{ width: '15%' }}>Users</Label>
                                            <div>
                                                <FormGroup key='recipients-faculty' className='mt-3' check>
                                                    <Field type='checkbox' name='recipientsFaculty' id='recipients-faculty' as={Input} disabled={announcementId > 0} />
                                                    <Label for='recipients-faculty' check>Faculty</Label>
                                                </FormGroup>
                                                <FormGroup key='recipients-students' className='mt-3' check>
                                                    <Field type='checkbox' name='recipientsStudents' id='recipients-students' as={Input} disabled={announcementId > 0} />
                                                    <Label for='recipients-students' check>Students</Label>
                                                </FormGroup>
                                                <ErrorMessage component="div" className="text-danger" name="at_least_one_recipients" />
                                            </div>
                                        </div>
                                        <div className='text-center'>
                                            <Button color='success' className='mt-2 px-5' type='submit'>
                                                <span className='d-inline-block fs-5 px-3'>{actionButtonLabel}</span>
                                            </Button>
                                        </div>
                                    </Col>
                                </Row>
                            </Form>
                        )}
                        </Formik>
                    </div>
                </Col>
            </Row>
        </Container>
    )
}

export default ClientAdminAnnouncementForm;
