import { createAction } from "@reduxjs/toolkit";

//Announcement type
export const CREATE_ANNOUNCEMENT = '[ANNOUNCEMENT] Announcement created';
export const UPDATE_ANNOUNCEMENT = '[ANNOUNCEMENT] Announcement updated';
export const DELETE_ANNOUNCEMENT = '[ANNOUNCEMENT] Announcement deleted';
export const HIDE_ANNOUNCEMENT = '[ANNOUNCEMENT] Announcement hidden';
export const GET_LIST_ANNOUNCEMENTS = '[ANNOUNCEMENT] Getting list announcements';
export const GET_LIST_ANNOUNCEMENTS_SUCCESS = '[ANNOUNCEMENT] Getting announcements success!';
export const CLEAR_LIST_ANNOUNCEMENTS = '[ANNOUNCEMENT] Clearing announcements';
export const GET_ADMIN_LIST_ANNOUNCEMENTS = '[ANNOUNCEMENT] Getting admin list announcements';
export const GET_ADMIN_LIST_ANNOUNCEMENTS_SUCCESS = '[ANNOUNCEMENT] Getting admin announcements success!';

//Announcement actions
export const createAnnouncement = createAction(CREATE_ANNOUNCEMENT);
export const updateAnnouncement = createAction(UPDATE_ANNOUNCEMENT);
export const deleteAnnouncement = createAction(DELETE_ANNOUNCEMENT);
export const hideAnnouncement = createAction(HIDE_ANNOUNCEMENT);
export const getListAnnouncements = createAction(GET_LIST_ANNOUNCEMENTS);
export const getListAnnouncementsSuccess = createAction(GET_LIST_ANNOUNCEMENTS_SUCCESS);
export const clearListAnnouncements = createAction(CLEAR_LIST_ANNOUNCEMENTS);
export const getAdminListAnnouncements = createAction(GET_ADMIN_LIST_ANNOUNCEMENTS);
export const getAdminListAnnouncementsSuccess = createAction(GET_ADMIN_LIST_ANNOUNCEMENTS_SUCCESS);