import { useCallback, useEffect, useMemo, useState } from 'react';
import { Container, Row, Col, Button, FormGroup, Input, Label } from 'reactstrap';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import AppBreadcrumb from '../../components/ui/AppBreadcrumb';
import { FACULTY_CLASSES } from "../../navigation/ROUTE_CONSTANTS";
import { addStudentToClass, createClass, getClass, removeStudentFromClass, resetClass, updateClass, updateStudentInClass } from "../../redux/actions/classes.actions";
import { getListStudents } from '../../redux/actions/users.actions';
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import { customStyles } from '../../theme/assets/dataTableCustomStyles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash, faPlus } from '@fortawesome/free-solid-svg-icons'
import { STUDENT_CLASS_ACTION_REMOVE } from '../../redux/reducers/classes.reducers';
import * as Yup from 'yup';
import SectionTitle from '../../components/ui/SectionTitle';

const FacultyClassesForm = () => {

    const dispatch = useDispatch();
    const { classId } = useParams();

    const availableStudents = useSelector((state) => state.users.studentsList);
    const currentFacultyClass = useSelector((state) => state.facultyClasses.currentFacultyClass);
    const classStudentDataTable = useSelector((state) => state.facultyClasses.classStudentDataTable);

    const [selectableStudents, setSelectableStudents] = useState([]);
    const [pageTitle, setPageTitle] = useState('');
    const [initialFormValues, setInitialFormValues] = useState({
        name: '',
        description: '',
    })

    useEffect(() => {
        dispatch(getListStudents())
    }, [dispatch])

    useEffect(() => {
        dispatch(resetClass());
        if (classId) {
            setPageTitle('Edit class');
            dispatch(getClass(classId))
        } else {
            setPageTitle('Create class');
        }
    }, [classId, availableStudents, dispatch])

    useEffect(() => {
        if (currentFacultyClass) {
            setInitialFormValues({
                name: currentFacultyClass.name,
                description: currentFacultyClass.description,
            })
        } else {
            setInitialFormValues({
                name: '',
                description: '',
            })
        }
    }, [currentFacultyClass])

    useEffect(() => {
        let selectableStudents = availableStudents.filter(
            // Filter out students that are already in the class and are not marked for deletion
            (student) => !classStudentDataTable.some(
                (classStudent) =>
                    classStudent.student === student.username &&
                    (!classStudent.action || classStudent.action !== STUDENT_CLASS_ACTION_REMOVE)
            )
        );

        setSelectableStudents(selectableStudents);
    }, [availableStudents, classStudentDataTable])

    const handleSaveClass = (values) => {
        if (!classId) {
            dispatch(createClass({
                ...values,
                students: classStudentDataTable
            }));
        } else {
            dispatch(updateClass({
                id: classId,
                ...values,
                students: classStudentDataTable
            }));
        }
    }

    const handleAddStudent = (data) => {
        dispatch(addStudentToClass({ student: data.username }));
    }

    const handleDeleteStudent = useCallback((data) => {
        dispatch(removeStudentFromClass(data));
    }, [dispatch]);

    const handleInputOnChange = useCallback((e, data) => {
        let newClassGrade = e.target.value;
        dispatch(updateStudentInClass({ ...data, class_grade: newClassGrade }));
    }, [dispatch]);

    const columns = [
        {
            name: 'Student',
            selector: row => row.username,
            sortable: true,
            width: '60%'
        },
        {
            name: 'Add to Class',
            center: true,
            width: '40%',
            cell: (row) =>
                <Button className='text-dark btn btn-link btn-light' onClick={() => handleAddStudent(row)}>
                    <FontAwesomeIcon icon={faPlus} title="Add to Class" />
                </Button>
        }
    ]

    const columnsStudents = useMemo(() => [
        {
            name: 'Student',
            selector: row => row.student,
            sortable: true,
            width: '40%'
        },
        {
            name: 'Grade',
            center: true,
            cell: (row) =>
                <Input
                    className='h-75'
                    key={row.student}
                    value={row.class_grade ? row.class_grade : ''}
                    onChange={(e) => handleInputOnChange(e, row)}
                ></Input>
            ,
            width: '30%'
        },
        {
            name: 'Remove',
            center: true,
            cell: (row) =>
                <Button className='text-dark btn btn-link btn-light' onClick={() => handleDeleteStudent(row)}>
                    <FontAwesomeIcon icon={faTrash} title="Delete" />
                </Button>
            ,
            width: '30%'
        }
    ], [handleDeleteStudent, handleInputOnChange]);

    return (
        <Container className='mb-5 pb-5'>
            <Row>
                <Col>
                    <AppBreadcrumb headText={pageTitle} crumbList={[{ linkTo: FACULTY_CLASSES, label: 'Class' }]} />
                </Col>
            </Row>

            <Row>
                <Col>
                    <div className='vstack mx-auto'>
                        <h4 className='pt-2 pb-3 text-center'>{pageTitle}</h4>
                        <Formik
                            enableReinitialize={true}
                            initialValues={initialFormValues}
                            validationSchema={Yup.object({
                                name: Yup.string()
                                    .min(3, 'Name must be at least 3 characters length.')
                                    .max(255, 'Name must be smaller than 255 characters length.')
                                    .required('Please enter a name'),
                                description: Yup.string()
                                    .nullable(),
                            })}
                            onSubmit={(values) => { handleSaveClass(values) }}
                        >{() => (
                            <Form>
                                <Row>
                                    <Col>
                                        <FormGroup>
                                            <Field type="text" name="name" placeholder="Name" as={Input} />
                                            <ErrorMessage component="div" className="text-danger" name="name" />
                                        </FormGroup>
                                        <FormGroup className='mt-4'>
                                            <Label>Description</Label>
                                            <Field type='textarea' name='description' rows={5} as={Input} />
                                            <ErrorMessage component="div" className="text-danger" name="description" />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row className='mb-5'>
                                    <Col md={6}>
                                        <SectionTitle title='Eligible students' />
                                        {selectableStudents && selectableStudents.length === 0 ? (
                                            <div className="alert alert-primary mt-2">
                                                Empty list
                                            </div>
                                        ) : (
                                            <DataTableExtensions filter={true} export={false} print={false} filterPlaceholder="Search student"
                                                columns={columns}
                                                data={selectableStudents} >
                                                <DataTable
                                                    noHeader
                                                    pagination
                                                    highlightOnHover
                                                    customStyles={customStyles}
                                                />
                                            </DataTableExtensions>
                                        )}
                                    </Col >

                                    <Col md={6}>
                                        <SectionTitle title='Students already in the class' />
                                        <DataTableExtensions filter={true} export={false} print={false} filterPlaceholder="Search student"
                                            columns={columnsStudents}
                                            data={
                                                // only show students that are not marked for deletion
                                                classStudentDataTable.filter((student) => !student.action || student.action !== STUDENT_CLASS_ACTION_REMOVE)
                                            } >
                                            <DataTable
                                                noHeader
                                                pagination
                                                highlightOnHover
                                                customStyles={customStyles}
                                            />
                                        </DataTableExtensions>

                                    </Col >
                                </Row>
                                <Row>
                                    <div className='text-center'>
                                        <Button color='success' className='mt-2 px-5' type='submit'>
                                            <span className='d-inline-block fs-5 px-3'>Save class</span>
                                        </Button>
                                    </div>
                                </Row>
                            </Form>
                        )}
                        </Formik>
                    </div>
                </Col>
            </Row>
        </Container>
    )
}

export default FacultyClassesForm;