import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import {
    CREATE_STUDENT_EXERCISE,
    DELETE_STUDENT_EXERCISE,
    UPDATE_STUDENT_EXERCISE,
    GET_ADMIN_LIST_STUDENT_EXERCISES,
    GET_LIST_STUDENT_EXERCISES,
    getAdminListStudentExercisesSuccess,
    getStudentExercisesSuccess,
    GET_STUDENT_EXERCISES_DASHBOARD,
    getStudentExercisesDashboard as getStudentExercisesDashboardAction,
    getStudentExercisesDashboardSuccess,
    MOVE_TO_IN_PROGRESS_STUDENT_EXERCISE,
} from '../actions/student_exercises.actions';
import { createStudentExercise, getStudentExercises, getStudentExercisesDashboard, moveToInProgress, removeStudentExercise, updateStudentExercise } from '../../services/studentExercisesService';
import { genericError } from '../actions/error.actions';
import { disableOverlay, enableOverlay, setNavigateTo, setToast } from '../actions/ui.actions';
import { STUDENT_EXERCISES } from '../../navigation/ROUTE_CONSTANTS';
import { TOAST_SEVERITY_SUCCESS } from '../reducers/ui.reducer';

/**
 * getListStudentExercisesEffect
 */
function* getListStudentExercisesEffect({ payload }) {
    try {
        const studentExercisesList = yield call(getStudentExercises, payload);
        if (studentExercisesList) {
            yield put(getStudentExercisesSuccess(studentExercisesList));
        }
    } catch (err) {
        console.log('error:', err);
        yield put(genericError(err));
    }
}

/**
 * getStudentExercisesDashboardEffect
 */
function* getStudentExercisesDashboardEffect() {
    try {
        const studentExercisesDashboard = yield call(getStudentExercisesDashboard);
        if (studentExercisesDashboard) {
            yield put(getStudentExercisesDashboardSuccess(studentExercisesDashboard));
        }
    } catch (err) {
        console.log('error:', err);
        yield put(genericError(err));
    }
}

/**
 * getAdminListStudentExercisesEffect
 */
function* getAdminListStudentExercisesEffect({ payload }) {
    yield put(enableOverlay('Loading student exercises ...'));
    try {
        const studentExercisesList = yield call(getStudentExercises, payload);
        yield put(getAdminListStudentExercisesSuccess(studentExercisesList));
    } catch (err) {
        console.log('error:', err);
        yield put(genericError(err));
    }
    yield put(disableOverlay());
}

/**
 * createStudentExerciseEffect
 */
function* createStudentExerciseEffect({ payload }) {
    try {
        yield put(enableOverlay('Creating student exercise ...'));
        const studentExercise = yield call(createStudentExercise, payload);
        if (studentExercise) {
            yield put(setToast({
                severity: TOAST_SEVERITY_SUCCESS,
                detail: 'The student exercise has been created!',
            }));
            yield put(setNavigateTo(STUDENT_EXERCISES.replace(':studentUsername', studentExercise.student_username)));
        }
    } catch (err) {
        console.log('error:', err);
        yield put(genericError(err));
    }
    yield put(disableOverlay());
}

/**
 * updateStudentExerciseEffect
 */
function* updateStudentExerciseEffect({ payload }) {
    try {
        yield put(enableOverlay('Updating student exercise ...'));
        const studentExercise = yield call(updateStudentExercise, payload);
        if (studentExercise) {
            yield put(setToast({
                severity: TOAST_SEVERITY_SUCCESS,
                detail: 'The student exercise has been updated!',
            }));
            yield put(setNavigateTo(STUDENT_EXERCISES.replace(':studentUsername', studentExercise.student_username)));
        }
    } catch (err) {
        console.log('error:', err);
        yield put(genericError(err));
    }
    yield put(disableOverlay());
}

/**
 * deleteStudentExerciseEffect
 */
function* deleteStudentExerciseEffect({ payload }) {
    try {
        yield put(enableOverlay('Deleting student exercise ...'));
        const studentExercise = yield call(removeStudentExercise, payload);
        if (studentExercise) {
            yield put(setToast({
                severity: TOAST_SEVERITY_SUCCESS,
                detail: 'The student exercise has been deleted!',
            }));
            yield put(setNavigateTo(STUDENT_EXERCISES.replace(':studentUsername', payload.student_username)));
        }
    } catch (err) {
        console.log('error:', err);
        yield put(genericError(err));
    }
    yield put(disableOverlay());
}

/**
 * moveToInProgressStudentExerciseEffect
 */
 function* moveToInProgressStudentExerciseEffect({ payload }) {
    try {
        const status = yield call(moveToInProgress, payload);
        if (status) {
            yield put(getStudentExercisesDashboardAction());
        }
    } catch (err) {
        console.log('error:', err);
        yield put(genericError(err));
    }
}

export function* watchGetListStudentExercises() {
    yield takeEvery(GET_LIST_STUDENT_EXERCISES, getListStudentExercisesEffect);
}

export function* watchGeStudentExercisesDashboard() {
    yield takeEvery(GET_STUDENT_EXERCISES_DASHBOARD, getStudentExercisesDashboardEffect);
}

export function* watchGetAdminListStudentExercises() {
    yield takeEvery(GET_ADMIN_LIST_STUDENT_EXERCISES, getAdminListStudentExercisesEffect);
}

export function* watchCreateStudentExercise() {
    yield takeEvery(CREATE_STUDENT_EXERCISE, createStudentExerciseEffect);
}

export function* watchUpdateStudentExercise() {
    yield takeEvery(UPDATE_STUDENT_EXERCISE, updateStudentExerciseEffect);
}

export function* watchDeleteStudentExercise() {
    yield takeEvery(DELETE_STUDENT_EXERCISE, deleteStudentExerciseEffect);
}

export function* watchMoveToInProgressStudentExercise() {
    yield takeEvery(MOVE_TO_IN_PROGRESS_STUDENT_EXERCISE, moveToInProgressStudentExerciseEffect);
}

function* studentExercisesSaga() {
    yield all([
        fork(watchGetListStudentExercises),
        fork(watchGeStudentExercisesDashboard),
        fork(watchGetAdminListStudentExercises),
        fork(watchCreateStudentExercise),
        fork(watchUpdateStudentExercise),
        fork(watchDeleteStudentExercise),
        fork(watchMoveToInProgressStudentExercise),
    ]);
}

export default studentExercisesSaga;
