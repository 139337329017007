import React from 'react';
import { useSelector } from 'react-redux';
import { Modal, ModalBody, Progress } from 'reactstrap';
import { secondsToDurationFormatted } from '../../utils/date_time';

const AppModalProgress = () => {
    const modalProgress = useSelector(state => state.ui.modalProgress);

    return (
        <Modal isOpen={modalProgress.isOpen} backdrop='static' keyboard={false} centered={true}>
            <ModalBody>
                <p className="text-center">
                    {modalProgress.message ? modalProgress.message : 'Uploading ...'}
                </p>
                <Progress animated className="my-3" value={modalProgress.percentage} max={100} />
                <p className="text-center">
                    {modalProgress.estimatedSeconds ? secondsToDurationFormatted(modalProgress.estimatedSeconds)  : ''}
                </p>
            </ModalBody>
        </Modal>
    )
}

export default AppModalProgress;
