import { useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import {
    Button, Form, FormGroup, Input, Label, Modal, ModalBody, ModalHeader
} from 'reactstrap';

import { ErrorMessage, Field, Formik } from 'formik';
import * as Yup from 'yup';
import DragAndDrop from '../../components/ui/DragAndDrop';
import { createCubicleLog } from '../../redux/actions/cubicle_logs.actions';
import { CUBICLE_STATUS_SPECIAL_REQUEST } from '../../redux/reducers/my_cubicle.reducer';
import { checkFullScreen, userFullName } from '../../utils';

const SpecialRequestModal = ({ modalOpen, setModalClosed }) => {
    const dispatch = useDispatch();
    const isFullScreen = checkFullScreen();
    const facultyMembers = useSelector(state => state.facultiesLoggedIn.map(user => { return { name: userFullName(user), value: user.username } }));
    const formDialogSubmitSuccess = useSelector(state => state.ui.formDialogSubmitSuccess);
    const [uploading, setUploading] = useState(false);

    const startUploadAndSubmit = (e) => {
        e.preventDefault();
        setUploading(true);
    }

    const handleSubmit = (values) => {
        const cubicleLogData = {
            cubicle_status: CUBICLE_STATUS_SPECIAL_REQUEST,
            faculty_requested: values.faculty_requested,
            student_comments: values.student_comments,
            attachments: values.attachments,
        };
        dispatch(createCubicleLog(cubicleLogData));
    }

    if (formDialogSubmitSuccess) {
        setModalClosed();
    }

    return (
        <Modal size="lg" isOpen={modalOpen} fullscreen={isFullScreen}>
            <ModalHeader toggle={setModalClosed} className="m-2" />
            <ModalBody className='w-75 m-auto'>
                <div className='text-center'>
                    <h3>Special request</h3>
                    <p className='my-4'>Select the faculty member which you require a special request. Only faculty members who are online are available</p>
                </div>
                <Formik
                    initialValues={{
                        faculty_requested: '',
                        student_comments: '',
                        attachments: [],
                    }}
                    validationSchema={Yup.object({
                        faculty_requested: Yup.string()
                            .required('Please select a faculty member'),
                    })}
                    onSubmit={(values) => {
                        handleSubmit(values);
                    }}
                >
                    {({ submitForm, setFieldValue }) => (
                        <Form className="justify-content-center">
                            <FormGroup>
                                <Field as="select"
                                    placeholder="Role"
                                    className="form-select"
                                    name="faculty_requested"
                                    id="faculty_requested"
                                >
                                    <option value="">Select a faculty member</option>
                                    {facultyMembers.map((f) =>
                                        <option value={f.value}
                                            key={f.value}>{f.name}</option>)
                                    }
                                </Field>
                                <ErrorMessage component="div" className="text-danger" name='faculty_requested' />
                            </FormGroup>

                            <div className='border border-1 my-4'></div>

                            <p>Upload any images, video or documents to support your procedure review</p>

                            <Field name="attachments" as={DragAndDrop} {...{ submitForm, uploading, setFieldValue, setUploading }} />

                            <FormGroup className='mt-4'>
                                <Label>Add any comments</Label>
                                <Field type='textarea' name='student_comments' rows={5} as={Input} />
                            </FormGroup>

                            <Button color='success' type='button' onClick={startUploadAndSubmit} block className='mb-5'>Submit special request</Button>
                        </Form>
                    )}
                </Formik>

            </ModalBody>
        </Modal>
    );
};

export default SpecialRequestModal;