import axios from 'axios';
import { buildPaginationUrlParams } from '../utils/index';
import { API_CUBICLES, API_CUBICLES_MY_QUEUE, API_CUBICLES_UNASSIGNED } from './API_CONSTANTS';

export const getCubicles = (paginationParams) => {
    paginationParams = paginationParams ? paginationParams : {
        page: 1,
        limit: 999,
    };

    const url = API_CUBICLES + buildPaginationUrlParams(paginationParams);

    return new Promise((resolve, reject) => {
        try {
            axios.get(url)
                .then((res) => {
                    console.log('Cubicles loaded: ', res);
                    resolve(res.data);
                })
                .catch((err) => {
                    console.log('Get cubicles error: ', err);
                    reject('Get cubicles error');
                });
        } catch (error) {
            console.error('Get cubicles error: ', error);
            reject('Get cubicles error');
        }
    });
};

export const getOneCubicle = (cubicleId) => {
    const url = API_CUBICLES + '/' + cubicleId;

    return new Promise((resolve, reject) => {
        try {
            axios.get(url)
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    console.log('Get one cubicle error: ', err);
                    reject('Get one cubicle error');
                });
        } catch (error) {
            console.error('Get one cubicle error: ', error);
            reject('Get one cubicle error');
        }
    });
};

export const getUnassignedCubicles = () => {
    const url = API_CUBICLES_UNASSIGNED;

    return new Promise((resolve, reject) => {
        try {
            axios.get(url)
                .then((res) => {
                    console.log('Unassigned cubicles loaded: ', res);
                    resolve(res.data);
                })
                .catch((err) => {
                    console.log('Get unassigned cubicles error: ', err);
                    reject('Get unassigned cubicles error');
                });
        } catch (error) {
            console.error('Get unassigned cubicles error: ', error);
            reject('Get unassigned cubicles error');
        }
    });
};

export const createCubicle = (cubicle) => {
    const url = API_CUBICLES;

    return new Promise((resolve, reject) => {
        try {
            axios.post(url, cubicle)
                .then((res) => {
                    console.log('Cubicle created: ', res);
                    resolve(res.data);
                })
                .catch((err) => {
                    console.log('Create cubicle error: ', err);
                    reject('Create cubicle error');
                });
        } catch (error) {
            console.error('Create cubicle error: ', error);
            reject('Create cubicle error');
        }
    });
}

export const updateCubicle = (cubicle) => {
    const url = API_CUBICLES + '/' + cubicle.id;

    return new Promise((resolve, reject) => {
        try {
            axios.put(url, cubicle)
                .then((res) => {
                    console.log('Cubicle updated: ', res);
                    resolve(res.data);
                })
                .catch((err) => {
                    console.log('Update cubicle error: ', err);
                    reject('Update cubicle error');
                });
        } catch (error) {
            console.error('Update cubicle error: ', error);
            reject('Update cubicle error');
        }
    });
}

export const removeCubicle = (cubicleId) => {
    const url = API_CUBICLES + '/' + cubicleId;

    return new Promise((resolve, reject) => {
        try {
            axios.delete(url)
                .then((res) => {
                    console.log('Cubicle removed: ', res);
                    resolve(res);
                })
                .catch((err) => {
                    console.log('Remove cubicle error: ', err);
                    reject('Remove cubicle error');
                });
        } catch (error) {
            console.error('Remove cubicle error: ', error);
            reject('Remove cubicle error');
        }
    });
}

export const getCubicleQueue = () => {
    const url = API_CUBICLES_MY_QUEUE;

    return new Promise((resolve, reject) => {
        try {
            axios.get(url)
                .then((res) => {
                    console.log('Cubicle queue loaded: ', res);
                    resolve(res.data);
                })
                .catch((err) => {
                    console.log('Get cubicle queue error: ', err);
                    reject('Get cubicle queue error');
                });
        } catch (error) {
            console.error('Get cubicle queue error: ', error);
            reject('Get cubicle queue error');
        }
    });
}
