import { buildProcedureLabel, userFullName } from "../../utils";
import { GET_BASIC_FACULTY_REPORT_SUCCESS, GET_BASIC_STUDENT_REPORT_SUCCESS, GET_CUBICLE_LOG_SUCCESS, GET_ON_GOING_QUEUE_SUCCESS } from "../actions/cubicle_logs.actions";
import { CUBICLE_STATUS_UNOCCUPIED, REQUEST_STATUS_CANCELLED, REQUEST_STATUS_COMPLETED, REQUEST_STATUS_UNDER_WAY } from "./my_cubicle.reducer";

// TODO - Left here in case we need to build a grouped by date report
// eslint-disable-next-line no-unused-vars
const buildBriefFacultyReport = (cubicleLogs) => {
    let briefReportRows = [];
    cubicleLogs.forEach(cubicleLog => {
        // Skip cancelled request logs
        if (cubicleLog.requestlog_set.some(requestLog => requestLog.request_status === REQUEST_STATUS_CANCELLED)) {
            return;
        }
        // Skip records without accepted_by_faculty
        if (!cubicleLog.accepted_by_faculty) {
            return;
        }

        let createdDate = new Date(cubicleLog.created_at).toLocaleDateString();

        // Calculate duration of Underway in hours
        let underWayDurationSeconds = 0;
        let requestLogUnderway = cubicleLog.requestlog_set.find(requestLog => requestLog.request_status === REQUEST_STATUS_UNDER_WAY);
        if (requestLogUnderway) {
            underWayDurationSeconds = requestLogUnderway.duration_seconds;
        }

        let index = briefReportRows.findIndex(
            report => report.createdDate === createdDate && report.acceptedByFacultyUsername === cubicleLog.accepted_by_faculty.username
        );
        if (index < 0) {
            briefReportRows.push({
                createdDate,
                acceptedByFacultyUsername: cubicleLog.accepted_by_faculty.username,
                acceptedByFacultyFullName: userFullName(cubicleLog.accepted_by_faculty),
                visitedCubicleNames: new Set([cubicleLog.cubicle.name]),
                totalHours: underWayDurationSeconds / 60 / 60,
                totalMinutes: underWayDurationSeconds / 60,
                requestCount: 1,
            });
        } else {
            briefReportRows[index].visitedCubicleNames.add(cubicleLog.cubicle.name);
            briefReportRows[index].totalHours += (underWayDurationSeconds / 60 / 60);
            briefReportRows[index].totalMinutes += (underWayDurationSeconds / 60);
            briefReportRows[index].requestCount += 1;
        }
    });

    briefReportRows.forEach(report => {
        // Replace Set by Array on visitedCubicleNames and
        // Join the names of visited cubicles with comma
        report.visitedCubicleNames = Array.from(report.visitedCubicleNames).join(', ');
        // Round total hours to 2 decimal places
        report.totalHours = (Math.round(report.totalHours * 10) / 10).toFixed(1);
        report.totalMinutes = Math.round(report.totalMinutes);
    });

    return briefReportRows;
}

const buildDetailedFacultyReport = (cubicleLogs) => {
    let detailedReportRows = [];
    cubicleLogs.forEach(cubicleLog => {
        // Skip cancelled request logs
        if (cubicleLog.requestlog_set.some(requestLog => requestLog.request_status === REQUEST_STATUS_CANCELLED)) {
            return;
        }
        // Skip records without accepted_by_faculty
        if (!cubicleLog.accepted_by_faculty) {
            return;
        }

        // Calculate duration of Underway in minutes
        let underWayDurationSeconds = 0;
        let requestLogUnderway = cubicleLog.requestlog_set.find(requestLog => requestLog.request_status === REQUEST_STATUS_UNDER_WAY);
        if (requestLogUnderway) {
            underWayDurationSeconds = requestLogUnderway.duration_seconds;
        }

        detailedReportRows.push({
            createdAt: cubicleLog.created_at,
            acceptedByFacultyUsername: cubicleLog.accepted_by_faculty.username,
            acceptedByFacultyFullName: userFullName(cubicleLog.accepted_by_faculty),
            visitedCubicleName: cubicleLog.cubicle.name,
            cubicleStatus: cubicleLog.cubicle_status,
            totalSeconds: underWayDurationSeconds,
        });
    });

    return detailedReportRows;
}

// TODO - Left here in case we need to build a grouped by date report
// eslint-disable-next-line no-unused-vars
const buildBriefStudentReport = (cubicleLogs) => {
    let briefReportRows = [];
    cubicleLogs.forEach(cubicleLog => {
        // Skip cubicle logs = Unoccupied
        if (cubicleLog.cubicle_status === CUBICLE_STATUS_UNOCCUPIED) {
            return;
        }

        let createdDate = new Date(cubicleLog.created_at).toLocaleDateString();
        let proceduresCompleted = [];
        if (cubicleLog.procedures && cubicleLog.requestlog_set.some(requestLog => requestLog.request_status === REQUEST_STATUS_COMPLETED)) {
            proceduresCompleted = cubicleLog.procedures.map(procedure => buildProcedureLabel(procedure, cubicleLog.other_procedure));
        }

        let index = briefReportRows.findIndex(
            report => report.createdDate === createdDate && report.studentUsername === cubicleLog.student.username
        );
        let cubicleLogDurationMinutes = cubicleLog.duration_seconds / 60;

        if (index < 0) {
            briefReportRows.push({
                createdDate,
                studentUsername: cubicleLog.student.username,
                studentFullName: userFullName(cubicleLog.student),
                proceduresCompleted: new Set(proceduresCompleted),
                totalHours: cubicleLogDurationMinutes / 60,
                totalMinutes: cubicleLogDurationMinutes,
                cubicleLogCount: 1,
            });
        } else {
            proceduresCompleted.forEach(procedureCompleted => {
                briefReportRows[index].proceduresCompleted.add(procedureCompleted);
            });
            briefReportRows[index].totalHours += (cubicleLogDurationMinutes / 60);
            briefReportRows[index].totalMinutes += (cubicleLogDurationMinutes);
            briefReportRows[index].cubicleLogCount += 1;
        }
    });

    briefReportRows.forEach(report => {
        // Replace Set by Array on proceduresCompleted
        report.proceduresCompleted = Array.from(report.proceduresCompleted);
        // Round total hours to 2 decimal places
        report.totalHours = (Math.round(report.totalHours * 10) / 10).toFixed(1);
        report.totalMinutes = Math.round(report.totalMinutes);
    });

    return briefReportRows;
}

const buildDetailedStudentReport = (cubicleLogs) => {
    let detailedReportRows = [];
    cubicleLogs.forEach(cubicleLog => {
        // Skip cubicle logs = Unoccupied
        if (cubicleLog.cubicle_status === CUBICLE_STATUS_UNOCCUPIED) {
            return;
        }

        let proceduresCompleted = [];
        if (cubicleLog.procedures && cubicleLog.requestlog_set.some(requestLog => requestLog.request_status === REQUEST_STATUS_COMPLETED)) {
            proceduresCompleted = cubicleLog.procedures.map(procedure => buildProcedureLabel(procedure, cubicleLog.other_procedure));
        }

        detailedReportRows.push({
            createdAt: cubicleLog.created_at,
            studentUsername: cubicleLog.student.username,
            studentFullName: userFullName(cubicleLog.student),
            acceptedByFacultyFullName: cubicleLog.accepted_by_faculty ? userFullName(cubicleLog.accepted_by_faculty) : null,
            proceduresCompleted: proceduresCompleted,
            cubicleName: cubicleLog.cubicle.name,
            cubicleStatus: cubicleLog.cubicle_status,
            totalSeconds: cubicleLog.duration_seconds,
        });
    });

    return detailedReportRows;
}

const buildQueueForFacultyVisit = (onGoingQueue) => {
    let queueForFacultyVisit = {};
    for (let onGoingCubicleLog of onGoingQueue) {
        // Only request logs with status = Underway and Accepted by Faculty
        if (onGoingCubicleLog.request_status !== REQUEST_STATUS_UNDER_WAY || !onGoingCubicleLog.accepted_by_faculty) {
            continue;
        }

        let facultyUsername = onGoingCubicleLog.accepted_by_faculty.username;
        if (!queueForFacultyVisit[facultyUsername]) {
            queueForFacultyVisit[facultyUsername] = [];
        }
        queueForFacultyVisit[facultyUsername].push(onGoingCubicleLog);
    }
    // Sort each Faculty queue by last_request_log.created_at
    for (let facultyUsername in queueForFacultyVisit) {
        queueForFacultyVisit[facultyUsername].sort((a, b) => {
            return a.last_request_log.created_at - b.last_request_log.created_at;
        });
    }

    return queueForFacultyVisit;
}

const initialCubicleLogsState = {
    detailedFacultyReport: {
        reportRows: [],
        next: null,
        previous: null,
        count: 0,
    },
    detailedStudentReport: {
        reportRows: [],
        next: null,
        previous: null,
        count: 0,
    },
    onGoingQueue: [],
    queueForFacultyVisit: [],
    selectedCubicleLog: null,
};

export const CubicleLogsReducer = (state = initialCubicleLogsState, action) => {
    switch (action.type) {
        case GET_BASIC_FACULTY_REPORT_SUCCESS:
            return {
                ...state,
                detailedFacultyReport: {
                    reportRows: buildDetailedFacultyReport(action.payload.results),
                    next: action.payload.next,
                    previous: action.payload.previous,
                    count: action.payload.count,
                }
            };
        case GET_BASIC_STUDENT_REPORT_SUCCESS:
            return {
                ...state,
                detailedStudentReport: {
                    reportRows: buildDetailedStudentReport(action.payload.results),
                    next: action.payload.next,
                    previous: action.payload.previous,
                    count: action.payload.count,
                }
            };
        case GET_ON_GOING_QUEUE_SUCCESS:
            return {
                ...state,
                onGoingQueue: action.payload,
                queueForFacultyVisit: buildQueueForFacultyVisit(action.payload),
            };
        case GET_CUBICLE_LOG_SUCCESS:
            return {
                ...state,
                selectedCubicleLog: action.payload,
            };
        default:
            return state;
    }
}
