import { useEffect, useState } from 'react';
import { Container, Row, Col, Button, FormGroup, Input } from 'reactstrap';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Select from "react-select";
import DatePicker from 'react-datepicker';
import * as Yup from 'yup';
import AppBreadcrumb from '../../../components/ui/AppBreadcrumb';
import { CLIENT_ADMIN_USERS, STUDENT_EXERCISES } from '../../../navigation/ROUTE_CONSTANTS';
import { disableOverlay, enableOverlay } from '../../../redux/actions/ui.actions';
import { getOneStudentExercise } from '../../../services/studentExercisesService';
import { yupParseDateString } from '../../../utils';
import { createStudentExercise, updateStudentExercise } from '../../../redux/actions/student_exercises.actions';
import { STUDENT_EXERCISE_STATUS_PLANNED, STUDENT_EXERCISE_STATUS_UI_NAMES } from '../../../redux/reducers/student_exercises.reducers';
import { createFromParams, dateTimeToAPIDate, parseToDateTime } from '../../../utils/date_time';
import { getListExercises } from '../../../redux/actions/exercises.actions';

const convertFromFormBaseDt = (formBaseDt) => {
    return createFromParams({
        year: formBaseDt.getFullYear(),
        month: formBaseDt.getMonth() + 1,
        day: formBaseDt.getDate(),
        hour: formBaseDt.getHours(),
        minute: formBaseDt.getMinutes(),
    });
}

const convertToFormBaseDt = (dateTime) => {
    return new Date(dateTime.year, dateTime.month - 1, dateTime.day, dateTime.hour, dateTime.minute);;
}

function StudentExerciseForm() {

    const dispatch = useDispatch();
    const { studentExerciseId, studentUsername } = useParams();
    const [initialFormValues, setInitialFormValues] = useState({
        student_username: studentUsername,
        exercise_id: '',
        due_date: null,
        procedure_grade: null,
        status: STUDENT_EXERCISE_STATUS_PLANNED,
    });
    const [pageTitle, setPageTitle] = useState('');
    const [exerciseOptions, setExerciseOptions] = useState([]);

    const exercisesMap = useSelector((state) => state.exercises.exercisesMap);

    useEffect(() => {
        if (studentExerciseId) {
            setPageTitle('Edit Student Exercise');

            dispatch(enableOverlay('Retrieving student exercise ...'));
            getOneStudentExercise({ studentUsername, studentExerciseId }).then((data) => {
                dispatch(disableOverlay());

                let dueDateDt = parseToDateTime(data.due_date);
                // DatePicker works only with JS Date in browser default timezone
                // But we need to work with the Client (tenant/university) timezone
                // So we create JS Date on browser default timezone and set the date and time Client timezone
                let dueDateDtJSDateLocalTimezone = convertToFormBaseDt(dueDateDt);

                setInitialFormValues({
                    student_username: data.student_username,
                    exercise_id: data.exercise_id,
                    due_date: dueDateDtJSDateLocalTimezone,
                    procedure_grade: data.procedure_grade,
                    status: data.status,
                });
            })
        } else {
            setPageTitle('Create Student Exercise');
        }
    }, [studentUsername, studentExerciseId, dispatch]);

    useEffect(() => {
        document.title = process.env.REACT_APP_SITE_TITLE + ' - ' + pageTitle;
    }, [pageTitle]);

    useEffect(() => {
        let exerciseOptions = Object.keys(exercisesMap).map((key) => {
            return {
                value: key,
                label: exercisesMap[key]
            }
        }).sort();

        if (exerciseOptions.length === 0) {
            dispatch(getListExercises());
        }

        exerciseOptions.unshift({
            value: 0,
            label: '-- Pick an exercise --'
        });

        setExerciseOptions(exerciseOptions);
    }, [exercisesMap, dispatch]);


    return (
        <Container className='mb-5 pb-5'>
            <Row>
                <Col>
                    <AppBreadcrumb headText={pageTitle} crumbList={[
                        { linkTo: CLIENT_ADMIN_USERS, label: 'Users' },
                        { linkTo: STUDENT_EXERCISES.replace(':studentUsername', initialFormValues.student_username), label: initialFormValues.student_username }
                    ]} />
                </Col>
            </Row>

            <Row>
                <Col md={10} className='mx-auto'>
                    <h4 className='pt-2 pb-3 text-center'>{pageTitle}</h4>
                    <Formik
                        enableReinitialize={true}
                        initialValues={initialFormValues}
                        validationSchema={Yup.object({
                            exercise_id: Yup.number()
                                .min(1, 'Please select an exercise')
                                .required('Please select an exercise'),
                            due_date: Yup.date().transform(yupParseDateString)
                                .typeError("Please enter a valid due date. Example: MM/DD/YYYY")
                                .required("Please enter a valid due date. Example: MM/DD/YYYY"),
                            procedure_grade: Yup.string()
                                .min(1, 'Grade must be at least 1 characters length.')
                                .max(2, 'Grade must be smaller than 2 characters length.')
                                .nullable(),
                            status: Yup.string()
                                .required('Please select the status'),
                        })}
                        onSubmit={(values) => {
                            const toSubmitDueDt = convertFromFormBaseDt(values.due_date);
                            let formData = {
                                student_username: studentUsername,
                                exercise_id: values.exercise_id,
                                due_date: dateTimeToAPIDate(toSubmitDueDt),
                                procedure_grade: values.procedure_grade,
                                status: values.status,
                            }

                            if (studentExerciseId) {
                                formData.id = studentExerciseId;
                                dispatch(updateStudentExercise(formData));
                            } else {
                                dispatch(createStudentExercise(formData));
                            }
                        }}
                    >{({ values, setFieldValue }) => (
                        <Form>
                            <Row>
                                <Col md={6}>
                                    <div className="background-light-grey p-3 border-bottom-grey mb-3 h5">
                                        Basics
                                    </div>
                                    <FormGroup>
                                        <Field id="exercise_id" name="exercise_id" as={Select}
                                            placeholder="Exercise"
                                            value={values.exercise_id ? { value: values.exercise_id, label: exercisesMap[values.exercise_id] } : ''}
                                            onChange={option => setFieldValue('exercise_id', option.value)}
                                            options={exerciseOptions}
                                            isSearchable={false}
                                        />
                                        <ErrorMessage component="div" className="text-danger" name="exercise_id" />
                                    </FormGroup>

                                    <FormGroup>
                                        <Field type="text" name="procedure_grade" placeholder="Grade" as={Input} />
                                        <ErrorMessage component="div" className="text-danger" name="procedure_grade" />
                                    </FormGroup>

                                    <FormGroup>
                                        <Field id="status" name="status" as="select"
                                            placeholder="Status"
                                            className="form-select"
                                        >
                                            <option value="">Select Status</option>
                                            {Object.entries(STUDENT_EXERCISE_STATUS_UI_NAMES).map((status) =>
                                                <option value={status[0]}
                                                    key={status[0]}>{status[1]}</option>)
                                            }
                                        </Field>
                                        <ErrorMessage component="div" className="text-danger" name="status" />
                                    </FormGroup>
                                </Col>

                                <Col md={6}>
                                    <div className="background-light-grey p-3 border-bottom-grey mb-3 h5">
                                        Exercise Due Date
                                    </div>
                                    <FormGroup className='text-center'>
                                        <DatePicker
                                            selected={values.due_date}
                                            onChange={(dateTime) => setFieldValue('due_date', dateTime)}
                                            inline
                                            fixedHeight
                                        />
                                        <ErrorMessage component="div" className="text-danger" name="due_date" />
                                    </FormGroup>

                                </Col>
                            </Row>
                            <div className='text-center'>
                                <Button color='success' className='mt-2 px-5' type='submit'>
                                    <span className='d-inline-block fs-5 px-3'>{pageTitle}</span>
                                </Button>
                            </div>
                        </Form>
                    )}
                    </Formik>
                </Col>
            </Row >

        </Container>
    )
}

export default StudentExerciseForm;