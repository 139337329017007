import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    AccordionBody,
    AccordionHeader,
    AccordionItem, Button, Col, Container, Row, UncontrolledAccordion
} from 'reactstrap';
import ChatUI from '../../components/general/ChatUI';
import ShouldUpdateStudentExercises from '../../components/student/ShouldUpdateStudentExercises';
import StudentCubicleQueue from '../../components/student/StudentCubicleQueue';
import { createCubicleLog, getOnGoingQueue, setOccupiedCubicleLog } from '../../redux/actions/cubicle_logs.actions';
import { getStudentExercisesDashboard, moveToInProgressStudentExercise } from '../../redux/actions/student_exercises.actions';
import { setFormDialogSubmitSuccess } from '../../redux/actions/ui.actions';
import { CUBICLE_STATUS_CHECKOUT, CUBICLE_STATUS_HELP, CUBICLE_STATUS_SPECIAL_REQUEST, CUBICLE_STATUS_START_CHECK, CUBICLE_STATUS_UNOCCUPIED } from '../../redux/reducers/my_cubicle.reducer';
import { STUDENT_EXERCISE_STATUS_COMPLETED, STUDENT_EXERCISE_STATUS_IN_PROGRESS, STUDENT_EXERCISE_STATUS_PLANNED } from '../../redux/reducers/student_exercises.reducers';
import { buildExerciseLabel } from '../../utils';
import { FORMAT_DATE_MED, isoStringToFormatted } from '../../utils/date_time';
import SpecialRequestModal from './SpecialRequestModal';
import TreatmentCompleteModal from './TreatmentCompleteModal';

const StudentDashboard = () => {
    const dispatch = useDispatch();
    const studentExercisesDashboard = useSelector(state => state.studentExercises.studentExercisesDashboard);
    const onGoingQueue = useSelector(state => state.cubicleLogs.onGoingQueue);
    const myCubicleData = useSelector(state => state.myCubicle);

    const [treatmentCompleteModalOpen, setTreatmentCompleteModalOpen] = useState(false);
    const [specialRequestModalOpen, setSpecialRequestModalOpen] = useState(false);
    const [mySpecialRequests, setMySpecialRequests] = useState([]);
    const [selectedStudentExerciseId, setSelectedStudentExerciseId] = useState(null);

    useEffect(() => {
        if (myCubicleData.id) {
            dispatch(getStudentExercisesDashboard());
            dispatch(getOnGoingQueue());
        }
    }, [dispatch, myCubicleData.id]);

    useEffect(() => {
        if (myCubicleData.cubicle_status === CUBICLE_STATUS_UNOCCUPIED) {
            dispatch(setOccupiedCubicleLog(myCubicleData.id));
        }
    }, [dispatch, myCubicleData.cubicle_status, myCubicleData.id]);

    useEffect(() => {
        setMySpecialRequests(onGoingQueue.filter((onGoingCubicleLog) => (
            onGoingCubicleLog.cubicle_status === CUBICLE_STATUS_SPECIAL_REQUEST &&
            onGoingCubicleLog.cubicle.id === myCubicleData.id
        )));
    }, [myCubicleData.id, onGoingQueue]);

    const ExerciseButton = (props) => {
        return (
            <Button className='ms-2 fs-6' size="sm" {...props} />
        );
    };

    const handleRequest = (e, requestStatus, studentExerciseId) => {
        e.preventDefault();
        const cubicleLogData = {
            cubicle_status: requestStatus,
            student_exercise_id: studentExerciseId,
        };
        dispatch(createCubicleLog(cubicleLogData));
    };

    const handleMoveTodo = (e, studentExerciseId) => {
        e.preventDefault();
        dispatch(moveToInProgressStudentExercise(studentExerciseId));
    };

    const sectionName = (name, seeAll) => {
        return (
            <div className='my-1 w-100'>
                <span className='fw-semibold fs-5'>{name}</span>
                {seeAll && (
                    <span className='float-end text-green mx-3'>
                        {'See all >'}
                    </span>
                )}
            </div>
        );
    };

    const isStudentExerciseRequestIntoOnGoingQueue = (studentExerciseId, cubicleStatus) =>
        onGoingQueue.some((cubicleLog) =>
            cubicleLog.cubicle_status === cubicleStatus && cubicleLog.student_exercise === studentExerciseId
        );

    const returnInProgress = () => {
        const sectionStudentExercises = studentExercisesDashboard.filter((se) => se.status === STUDENT_EXERCISE_STATUS_IN_PROGRESS);
        return (
            <AccordionItem key='in-progress'>
                <AccordionHeader targetId="accordion-in-progress">
                    {sectionName('To do', false)}
                </AccordionHeader>
                <AccordionBody accordionId='accordion-in-progress' className='px-0'>

                    {/* Special Requests are not linked to an Exercise */}
                    {mySpecialRequests.length > 0 && (
                        <div className='pt-4 px-4 border-top-grey' key='ongoing-special-request'>
                            {mySpecialRequests.map((onGoingCubicleLog) =>
                                <StudentCubicleQueue onGoingCubicleLog={onGoingCubicleLog} key={'special-request-' + onGoingCubicleLog.id} />
                            )}
                        </div>
                    )}

                    {/* Requests linked to Exercises */}
                    {sectionStudentExercises.map((se) => (
                        <div className='pt-4 ps-4 border-top-grey' key={se.id}>
                            <ShouldUpdateStudentExercises studentExerciseId={se.id} />
                            <Row>
                                <Col md={4}>
                                    <p className='fs-5 fw-bold text-green mb-1'>
                                        {buildExerciseLabel(se.exercise)}
                                    </p>
                                    <p className='mb-1'>
                                        Started on {se.started_at ? isoStringToFormatted(se.started_at, FORMAT_DATE_MED) : '---'}
                                    </p>
                                </Col>
                                <Col md={8} className='text-end'>
                                    <ExerciseButton
                                        color="success"
                                        onClick={(e) => handleRequest(e, CUBICLE_STATUS_START_CHECK, se.id)}
                                        disabled={isStudentExerciseRequestIntoOnGoingQueue(se.id, CUBICLE_STATUS_START_CHECK)}
                                    >
                                        Start check
                                    </ExerciseButton>
                                    <ExerciseButton
                                        color="primary"
                                        onClick={() => {
                                            dispatch(setFormDialogSubmitSuccess(false));
                                            setTreatmentCompleteModalOpen(true);
                                            setSelectedStudentExerciseId(se.id);
                                        }}
                                        disabled={isStudentExerciseRequestIntoOnGoingQueue(se.id, CUBICLE_STATUS_CHECKOUT)}
                                    >
                                        Treatment complete
                                    </ExerciseButton>
                                    <ExerciseButton
                                        color="warning-orange" outline
                                        onClick={(e) => handleRequest(e, CUBICLE_STATUS_HELP, se.id)}
                                        disabled={isStudentExerciseRequestIntoOnGoingQueue(se.id, CUBICLE_STATUS_HELP)}
                                    >
                                        Help
                                    </ExerciseButton>
                                </Col>
                            </Row>
                            {onGoingQueue.filter((onGoingCubicleLog) => se.id === onGoingCubicleLog.student_exercise).map((onGoingCubicleLog) =>
                                <StudentCubicleQueue key={onGoingCubicleLog.id} onGoingCubicleLog={onGoingCubicleLog} />
                            )}
                        </div>
                    )
                    )}
                </AccordionBody>
            </AccordionItem>
        );
    };

    const returnNextProcedures = () => {
        const sectionStudentExercises = studentExercisesDashboard.filter((se) => se.status === STUDENT_EXERCISE_STATUS_PLANNED);
        return (
            <AccordionItem key='next-procedures'>
                <AccordionHeader targetId="accordion-next-procedures">
                    {sectionName('Next exercises', true)}
                </AccordionHeader>
                <AccordionBody accordionId='accordion-next-procedures'>
                    {sectionStudentExercises.map((se, index) => (
                        <div className='my-3 ps-4 border-bottom-grey' key={se.id}>
                            <Row>
                                <Col>
                                    <p className='fs-5 fw-bold text-green mb-1'>
                                        {buildExerciseLabel(se.exercise)}
                                    </p>
                                    <p>Due {isoStringToFormatted(se.due_date, FORMAT_DATE_MED)}</p>
                                </Col>
                                <Col className='text-end'>
                                    <ExerciseButton color="success"
                                        onClick={(e) => handleMoveTodo(e, se.id)}
                                        disabled={index!==0}
                                    >
                                        Move to To Do
                                    </ExerciseButton>
                                </Col>
                            </Row>
                        </div>
                    )
                    )}
                </AccordionBody>
            </AccordionItem>
        );
    };

    const completedProcedures = () => {
        const sectionStudentExercises = studentExercisesDashboard.filter((se) => se.status === STUDENT_EXERCISE_STATUS_COMPLETED);
        return (
            <AccordionItem key='completed-procedures'>
                <AccordionHeader targetId="accordion-completed-procedures">
                    {sectionName('Completed exercises', true)}
                </AccordionHeader>
                <AccordionBody accordionId='accordion-completed-procedures'>
                    {sectionStudentExercises.map((se) => (
                        <div className='my-3 ps-4 border-bottom-grey' key={se.id}>
                            <Row>
                                <Col className='fs-5 fw-bold text-green'>
                                    {buildExerciseLabel(se.exercise)}
                                </Col>
                            </Row>
                            <Row>
                                <Col><p>
                                    Completed on {se.completed_on ? isoStringToFormatted(se.completed_on, FORMAT_DATE_MED) : '---'}
                                    <span className='text-muted mx-3'>|</span>
                                    {se.procedure_grade ? `Grade: ${se.procedure_grade}` : 'Not reviewed'}
                                </p></Col>
                            </Row>
                        </div>
                    )
                    )}
                </AccordionBody>
            </AccordionItem>
        );
    };

    const handleSpecialRequestButton = () => {
        dispatch(setFormDialogSubmitSuccess(false));
        setSpecialRequestModalOpen(true);
    }

    return (
        <Container className="min-vh-72">
            <Row className='mt-4'>
                <Col xs={12} lg={8}>
                    <UncontrolledAccordion defaultOpen={['accordion-in-progress', 'accordion-next-procedures']} stayOpen className='mb-5 student-dashboard-accordion'>
                        {returnInProgress()}
                        {returnNextProcedures()}
                        {completedProcedures()}
                    </UncontrolledAccordion>
                </Col>
                <Col xs={12} lg={4}>
                    <Button className='w-100 mb-3 fs-6'
                        color="success"
                        outline
                        size="sm"
                        onClick={handleSpecialRequestButton}
                    >
                        Special request
                    </Button>
                    <ChatUI location='StudentDashboard' />
                </Col>
            </Row>
            <TreatmentCompleteModal
                selectedStudentExerciseId={selectedStudentExerciseId}
                modalOpen={treatmentCompleteModalOpen}
                setModalClosed={(e) => { setTreatmentCompleteModalOpen(false) }}
            />
            <SpecialRequestModal
                modalOpen={specialRequestModalOpen}
                setModalClosed={(e) => { setSpecialRequestModalOpen(false) }}
            />
        </Container>
    );
};

export default StudentDashboard;
