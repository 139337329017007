import { GET_LIST_EXERCISES_SUCCESS, GET_EXERCISE_SUCCESS, CLEAR_SELECTED_EXERCISE } from "../actions/exercises.actions"

const initialExercisesState = {
    exercisesList: null,
    selectedExercise: {},
    exercisesMap: {},
}

export const ExerciseReducer = (state = initialExercisesState, action) => {
    switch (action.type) {
        case GET_LIST_EXERCISES_SUCCESS:
            let exercisesMap = {};
            for (let exercise of action.payload.results) {
                exercisesMap[exercise.id] = exercise.short_name ? `${exercise.name}  (${exercise.short_name})` : exercise.name;
            }

            return {
                ...state,
                exercisesList: action.payload.results,
                exercisesMap,
            }
        case GET_EXERCISE_SUCCESS:
            return {
                ...state,
                selectedExercise: action.payload
            }
        case CLEAR_SELECTED_EXERCISE:
            return {
                ...state,
                selectedExercise: {}
            }
        default:
            return state;
    }
}