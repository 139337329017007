import Cubicle, { CUBICLE_VARIANT_HEADER } from '../components/Cubicle';
import { Container, Row, Col } from 'reactstrap';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { HEADER_COLOR_SUCCESS, HEADER_COLOR_WHITE } from '../../redux/reducers/ui.reducer';
import { CUBICLE_STATUS_CHECKOUT, CUBICLE_STATUS_HELP, CUBICLE_STATUS_OCCUPIED, CUBICLE_STATUS_SPECIAL_REQUEST, CUBICLE_STATUS_START_CHECK, CUBICLE_STATUS_UNOCCUPIED } from '../../redux/reducers/my_cubicle.reducer';
import ButtonCancelRequest from '../../components/student/ButtonCancelRequest';

const getLeftSection = (myCubicle) => {
    let titleColor = HEADER_COLOR_WHITE;

    switch (myCubicle.cubicle_status) {
        case CUBICLE_STATUS_UNOCCUPIED:
        case CUBICLE_STATUS_OCCUPIED:
            titleColor = HEADER_COLOR_SUCCESS;
            break;
        case CUBICLE_STATUS_HELP:
            titleColor = HEADER_COLOR_SUCCESS;
            break;
        case CUBICLE_STATUS_SPECIAL_REQUEST:
            titleColor = HEADER_COLOR_SUCCESS;
            break;
        case CUBICLE_STATUS_START_CHECK:
            titleColor = HEADER_COLOR_WHITE;
            break;
        case CUBICLE_STATUS_CHECKOUT:
            titleColor = HEADER_COLOR_WHITE;
            break;
        default:
            break;
    }

    return (
        <div className='d-flex align-items-center h-100'>
            <div className={`me-3 text-end text-wrap text-${titleColor}`}>
                This is cubicle
            </div>
            <div className='w-100'>
                <Cubicle cubicleData={myCubicle} cubicleLogData={myCubicle.last_cubicle_log} variant={CUBICLE_VARIANT_HEADER} />
            </div>
        </div>
    );
};

const Header = () => {
    const header = useSelector(state => state.ui.header);
    const myCubicle = useSelector((state) => state.myCubicle);

    const [divClasses, setDivClasses] = useState('');

    useEffect(() => {
        let classes = [];
        if (header.color) {
            classes.push(`text-bg-${header.color}`);
        }

        if (header.subtitle?.text) {
            classes.push('pt-3 pb-3');
        } else {
            classes.push('pt-2 pb-3 pb-md-2');
        }
        setDivClasses(classes.join(' '));
    }, [header.color, header.subtitle]);

    return (
        <div className={divClasses + ' border-bottom border-2'}>
            <Container className={`${header.subtitle?.text ? 'mt-1 mb-1' : 'mt-2 mb-2 pt-1'} px-4 px-md-auto`}>
                <Row className='align-items-center'>
                    <Col md={2} className='d-none d-md-block'>
                        {myCubicle && myCubicle.name && getLeftSection(myCubicle)}
                    </Col>
                    <Col xs={12} md={8}>
                        {header.title?.text && <div className={`fs-2 lh-sm fw-semibold text-center text-${header.title.color}`}>{header.title.text}</div>}
                        {header.subtitle?.text && <div className={`fs-5 text-center text-${header.subtitle.color}`}>{header.subtitle.text}</div>}
                    </Col>
                    <Col md={2} className='d-none d-md-block'>
                        {header.rightSection &&
                            <div className='d-flex align-items-center justify-content-end h-100'>
                                {header.rightSection.buttonCancel && (
                                    <ButtonCancelRequest color={header.rightSection.buttonCancel.color} cubicleLogId={header.rightSection.buttonCancel.cubicleLogId}>
                                        Cancel request
                                    </ButtonCancelRequest>
                                )}
                            </div>
                        }
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default Header;