import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { CREATE_CLASS, DELETE_CLASS, GET_LIST_CLASSES, UPDATE_CLASS, getListClassesSuccess, GET_CLASS, getClassSuccess, updateClassStudents, UPDATE_CLASS_STUDENTS } from '../actions/classes.actions';
import { getClasses, getOneClass, createClass, updateClass, removeClass, addStudentToClass, updateStudentToClass, removeStudentFromClass } from '../../services/classesService';
import { genericError } from '../actions/error.actions';
import { disableOverlay, enableOverlay, setNavigateTo, setToast } from '../actions/ui.actions';
import { FACULTY_CLASSES } from '../../navigation/ROUTE_CONSTANTS';
import { TOAST_SEVERITY_SUCCESS } from '../reducers/ui.reducer';
import { STUDENT_CLASS_ACTION_REMOVE } from '../reducers/classes.reducers';

/**
 * getListClassesEffect
 */
function* getListClassesEffect() {
    yield put(enableOverlay('Loading classes ...'));
    try {
        const classesList = yield call(getClasses);
        if (classesList) {
            yield put(getListClassesSuccess(classesList));
        }
    } catch (err) {
        console.log('error:', err);
        yield put(genericError(err));
    }
    yield put(disableOverlay());
}

/**
 * getClassEffect
 */
function* getClassEffect({ payload }) {
    yield put(enableOverlay('Getting class ...'));
    try {
        const facultyClass = yield call(getOneClass, payload);
        if (facultyClass) {
            yield put(getClassSuccess(facultyClass));
        }
    } catch (err) {
        console.log('error:', err);
        yield put(genericError(err));
    }
    yield put(disableOverlay());
}

/**
 * createClassEffect
 */
function* createClassEffect({ payload }) {
    try {
        yield put(enableOverlay('Creating class ...'));
        let facultyClassStudents = payload.students;
        delete payload.students;
        const facultyClass = yield call(createClass, payload);
        if (facultyClass) {
            yield put(updateClassStudents({id: facultyClass.id, students: facultyClassStudents}));
            yield put(setToast({
                severity: TOAST_SEVERITY_SUCCESS,
                detail: 'The class has been created!',
            }));
            yield put(setNavigateTo(FACULTY_CLASSES));
        }
    } catch (err) {
        console.log('error:', err);
        yield put(genericError(err));
    }
    yield put(disableOverlay());
}

/**
 * updateClassEffect
 */
function* updateClassEffect({ payload }) {
    try {
        yield put(enableOverlay('Updating class ...'));
        let facultyClassStudents = payload.students;
        delete payload.students;
        const facultyClass = yield call(updateClass, payload);
        if (facultyClass) {
            yield put(updateClassStudents({id: facultyClass.id, students: facultyClassStudents}));
            yield put(setToast({
                severity: TOAST_SEVERITY_SUCCESS,
                detail: 'The class has been updated!',
            }));
            yield put(setNavigateTo(FACULTY_CLASSES));
        }
    } catch (err) {
        console.log('error:', err);
        yield put(genericError(err));
    }
    yield put(disableOverlay());
}

/**
 * updateClassStudentsEffect
 */
 function* updateClassStudentsEffect({ payload }) {
    try {
        let facultyClassId = payload.id;
        // Only process the students that have been changed/added
        let facultyClassStudents = payload.students.filter(student => 'action' in student);

        // One API call for each student
        yield all(facultyClassStudents.map((student) => {
            if (student.action === STUDENT_CLASS_ACTION_REMOVE) {
                return call(removeStudentFromClass, student.id);

            } else if (!student.id) {
                return call(addStudentToClass, {faculty_class_id: facultyClassId, class_grade: student.class_grade, student: student.student});

            } else {
                return call(updateStudentToClass, {id: student.id, class_grade: student.class_grade});
            }
        }));

    } catch (err) {
        console.log('error:', err);
        yield put(genericError(err));
    }
}

/**
 * deleteClassEffect
 */
function* deleteClassEffect({ payload }) {
    try {
        yield put(enableOverlay('Deleting class ...'));
        const class_ = yield call(removeClass, payload);
        if (class_) {
            yield put(setToast({
                severity: TOAST_SEVERITY_SUCCESS,
                detail: 'The class has been deleted!',
            }));
            yield put(setNavigateTo(FACULTY_CLASSES));
        }
    } catch (err) {
        console.log('error:', err);
        yield put(genericError(err));
    }
    yield put(disableOverlay());
}

export function* watchGetListClasses() {
    yield takeEvery(GET_LIST_CLASSES, getListClassesEffect);
}

export function* watchGetClass() {
    yield takeEvery(GET_CLASS, getClassEffect);
}


export function* watchCreateClass() {
    yield takeEvery(CREATE_CLASS, createClassEffect);
}

export function* watchUpdateClass() {
    yield takeEvery(UPDATE_CLASS, updateClassEffect);
}

export function* watchUpdateClassStudents() {
    yield takeEvery(UPDATE_CLASS_STUDENTS, updateClassStudentsEffect);
}

export function* watchDeleteClass() {
    yield takeEvery(DELETE_CLASS, deleteClassEffect);
}

function* facultyClassesSaga() {
    yield all([
        fork(watchGetListClasses),
        fork(watchGetClass),
        fork(watchCreateClass),
        fork(watchUpdateClass),
        fork(watchUpdateClassStudents),
        fork(watchDeleteClass)
    ]);
}

export default facultyClassesSaga;
