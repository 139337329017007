import { buildProcedureLabel } from "../../utils";
import { CLEAR_SELECTED_PROCEDURE, GET_ADMIN_LIST_PROCEDURES_SUCCESS, GET_LIST_PROCEDURES_SUCCESS, GET_SELECTED_PROCEDURE_SUCCESS } from "../actions/procedures.actions";

const initialProceduresState = {
    proceduresList: [],
    next: null,
    previous: null,
    count: 0,
    proceduresMap: {},
    selectedProcedure: {},
};

export const ProceduresReducer = (state = initialProceduresState, action) => {
    switch (action.type) {
        case GET_LIST_PROCEDURES_SUCCESS:

            let proceduresMap = {};
            for (let procedure of action.payload.results) {
                proceduresMap[procedure.id] = buildProcedureLabel(procedure);
            }

            return {
                ...state,
                proceduresList: action.payload.results,
                next: action.payload.next,
                previous: action.payload.previous,
                count: action.payload.count,
                proceduresMap,
            }
        case GET_SELECTED_PROCEDURE_SUCCESS:
            return {
                ...state,
                selectedProcedure: action.payload
            }
        case CLEAR_SELECTED_PROCEDURE:
            return {
                ...state,
                selectedProcedure: {}
            }
        default:
            return state;
    }
}

const initialAdminProceduresState = {
    adminProceduresList: [],
    next: null,
    previous: null,
    count: 0
};

export const AdminProceduresReducer = (state = initialAdminProceduresState, action) => {
    switch (action.type) {
        case GET_ADMIN_LIST_PROCEDURES_SUCCESS:
            return {
                ...state,
                adminProceduresList: action.payload.results,
                next: action.payload.next,
                previous: action.payload.previous,
                count: action.payload.count,
            }
        default:
            return state;
    }
}
