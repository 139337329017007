import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'reactstrap';
import { CUBICLE_GRID_TYPE_ASSISTANCE_REQUEST, CUBICLE_GRID_TYPE_MY_LIST, CUBICLE_GRID_TYPE_SPECIAL_REQUEST } from '../../components/faculties/FacultyHomeCubicleGrid';
import { FACULTY_GRADING } from '../../navigation/ROUTE_CONSTANTS';
import { createRequestLog } from '../../redux/actions/request_logs.actions';
import { setNavigateTo } from '../../redux/actions/ui.actions';
import {
    CUBICLE_STATUS_OCCUPIED,
    CUBICLE_STATUS_UI_NAMES,
    CUBICLE_STATUS_UNOCCUPIED,
    REQUEST_STATUS_COMPLETED,
    REQUEST_STATUS_ON_QUEUE,
    CUBICLE_STATUS_SPECIAL_REQUEST,
    REQUEST_STATUS_UNDER_WAY,
    STATUS_ELIGIBLE_FOR_REQUEST_LOG,
    CUBICLE_STATUS_CHECKOUT,
} from '../../redux/reducers/my_cubicle.reducer';
import { buildProcedureLabel } from '../../utils';
import crossSVG from '../assets/svg/cancel-close-cross.svg';

export const CUBICLE_VARIANT_STUDENT_QUEUE = 'student-queue';
export const CUBICLE_VARIANT_FACULTY_HOME = 'faculty-home';
export const CUBICLE_VARIANT_NAVBAR = 'navbar';
export const CUBICLE_VARIANT_HEADER = 'header';
export const CUBICLE_VARIANT_CUBICLE_MAP = 'cubicle-map';

/* List of available values for attribute color. It is derived from Bootstrap color classes.
 * info, warning, danger, success, primary, secondary, transparent
 */

const Cubicle = ({ cubicleData, cubicleLogData, variant, gridType = null }) => {
    const dispatch = useDispatch();
    const myCubicleId = useSelector((state) => state.myCubicle.id);
    const outlineClassPrefix = 'outline-';

    const footerDescriptionStyle = {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
    };

    const getButtonColor = () => {
        if (
            cubicleData.cubicle_status === CUBICLE_STATUS_UNOCCUPIED ||
            [CUBICLE_VARIANT_HEADER, CUBICLE_VARIANT_NAVBAR].includes(variant)
        ) {
            return outlineClassPrefix + cubicleData.color;
        }

        return cubicleData.color;
    };

    const getFooterDescription = () => {
        if ([CUBICLE_VARIANT_NAVBAR, CUBICLE_VARIANT_HEADER].includes(variant) || !cubicleLogData) {
            return [];
        }

        if (
            [CUBICLE_STATUS_OCCUPIED, CUBICLE_STATUS_UNOCCUPIED].includes(
                cubicleLogData.cubicle_status
            )
        ) {
            // Single space just to render one row
            return [' '];
        }

        if (cubicleLogData.cubicle_status === CUBICLE_STATUS_SPECIAL_REQUEST) {
            return [cubicleLogData.faculty_requested.last_name];
        }

        if (
            !cubicleLogData?.procedures ||
            cubicleLogData.procedures.length === 0
        ) {
            let statusName =
                CUBICLE_STATUS_UI_NAMES[cubicleLogData.cubicle_status];
            return statusName.split(' ');
        }

        return cubicleLogData.procedures.map((procedure) => buildProcedureLabel(procedure));
    };

    const onClickButtonHandler = (e) => {
        e.preventDefault();
        if (variant === CUBICLE_VARIANT_FACULTY_HOME) {
            if (!cubicleLogData) {
                // If not cubicle log data, not action is needed
            } else if (
                [CUBICLE_GRID_TYPE_ASSISTANCE_REQUEST, CUBICLE_GRID_TYPE_SPECIAL_REQUEST].includes(gridType) &&
                cubicleLogData.request_status === REQUEST_STATUS_ON_QUEUE &&
                STATUS_ELIGIBLE_FOR_REQUEST_LOG.includes(
                    cubicleLogData.cubicle_status
                )
            ) {
                let requestLogData = {
                    cubicle_log: cubicleLogData.id,
                    request_status: REQUEST_STATUS_UNDER_WAY,
                };
                dispatch(createRequestLog(requestLogData));
            } else if (
                gridType === CUBICLE_GRID_TYPE_MY_LIST &&
                cubicleLogData.request_status === REQUEST_STATUS_UNDER_WAY
            ) {
                if (cubicleLogData.cubicle_status === CUBICLE_STATUS_CHECKOUT) {
                    dispatch(setNavigateTo(FACULTY_GRADING.replace(':cubicleLogId', cubicleLogData.id)));
                } else {
                    let requestLogData = {
                        cubicle_log: cubicleLogData.id,
                        request_status: REQUEST_STATUS_COMPLETED,
                    };
                    dispatch(createRequestLog(requestLogData));
                }
            }
        }
    };

    const buttonColor = getButtonColor();
    let footerDescription = getFooterDescription();

    let btnStyle = {
        height: 60,
        width: 60,
        fontWeight: buttonColor.includes(outlineClassPrefix) ? 700 : 400,
        borderRadius: 8,
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        padding: '0.6rem',
    };

    const buttonSize = variant === CUBICLE_VARIANT_NAVBAR ? 'sm' : 'lg';

    if (variant === CUBICLE_VARIANT_NAVBAR) {
        btnStyle.height = 33;
        btnStyle.width = 33;
        btnStyle.padding = 2;
    }

    if (cubicleData.is_enabled === false) {
        btnStyle.opacity = 0.8;
        btnStyle.backgroundImage = `url(${crossSVG})`;
        btnStyle.backgroundSize = 60;
        footerDescription = ['Disabled'];
    }

    if (
        cubicleData.id === myCubicleId &&
        ![CUBICLE_VARIANT_NAVBAR, CUBICLE_VARIANT_HEADER].includes(variant)
    ) {
        btnStyle.boxShadow = '0 0.3rem 2rem rgb(0 0 0 / 82%)';
    }

    let mainDivClass = 'd-inline-flex vstack m-2';
    if ([CUBICLE_VARIANT_NAVBAR, CUBICLE_VARIANT_HEADER].includes(variant)) {
        mainDivClass = 'd-inline-block me-md-0 me-2';
    }

    return (
        <div className={mainDivClass}>
            <Button
                size={buttonSize}
                color={buttonColor}
                style={btnStyle}
                onClick={onClickButtonHandler}
            >
                {cubicleData.name}
            </Button>
            {footerDescription.length > 0 && (
                <div
                    className="fs-7 lh-sm text-center text-black-50 mt-2"
                    style={{ width: 60 }}
                >
                    <div key="0" style={footerDescriptionStyle}>
                        {footerDescription[0]}
                    </div>
                    {footerDescription.length > 1 && (
                        <div key="1" style={footerDescriptionStyle}>
                            {footerDescription[1]}
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default Cubicle;
