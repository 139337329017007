import { useSelector } from "react-redux";
import { Switch } from "react-router-dom";
import { GuardedRoute, GuardProvider } from "react-router-guards";
import { USER_ROLE_CLIENT_ADMIN, USER_ROLE_FACULTY, USER_ROLE_STUDENT } from "../redux/reducers/auth.reducer";
import {
    CLIENT_ADMIN_ANNOUNCEMENTS, CLIENT_ADMIN_CREATE_CUBICLE, CLIENT_ADMIN_CREATE_CUBICLE_GROUP, CLIENT_ADMIN_CREATE_LOGOFF_TIME, CLIENT_ADMIN_CREATE_USER, CLIENT_ADMIN_CUBICLES, CLIENT_ADMIN_CUBICLE_GROUPS, CLIENT_ADMIN_DELETE_CUBICLE, CLIENT_ADMIN_DELETE_CUBICLE_GROUP, CLIENT_ADMIN_DELETE_LOGOFF_TIME, CLIENT_ADMIN_DELETE_USER, CLIENT_ADMIN_EDIT_CUBICLE, CLIENT_ADMIN_EDIT_CUBICLE_GROUP, CLIENT_ADMIN_EDIT_LOGOFF_TIME, CLIENT_ADMIN_EDIT_USER, CLIENT_ADMIN_FACULTY_REPORT, CLIENT_ADMIN_LOGOFF_TIMES, CLIENT_ADMIN_STUDENT_REPORT, CLIENT_ADMIN_USERS, CREATE_ANNOUNCEMENT, CREATE_EXERCISE, CREATE_PROCEDURE, CREATE_PROCEDURE_FROM_EXERCISE, CREATE_STUDENT_EXERCISE, CUBICLES_MAP, DELETE_ANNOUNCEMENT, DELETE_EXERCISE, DELETE_PROCEDURE, DELETE_STUDENT_EXERCISE, EDIT_ANNOUNCEMENT, EDIT_PROFILE, EDIT_STUDENT_EXERCISE, EXERCISES, EXERCISES_DETAILS, EXERCISES_PROCEDURES, FACULTY_CLASSES,
    FACULTY_CLASSES_CREATE,
    FACULTY_CLASSES_DELETE,
    FACULTY_CLASSES_UPDATE, FACULTY_GRADING, FACULTY_HOME,
    FACULTY_MY_ACCOUNT,
    LOGIN_FORM,
    LOGOUT,
    PAGES_ABOUT, PAGES_CONTACT, PAGES_HELP, PAGES_NOT_AUTHORIZED, PAGES_PRIVACY,
    PAGES_TERMS, PROCEDURES, PROCEDURE_DETAILS, PROCEDURE_DETAILS_FROM_EXERCISE, STUDENT_EXERCISES, STUDENT_HOME,
    STUDENT_MY_ACCOUNT,
    USER_HOME, USER_PROFILE, WEBCASTS_ADMIN,
    WEBCASTS_ADMIN_CREATE, WEBCASTS_ADMIN_DELETE, WEBCASTS_ADMIN_EDIT, WEBCASTS_LANDING, WEBCASTS_WATCH
} from "./ROUTE_CONSTANTS";

// General Pages
import NotFound from "../pages/error/NotFound";
import About from "../pages/general/About";
import Login from "../pages/general/Login";
import Logout from "../pages/general/Logout";

// Student Pages
import AccountStudent from "../pages/students/AccountStudent";
import StudentDashboard from "../pages/students/StudentDashboard";

// Faculty Pages
import AccountFaculty from "../pages/faculties/AccountFaculty";
import FacultyGrading from "../pages/faculties/FacultyGrading";
import FacultyHome from "../pages/faculties/FacultyHome";

// Client Admin Pages
import ClientAdminAnnouncements from "../pages/client_admins/ClientAdminAnnouncements";
import ClientAdminCreateUser from "../pages/client_admins/ClientAdminCreateUser";
import ClientAdminCubicleDelete from "../pages/client_admins/ClientAdminCubicleDelete";
import ClientAdminCubicleForm from "../pages/client_admins/ClientAdminCubicleForm";
import ClientAdminCubicleGroupDelete from "../pages/client_admins/ClientAdminCubicleGroupDelete";
import ClientAdminCubicleGroupForm from "../pages/client_admins/ClientAdminCubicleGroupForm";
import ClientAdminCubicleGroups from "../pages/client_admins/ClientAdminCubicleGroups";
import ClientAdminCubicles from "../pages/client_admins/ClientAdminCubicles";
import ClientAdminDeleteUser from "../pages/client_admins/ClientAdminDeleteUser";
import ClientAdminEditUser from "../pages/client_admins/ClientAdminEditUser";
import ClientAdminExerciseDelete from "../pages/client_admins/ClientAdminExerciseDelete";
import ClientAdminFacultyReport from "../pages/client_admins/ClientAdminFacultyReport";
import ClientAdminLogoffTimeDelete from "../pages/client_admins/ClientAdminLogoffTimeDelete";
import ClientAdminLogoffTimeForm from "../pages/client_admins/ClientAdminLogoffTimeForm";
import ClientAdminLogoffTimes from "../pages/client_admins/ClientAdminLogoffTimes";
import ClientAdminProcedureDelete from "../pages/client_admins/ClientAdminProcedureDelete";
import ClientAdminStudentReport from "../pages/client_admins/ClientAdminStudentReport";
import ClientAdminUsers from "../pages/client_admins/ClientAdminUsers";
import Contact from "../pages/general/Contact";
import CubicleMap from "../pages/general/CubicleMap";
import ExerciseForm from "../pages/general/Exercises/ExerciseForm";
import Exercises from "../pages/general/Exercises/Exercises";
import Help from "../pages/general/Help";
import Privacy from "../pages/general/Privacy";
import ProcedureForm from "../pages/general/Procedures/ProcedureForm";
import Procedures from "../pages/general/Procedures/Procedures";
import Terms from "../pages/general/Terms";
import EditProfile from "../pages/user/EditProfile";
import Profile from "../pages/user/Profile";

import ClientAdminAnnouncementDelete from "../pages/client_admins/ClientAdminAnnouncementDelete";
import ClientAdminAnnouncementForm from "../pages/client_admins/ClientAdminAnnouncementForm";
import NotAuthorized from "../pages/error/NotAuthorized";
import FacultyClassesDelete from "../pages/faculties/FacultyClassDelete";
import FacultyClasses from "../pages/faculties/FacultyClasses";
import FacultyClassesForm from "../pages/faculties/FacultyClassesForm";
import ExerciseProceduresForm from "../pages/general/Exercises/ExerciseProceduresForm";
import StudentExerciseDelete from "../pages/general/StudentExercises/StudentExerciseDelete";
import StudentExerciseForm from "../pages/general/StudentExercises/StudentExerciseForm";
import StudentExercises from "../pages/general/StudentExercises/StudentExercises";
import WebcastsLanding from "../pages/webcasts/WebcastLanding";
import WebcastsDelete from "../pages/webcasts/WebcastsDelete";
import WebcastsForm from "../pages/webcasts/WebcastsForm";
import WebcastsList from "../pages/webcasts/WebcastsList";
import WebcastsViewer from "../pages/webcasts/WebcastsViewer";

const getIsLoggedIn = () => {
    return !!(localStorage.getItem('ca-user'));
};

const checkCurrentUserRole = (role) => {
    const user = JSON.parse(localStorage.getItem('ca-user'));
    return user.role === role;
};

const requireLogin = (to, from, next) => {
    const auth = to.meta.auth || false;
    if (auth) {
        if (getIsLoggedIn()) {
            next();
        }
        next.redirect(LOGIN_FORM);
    } else {
        next();
    }
};

const requireRole = (to, from, next) => {
    const role = to.meta.role || null;
    if (role) {
        if (checkCurrentUserRole(role)) {
            next();
        }
        next.redirect(PAGES_NOT_AUTHORIZED);
    } else {
        next();
    }
};

export const RouterConfig = () => {
    const userLogged = useSelector(state => state.users.userLogged);

    return (
        <GuardProvider guards={[requireLogin, requireRole]} error={NotFound}>
            <Switch>
                {/* List all public routes here */}

                <GuardedRoute path={LOGIN_FORM} exact component={Login} />
                <GuardedRoute path={PAGES_ABOUT} exact component={About} />
                <GuardedRoute path={PAGES_PRIVACY} exact component={Privacy} />
                <GuardedRoute path={PAGES_TERMS} exact component={Terms} />
                <GuardedRoute path={PAGES_HELP} exact component={Help} />
                <GuardedRoute path={PAGES_CONTACT} exact component={Contact} />

                {/*
                <GuardedRoute exact path={RESET_PASSWORD}>
                <ResetPassword />
                </GuardedRoute>

                <GuardedRoute exact path={CREATE_ACCOUNT}>
                <CreateAccount />
                </GuardedRoute>
                */}

                {/* List all private/auth routes here */}
                <GuardedRoute path={LOGOUT} exact component={Logout} meta={{ auth: true }} />
                <GuardedRoute path={USER_HOME} exact meta={{ auth: true }} >
                    {
                        (userLogged?.role === USER_ROLE_STUDENT) && <StudentDashboard />
                    }
                    {
                        (userLogged?.role === USER_ROLE_FACULTY) && <FacultyHome />
                    }
                    {(userLogged?.role === USER_ROLE_CLIENT_ADMIN) && <ClientAdminUsers />}
                </GuardedRoute>

                <GuardedRoute path={CUBICLES_MAP} exact component={CubicleMap} meta={{ auth: true }} />

                <GuardedRoute path={EXERCISES} exact component={Exercises} meta={{ auth: true }} />
                <GuardedRoute path={EXERCISES_DETAILS} exact component={ExerciseForm} meta={{ auth: true }} />

                <GuardedRoute path={PROCEDURES} exact component={Procedures} meta={{ auth: true }} />
                <GuardedRoute path={PROCEDURE_DETAILS} exact component={ProcedureForm} meta={{ auth: true }} />
                <GuardedRoute path={PROCEDURE_DETAILS_FROM_EXERCISE} exact component={ProcedureForm} meta={{ auth: true }} />

                <GuardedRoute path={STUDENT_HOME} exact component={StudentDashboard} meta={{ auth: true, role: USER_ROLE_STUDENT }} />
                <GuardedRoute path={USER_PROFILE} exact component={Profile} meta={{ auth: true }} />
                <GuardedRoute path={EDIT_PROFILE} exact component={EditProfile} meta={{ auth: true }} />

                <GuardedRoute path={STUDENT_MY_ACCOUNT} exact component={AccountStudent} meta={{ auth: true, role: USER_ROLE_STUDENT }} />

                <GuardedRoute path={FACULTY_HOME} exact component={FacultyHome} meta={{ auth: true, role: USER_ROLE_FACULTY }} />
                <GuardedRoute path={FACULTY_MY_ACCOUNT} exact component={AccountFaculty} meta={{ auth: true, role: USER_ROLE_FACULTY }} />
                <GuardedRoute path={FACULTY_GRADING} exact component={FacultyGrading} meta={{ auth: true, role: USER_ROLE_FACULTY }} />
                <GuardedRoute path={FACULTY_CLASSES} exact component={FacultyClasses} meta={{ auth: true, role: USER_ROLE_FACULTY }} />
                <GuardedRoute path={FACULTY_CLASSES_CREATE} exact component={FacultyClassesForm} meta={{ auth: true, role: USER_ROLE_FACULTY }} />
                <GuardedRoute path={FACULTY_CLASSES_UPDATE} exact component={FacultyClassesForm} meta={{ auth: true, role: USER_ROLE_FACULTY }} />
                <GuardedRoute path={FACULTY_CLASSES_DELETE} exact component={FacultyClassesDelete} meta={{ auth: true, role: USER_ROLE_FACULTY }} />

                <GuardedRoute path={CLIENT_ADMIN_FACULTY_REPORT} exact component={ClientAdminFacultyReport} meta={{ auth: true, role: USER_ROLE_CLIENT_ADMIN }} />
                <GuardedRoute path={CLIENT_ADMIN_STUDENT_REPORT} exact component={ClientAdminStudentReport} meta={{ auth: true, role: USER_ROLE_CLIENT_ADMIN }} />

                <GuardedRoute path={CLIENT_ADMIN_ANNOUNCEMENTS} exact component={ClientAdminAnnouncements} meta={{ auth: true, role: USER_ROLE_CLIENT_ADMIN }} />
                <GuardedRoute path={CREATE_ANNOUNCEMENT} exact component={ClientAdminAnnouncementForm} meta={{ auth: true, role: USER_ROLE_CLIENT_ADMIN }} />
                <GuardedRoute path={EDIT_ANNOUNCEMENT} exact component={ClientAdminAnnouncementForm} meta={{ auth: true, role: USER_ROLE_CLIENT_ADMIN }} />
                <GuardedRoute path={DELETE_ANNOUNCEMENT} exact component={ClientAdminAnnouncementDelete} meta={{auth: true, role: USER_ROLE_CLIENT_ADMIN}} />

                <GuardedRoute path={CLIENT_ADMIN_USERS} exact component={ClientAdminUsers} meta={{ auth: true, role: USER_ROLE_CLIENT_ADMIN }} />
                <GuardedRoute path={CLIENT_ADMIN_CREATE_USER} exact component={ClientAdminCreateUser} meta={{ auth: true, role: USER_ROLE_CLIENT_ADMIN }} />
                <GuardedRoute path={CLIENT_ADMIN_EDIT_USER} exact component={ClientAdminEditUser} meta={{ auth: true, role: USER_ROLE_CLIENT_ADMIN }} />
                <GuardedRoute path={CLIENT_ADMIN_DELETE_USER} exact component={ClientAdminDeleteUser} meta={{ auth: true, role: USER_ROLE_CLIENT_ADMIN }} />

                <GuardedRoute path={CLIENT_ADMIN_CUBICLES} exact component={ClientAdminCubicles} meta={{ auth: true, role: USER_ROLE_CLIENT_ADMIN }} />
                <GuardedRoute path={CLIENT_ADMIN_CREATE_CUBICLE} exact component={ClientAdminCubicleForm} meta={{ auth: true, role: USER_ROLE_CLIENT_ADMIN }} />
                <GuardedRoute path={CLIENT_ADMIN_EDIT_CUBICLE} exact component={ClientAdminCubicleForm} meta={{ auth: true, role: USER_ROLE_CLIENT_ADMIN }} />
                <GuardedRoute path={CLIENT_ADMIN_DELETE_CUBICLE} exact component={ClientAdminCubicleDelete} meta={{ auth: true, role: USER_ROLE_CLIENT_ADMIN }} />

                <GuardedRoute path={CLIENT_ADMIN_LOGOFF_TIMES} exact component={ClientAdminLogoffTimes} meta={{ auth: true, role: USER_ROLE_CLIENT_ADMIN }} />
                <GuardedRoute path={CLIENT_ADMIN_CREATE_LOGOFF_TIME} exact component={ClientAdminLogoffTimeForm} meta={{ auth: true, role: USER_ROLE_CLIENT_ADMIN }} />
                <GuardedRoute path={CLIENT_ADMIN_EDIT_LOGOFF_TIME} exact component={ClientAdminLogoffTimeForm} meta={{ auth: true, role: USER_ROLE_CLIENT_ADMIN }} />
                <GuardedRoute path={CLIENT_ADMIN_DELETE_LOGOFF_TIME} exact component={ClientAdminLogoffTimeDelete} meta={{ auth: true, role: USER_ROLE_CLIENT_ADMIN }} />

                <GuardedRoute path={CLIENT_ADMIN_CUBICLE_GROUPS} exact component={ClientAdminCubicleGroups} meta={{ auth: true, role: USER_ROLE_CLIENT_ADMIN }} />
                <GuardedRoute path={CLIENT_ADMIN_CREATE_CUBICLE_GROUP} exact component={ClientAdminCubicleGroupForm} meta={{ auth: true, role: USER_ROLE_CLIENT_ADMIN }} />
                <GuardedRoute path={CLIENT_ADMIN_EDIT_CUBICLE_GROUP} exact component={ClientAdminCubicleGroupForm} meta={{ auth: true, role: USER_ROLE_CLIENT_ADMIN }} />
                <GuardedRoute path={CLIENT_ADMIN_DELETE_CUBICLE_GROUP} exact component={ClientAdminCubicleGroupDelete} meta={{ auth: true, role: USER_ROLE_CLIENT_ADMIN }} />

                <GuardedRoute path={EXERCISES_PROCEDURES} exact component={ExerciseProceduresForm} meta={{ auth: true, role: USER_ROLE_CLIENT_ADMIN }} />

                <GuardedRoute path={CREATE_PROCEDURE} exact component={ProcedureForm} meta={{ auth: true, role: USER_ROLE_CLIENT_ADMIN }} />
                <GuardedRoute path={CREATE_PROCEDURE_FROM_EXERCISE} exact component={ProcedureForm} meta={{ auth: true, role: USER_ROLE_CLIENT_ADMIN }} />
                <GuardedRoute path={DELETE_PROCEDURE} exact component={ClientAdminProcedureDelete} meta={{ auth: true, role: USER_ROLE_CLIENT_ADMIN }} />

                <GuardedRoute path={CREATE_EXERCISE} exact component={ExerciseForm} meta={{ auth: true, role: USER_ROLE_CLIENT_ADMIN }} />
                <GuardedRoute path={DELETE_EXERCISE} exact component={ClientAdminExerciseDelete} meta={{ auth: true, role: USER_ROLE_CLIENT_ADMIN }} />

                <GuardedRoute path={STUDENT_EXERCISES} exact component={StudentExercises} meta={{ auth: true, role: USER_ROLE_CLIENT_ADMIN }} />
                <GuardedRoute path={CREATE_STUDENT_EXERCISE} exact component={StudentExerciseForm} meta={{ auth: true, role: USER_ROLE_CLIENT_ADMIN }} />
                <GuardedRoute path={EDIT_STUDENT_EXERCISE} exact component={StudentExerciseForm} meta={{ auth: true, role: USER_ROLE_CLIENT_ADMIN }} />
                <GuardedRoute path={DELETE_STUDENT_EXERCISE} exact component={StudentExerciseDelete} meta={{ auth: true, role: USER_ROLE_CLIENT_ADMIN }} />

                <GuardedRoute path={WEBCASTS_ADMIN} exact component={WebcastsList} meta={{ auth: true, role: USER_ROLE_CLIENT_ADMIN }} />
                <GuardedRoute path={WEBCASTS_ADMIN_CREATE} exact component={WebcastsForm} meta={{ auth: true, role: USER_ROLE_CLIENT_ADMIN }} />
                <GuardedRoute path={WEBCASTS_ADMIN_EDIT} exact component={WebcastsForm} meta={{ auth: true, role: USER_ROLE_CLIENT_ADMIN }} />
                <GuardedRoute path={WEBCASTS_ADMIN_DELETE} exact component={WebcastsDelete} meta={{ auth: true, role: USER_ROLE_CLIENT_ADMIN }} />

                <GuardedRoute path={WEBCASTS_LANDING} exact component={WebcastsLanding} meta={{ auth: true }} />
                <GuardedRoute path={WEBCASTS_WATCH} exact component={WebcastsViewer} meta={{ auth: true }} />

                {/* List a generic 404-Not Found route here */}
                <GuardedRoute path="*">
                    <NotFound />
                </GuardedRoute>

                <GuardedRoute path={PAGES_NOT_AUTHORIZED} >
                    <NotAuthorized />
                </GuardedRoute>

            </Switch>
        </GuardProvider>
    );
};
