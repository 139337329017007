import { all, call, fork, put, select, takeEvery } from "redux-saga/effects";
import { disableOverlay, enableOverlay, updateHeaderFromMyCubicle } from "../actions/ui.actions";
import { genericError } from "../actions/error.actions";
import { GET_MY_CUBICLE, getMyCubicleSuccess, GET_MY_CUBICLE_SUCCESS, getMyCubicle, SHOULD_REFRESH_MY_CUBICLE, SHOULD_REFRESH_MY_CUBICLE_FROM_CUBICLE_LOGS_QUEUE } from "../actions/my_cubicle.actions"
import * as myCubicleApi from "../../services/myCubicleService"

function* getMyCubicleEffect() {
  try {
    yield put(enableOverlay('Loading My cubicle...'));
    const myCubicleData = yield call(myCubicleApi.getMyCubicle);
    yield put(getMyCubicleSuccess(myCubicleData));
    yield put(disableOverlay());
  } catch (err) {
    yield put(genericError(err));
    yield put(disableOverlay());
  }
}

function* getMyCubicleSuccessEffect({ payload }) {
  yield put(updateHeaderFromMyCubicle(payload));
}

function* shouldRefreshMyCubicleEffect({ payload }) {
  const myCubicle = yield select((state) => state.myCubicle);
  const onQueueMyCubicle = payload.find((cubicle) => cubicle.id === myCubicle.id);
  if (onQueueMyCubicle) {
    // Verify changes on attributes of interest
    if (
      myCubicle.cubicle_status !== onQueueMyCubicle.cubicle_status ||
      !!myCubicle.last_cubicle_log ^ !!onQueueMyCubicle.last_cubicle_log ||  // XOR for detecting null on only one side
      myCubicle.last_cubicle_log?.id !== onQueueMyCubicle.last_cubicle_log?.id ||
      myCubicle.underway_faculty !== onQueueMyCubicle.underway_faculty ||
      myCubicle.request_status !== onQueueMyCubicle.request_status
    ) {
      yield put(getMyCubicle());
    }
  }
}

function* shouldRefreshMyCubicleFromCubicleLogsQueueEffect({ payload }) {
  const myCubicle = yield select((state) => state.myCubicle);
  // payload contains on going cubicle logs sort by created_at desc
  //   so the first element is the last created cubicle log
  const onQueueMyCubicleLog = payload.find((cubicleLog) => cubicleLog.cubicle.id === myCubicle.id);
  if (onQueueMyCubicleLog) {
    // Verify changes on attributes of interest
    if (
      myCubicle.cubicle_status !== onQueueMyCubicleLog.cubicle_status ||
      myCubicle.last_cubicle_log.id !== onQueueMyCubicleLog.id ||
      myCubicle.last_cubicle_log.accepted_by_faculty !== onQueueMyCubicleLog.accepted_by_faculty ||
      myCubicle.request_status !== onQueueMyCubicleLog.request_status
    ) {
      yield put(getMyCubicle());
    }
  }
}

export function* watchGetMyCubicle() {
  yield takeEvery(GET_MY_CUBICLE, getMyCubicleEffect);
}

export function* watchGetMyCubicleSuccess() {
  yield takeEvery(GET_MY_CUBICLE_SUCCESS, getMyCubicleSuccessEffect);
}

export function* watchRefreshMyCubicle() {
  yield takeEvery(SHOULD_REFRESH_MY_CUBICLE, shouldRefreshMyCubicleEffect);
}

export function* watchRefreshMyCubicleFromCubicleLogsQueue() {
  yield takeEvery(SHOULD_REFRESH_MY_CUBICLE_FROM_CUBICLE_LOGS_QUEUE, shouldRefreshMyCubicleFromCubicleLogsQueueEffect);
}

function* myCubicleSaga() {
  yield all([
    fork(watchGetMyCubicle),
    fork(watchGetMyCubicleSuccess),
    fork(watchRefreshMyCubicle),
  ]);
}

export default myCubicleSaga;
