import { Fragment } from "react";
import { CardText, CardTitle } from "reactstrap";
import { FORMAT_DATETIME_MED_WITH_WEEKDAY, isoStringToFormatted } from "../../utils/date_time";

const WebcastItem = ({ webcastData }) => {
    return (
        <Fragment>
            <CardTitle className="text-green fw-bold mb-2">{webcastData.title}</CardTitle>

            <CardText>
                Author: {webcastData.author}
                <span className="px-3">|</span>
                {webcastData.event_time ? isoStringToFormatted(webcastData.event_time, FORMAT_DATETIME_MED_WITH_WEEKDAY) : ''}
            </CardText>

            <CardText>
                {webcastData.description}
            </CardText>
        </Fragment>
    );
};

export default WebcastItem;