import { ErrorMessage, Field, Form, Formik } from 'formik';
import { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import Select from "react-select";
import { Button, Col, Container, FormGroup, FormText, Input, Row } from 'reactstrap';
import * as Yup from 'yup';
import AppBreadcrumb from '../../components/ui/AppBreadcrumb';
import { CLIENT_ADMIN_LOGOFF_TIMES } from '../../navigation/ROUTE_CONSTANTS';
import { createLogoffTime, updateLogoffTime } from '../../redux/actions/logoff_times.actions';
import { FREQUENCY_DAILY, FREQUENCY_UI_NAMES, LOGOFF_ROLE_FACULTY_AND_STUDENT, LOGOFF_ROLE_UI_NAMES } from '../../redux/reducers/logoff_times.reducers';
import { getOneLogoffTime } from '../../services/logoffTimesService';
import '../../styles/AppDatePicker.scss';
import { createFromParams, dateTimeToAPIDateTime, dateTimeToFormatted, FORMAT_DATETIME_SHORT, parseToDateTime } from '../../utils/date_time';

const convertFromFormBaseDt = (formBaseDt) => {
    return createFromParams({
        year: formBaseDt.getFullYear(),
        month: formBaseDt.getMonth() + 1,
        day: formBaseDt.getDate(),
        hour: formBaseDt.getHours(),
        minute: formBaseDt.getMinutes(),
    });
}

const convertToFormBaseDt = (dateTime) => {
    return new Date(dateTime.year, dateTime.month - 1, dateTime.day, dateTime.hour, dateTime.minute);;
}

const initialBaseDt = createFromParams({ hour: 12, minute: 0 });
const initialBaseDtJSDateLocalTimezone = convertToFormBaseDt(initialBaseDt);
const FREQUENCY_OPTIONS = Object.keys(FREQUENCY_UI_NAMES);
const LOGOFF_ROLE_OPTIONS = Object.keys(LOGOFF_ROLE_UI_NAMES);

function ClientAdminLogoffTimeForm() {

    const dispatch = useDispatch();
    const { logoffTimeId } = useParams();
    const [initialFormValues, setInitialFormValues] = useState({
        form_base_dt: initialBaseDtJSDateLocalTimezone,
        frequency: FREQUENCY_DAILY,
        logoff_role: LOGOFF_ROLE_FACULTY_AND_STUDENT,
    });
    const [pageTitle, setPageTitle] = useState('');

    useEffect(() => {
        if (logoffTimeId) {
            setPageTitle('Edit Logoff Time');
            getOneLogoffTime(logoffTimeId).then((data) => {
                let baseDt = parseToDateTime(data.base_dt_utc);
                // DatePicker works only with JS Date in browser default timezone
                // But we need to work with the Client (tenant/university) timezone
                // So we create JS Date on browser default timezone and set the date and time Client timezone
                let baseDtJSDateLocalTimezone = convertToFormBaseDt(baseDt);

                setInitialFormValues({
                    form_base_dt: baseDtJSDateLocalTimezone,
                    frequency: data.frequency,
                    logoff_role: data.logoff_role,
                });
            })
        } else {
            setPageTitle('Create Logoff Time');
        }
    }, [logoffTimeId]);

    useEffect(() => {
        document.title = process.env.REACT_APP_SITE_TITLE + ' - ' + pageTitle;
    }, [pageTitle]);

    return (
        <Container className='mb-5 pb-5'>
            <Row>
                <Col>
                    <AppBreadcrumb headText={pageTitle} crumbList={[{ linkTo: CLIENT_ADMIN_LOGOFF_TIMES, label: 'Logoff Times' }]} />
                </Col>
            </Row>

            <Row>
                <Col>
                    <div className='vstack col-md-4 mx-auto'>
                        <h4 className='pt-2 pb-3 text-center'>{pageTitle}</h4>
                        <Formik
                            enableReinitialize={true}
                            initialValues={initialFormValues}
                            validationSchema={Yup.object({
                                frequency: Yup.string()
                                    .oneOf(FREQUENCY_OPTIONS, 'Please select a frequency'),
                                logoff_role: Yup.string()
                                    .oneOf(LOGOFF_ROLE_OPTIONS, 'Please select a logoff role'),
                            })}
                            onSubmit={(values) => {
                                const toSubmitBaseDt = convertFromFormBaseDt(values.form_base_dt);
                                let formData = {
                                    base_dt_utc: dateTimeToAPIDateTime(toSubmitBaseDt),
                                    frequency: values.frequency,
                                    logoff_role: values.logoff_role,
                                }

                                if (logoffTimeId) {
                                    formData.id = logoffTimeId;
                                    dispatch(updateLogoffTime(formData));
                                } else {
                                    dispatch(createLogoffTime(formData));
                                }
                            }}
                        >{({ values, setFieldValue }) => (
                            <Form>
                                <FormGroup>
                                    <Field type='text' name='form_base_dt' readOnly as={Input}
                                        value={dateTimeToFormatted(convertFromFormBaseDt(values.form_base_dt), FORMAT_DATETIME_SHORT)}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <DatePicker
                                        placeholderText="Base date and time"
                                        selected={values.form_base_dt}
                                        onChange={(dateTime) => setFieldValue('form_base_dt', dateTime)}
                                        showTimeSelect
                                        timeFormat='HH:mm'
                                        timeIntervals={30}
                                        inline
                                        fixedHeight
                                    />
                                    <FormText>
                                        Pick a base date and time for automatic logoff.
                                    </FormText>
                                </FormGroup>
                                <FormGroup >
                                    <Field id="frequency" name="frequency" as={Select}
                                        placeholder="Frequency"
                                        value={values.frequency ? { value: values.frequency, label: FREQUENCY_UI_NAMES[values.frequency] } : ''}
                                        onChange={option => setFieldValue('frequency', option.value)}
                                        options={FREQUENCY_OPTIONS.map(frequency => ({ value: frequency, label: FREQUENCY_UI_NAMES[frequency] }))}
                                        isSearchable={false}
                                    />
                                    <ErrorMessage component="div" className="text-danger" name="frequency" />
                                </FormGroup>
                                <FormGroup >
                                    <Field id="logoff_role" name="logoff_role" as={Select}
                                        placeholder="Logoff role"
                                        value={values.logoff_role ? { value: values.logoff_role, label: LOGOFF_ROLE_UI_NAMES[values.logoff_role] } : ''}
                                        onChange={option => setFieldValue('logoff_role', option.value)}
                                        options={LOGOFF_ROLE_OPTIONS.map(logoffRole => ({ value: logoffRole, label: LOGOFF_ROLE_UI_NAMES[logoffRole] }))}
                                        isSearchable={false}
                                    />
                                    <ErrorMessage component="div" className="text-danger" name="logoff_role" />
                                </FormGroup>
                                <div className='text-center'>
                                    <Button color='success' className='mt-2 px-5' type='submit'>
                                        <span className='d-inline-block fs-5 px-3'>{pageTitle}</span>
                                    </Button>
                                </div>
                            </Form>
                        )}
                        </Formik>
                    </div>
                </Col>
            </Row >

        </Container>
    )
}

export default ClientAdminLogoffTimeForm;