import { USER_HOME } from '../../navigation/ROUTE_CONSTANTS';
import {
    CLOSE_MODAL_PROGRESS,
    CLOSE_NOTIFICATION_MODAL,
    DISABLE_OVERLAY,
    ENABLE_OVERLAY,
    NEW_NOTIFICATION_MODAL,
    SET_COMETCHAT_INIT_COMPLETED,
    SET_FORM_DIALOG_SUBMIT_SUCCESS,
    SET_FORM_SUBMITTED_SUCCESS,
    SET_HEADER_DATA,
    SET_HOME_TARGET_URL,
    SET_NAVIGATE_TO,
    SET_START_LOGOUT_PROCESS,
    SET_TOAST,
    UPDATE_HEADER_FROM_MY_CUBICLE,
    UPDATE_INDEX_DROPDOWN_MOBILE,
    UPDATE_MODAL_PROGRESS,
    UPDATE_SHOW_DROPDOWN_MOBILE
} from '../actions/ui.actions';
import {
    CUBICLE_STATUS_CHECKOUT,
    CUBICLE_STATUS_HELP,
    CUBICLE_STATUS_OCCUPIED,
    CUBICLE_STATUS_SPECIAL_REQUEST,
    CUBICLE_STATUS_START_CHECK,
    REQUEST_STATUS_ON_QUEUE,
    REQUEST_STATUS_UNDER_WAY
} from './my_cubicle.reducer';

export const TOAST_SEVERITY_SUCCESS = 'success';
export const TOAST_SEVERITY_ERROR = 'error';

export const HEADER_COLOR_PRIMARY = 'primary';
export const HEADER_COLOR_SUCCESS = 'success';
export const HEADER_COLOR_WHITE = 'white';
export const HEADER_COLOR_BODY = 'body';
export const HEADER_COLOR_HELP = 'help';
export const HEADER_COLOR_BLACK = 'black';
export const HEADER_COLOR_OUTLINE_SUCCESS = 'outline-success';
export const HEADER_COLOR_OUTLINE_SECONDARY = 'outline-secondary';

const initialHeaderData = {
    color: HEADER_COLOR_WHITE,
    title: {
        color: HEADER_COLOR_WHITE,
        text: false,
    },
    subtitle: {
        color: HEADER_COLOR_WHITE,
        text: false,
    },
    leftSection: {
        title: {
            color: HEADER_COLOR_WHITE,
            text: false,
        },
        cubicle: {
            size: 'lg',
            color: HEADER_COLOR_OUTLINE_SUCCESS,
            location: false, // Cubicle name
        },
    },
    rightSection: null,
};

const buildHeaderFromMyCubicle = (headerCurrentState, myCubicle) => {
    let lastCubicleLog = myCubicle.last_cubicle_log;

    let headerData = {
        ...headerCurrentState,
    };

    if (!lastCubicleLog || lastCubicleLog.cubicle_status === CUBICLE_STATUS_OCCUPIED) {
        headerData = {
            ...headerData,
            title: {
                color: HEADER_COLOR_BODY,
                text: 'Welcome back',
            },
            subtitle: null,
            color: HEADER_COLOR_WHITE,
            leftSection: {
                title: {
                    color: HEADER_COLOR_SUCCESS,
                    text: 'This is cubicle',
                },
                cubicle: {
                    size: 'lg',
                    color: HEADER_COLOR_OUTLINE_SUCCESS,
                    location: myCubicle.name,
                },
            },
            rightSection: null,
        };
    } else if (lastCubicleLog.cubicle_status === CUBICLE_STATUS_START_CHECK) {
        headerData = {
            ...headerData,
            title: null,
            subtitle: {
                color: HEADER_COLOR_WHITE,
                text: 'Please remain in your cubicle',
            },
            color: HEADER_COLOR_SUCCESS,
            leftSection: {
                title: {
                    color: HEADER_COLOR_WHITE,
                    text: 'This is cubicle',
                },
                cubicle: {
                    size: 'lg',
                    color: HEADER_COLOR_OUTLINE_SUCCESS,
                    location: myCubicle.name,
                },
            },
            rightSection: {
                buttonCancel: {
                    color: HEADER_COLOR_OUTLINE_SECONDARY,
                    cubicleLogId: lastCubicleLog.id,
                },
            },
        };

        if (lastCubicleLog.request_status === REQUEST_STATUS_ON_QUEUE) {
            headerData = {
                ...headerData,
                title: {
                    color: HEADER_COLOR_WHITE,
                    text: 'Your start check request has been submitted',
                },
            };
        } else if (lastCubicleLog.request_status === REQUEST_STATUS_UNDER_WAY) {
            headerData = {
                ...headerData,
                title: {
                    color: HEADER_COLOR_WHITE,
                    text: 'Your start check request is accepted',
                },
            };
        }
    } else if (lastCubicleLog.cubicle_status === CUBICLE_STATUS_CHECKOUT) {
        headerData = {
            ...headerData,
            title: null,
            subtitle: {
                color: HEADER_COLOR_WHITE,
                text: 'Please remain in your cubicle',
            },
            color: HEADER_COLOR_PRIMARY,
            leftSection: {
                title: {
                    color: HEADER_COLOR_WHITE,
                    text: 'This is cubicle',
                },
                cubicle: {
                    size: 'lg',
                    color: HEADER_COLOR_OUTLINE_SUCCESS,
                    location: myCubicle.name,
                },
            },
            rightSection: {
                buttonCancel: {
                    color: HEADER_COLOR_OUTLINE_SECONDARY,
                    cubicleLogId: lastCubicleLog.id,
                },
            },
        };

        if (lastCubicleLog.request_status === REQUEST_STATUS_ON_QUEUE) {
            headerData = {
                ...headerData,
                title: {
                    color: HEADER_COLOR_WHITE,
                    text: 'Your treatment complete request has been submitted',
                },
            };
        } else if (lastCubicleLog.request_status === REQUEST_STATUS_UNDER_WAY) {
            headerData = {
                ...headerData,
                title: {
                    color: HEADER_COLOR_WHITE,
                    text: 'Your treatment complete request is accepted',
                },
            };
        }
    } else if (
        lastCubicleLog.cubicle_status === CUBICLE_STATUS_HELP ||
        lastCubicleLog.cubicle_status === CUBICLE_STATUS_SPECIAL_REQUEST
    ) {
        headerData = {
            ...headerData,
            title: null,
            subtitle: {
                color: HEADER_COLOR_BODY,
                text: 'Please remain in your cubicle',
            },
            color: HEADER_COLOR_WHITE,
            leftSection: {
                title: {
                    color: HEADER_COLOR_SUCCESS,
                    text: 'This is cubicle',
                },
                cubicle: {
                    size: 'lg',
                    color: HEADER_COLOR_OUTLINE_SUCCESS,
                    location: myCubicle.name,
                },
            },
            rightSection: {
                buttonCancel: {
                    color: HEADER_COLOR_OUTLINE_SECONDARY,
                    cubicleLogId: lastCubicleLog.id,
                },
            },
        };

        if (lastCubicleLog.request_status === REQUEST_STATUS_ON_QUEUE) {
            headerData = {
                ...headerData,
                title: {
                    color: HEADER_COLOR_HELP,
                    text: "You've requested help",
                },
            };
        } else if (lastCubicleLog.request_status === REQUEST_STATUS_UNDER_WAY) {
            headerData = {
                ...headerData,
                title: {
                    color: HEADER_COLOR_HELP,
                    text: 'Your help request is accepted',
                },
            };
        }
    }

    return headerData;
};

const initialUIState = {
    overlay: { loading: false, message: null },
    modalProgress: { isOpen: false, message: null, percentage: 0},
    notificationModal: {
        title: null,
        message: null,
        iconType: null,
        isOpen: false,
    },
    toast: {
        severity: null,
        summary: null,
        detail: null,
        life: null,
    },
    header: { ...initialHeaderData },
    navigateTo: null,
    homeTargetUrl: USER_HOME,
    showDropdownMobile: false,
    indexDropdownMobile: 0,
    formDialogSubmitSuccess: false,
    formSubmittedSuccess: false,
    cometchatInitCompleted: false,
    startLogoutProcess: false,
};

export const UIReducer = (state = initialUIState, action) => {
    switch (action.type) {
        case ENABLE_OVERLAY:
            return {
                ...state,
                overlay: { loading: true, message: action.payload },
            };
        case DISABLE_OVERLAY:
            return { ...state, overlay: { loading: false, message: null } };
        case UPDATE_MODAL_PROGRESS:
            return {
                ...state,
                modalProgress: {
                    isOpen: true,
                    message: action.payload.message,
                    percentage: action.payload.percentage,
                },
            };
        case CLOSE_MODAL_PROGRESS:
            return {
                ...state,
                modalProgress: {
                    isOpen: false,
                    message: null,
                    percentage: 0,
                },
            };
        case NEW_NOTIFICATION_MODAL:
            return {
                ...state,
                notificationModal: {
                    title: action.payload.title,
                    message: action.payload.message,
                    iconType: action.payload.iconType,
                    buttonResponse: action.payload.buttonResponse,
                    buttonAcceptText: action.payload.buttonAcceptText,
                    handleAccept: action.payload.handleAccept,
                    isOpen: true,
                },
            };
        case CLOSE_NOTIFICATION_MODAL:
            return {
                ...state,
                notificationModal: {
                    title: null,
                    message: null,
                    iconType: null,
                    isOpen: false,
                },
            };
        case SET_TOAST:
            return { ...state, toast: action.payload };
        case UPDATE_HEADER_FROM_MY_CUBICLE:
            return {
                ...state,
                header: buildHeaderFromMyCubicle(state.header, action.payload),
            };
        case SET_HEADER_DATA:
            return { ...state, header: { ...state.header, ...action.payload } };
        case SET_NAVIGATE_TO:
            return { ...state, navigateTo: action.payload };
        case SET_HOME_TARGET_URL:
            return { ...state, homeTargetUrl: action.payload };
        case UPDATE_SHOW_DROPDOWN_MOBILE:
            return { ...state, showDropdownMobile: action.payload };
        case UPDATE_INDEX_DROPDOWN_MOBILE:
            return { ...state, indexDropdownMobile: action.payload };
        case SET_FORM_DIALOG_SUBMIT_SUCCESS:
            return { ...state, formDialogSubmitSuccess: action.payload };
        case SET_FORM_SUBMITTED_SUCCESS:
            return { ...state, formSubmittedSuccess: action.payload };
        case SET_START_LOGOUT_PROCESS:
            return { ...state, startLogoutProcess: action.payload };
        case SET_COMETCHAT_INIT_COMPLETED:
            return { ...state, cometchatInitCompleted: true };
        default:
            return state;
    }
};
