export const USER_HOME = '/';
export const LOGIN_FORM = '/login';
export const RESET_PASSWORD = '/reset-password';
export const CREATE_ACCOUNT = '/create-account';
export const USER_PROFILE = '/profile';
export const EDIT_PROFILE = '/edit-profile';
export const DASHBOARD = '/dashboard';
export const LOGOUT = '/logout';


// General
export const CUBICLES_MAP = '/cubicles-map';

export const PROCEDURES = '/procedures';
export const PROCEDURE_DETAILS = PROCEDURES + '/details/:procedureId';
export const PROCEDURE_DETAILS_FROM_EXERCISE = PROCEDURES + '/details/:procedureId/exercise/:exerciseId';

export const CREATE_PROCEDURE = PROCEDURES + '/create';
export const CREATE_PROCEDURE_FROM_EXERCISE = PROCEDURES + '/create/exercise/:exerciseId';

export const DELETE_PROCEDURE = PROCEDURES + '/delete/:procedureId';

export const EXERCISES = '/exercises';
export const EXERCISES_DETAILS = EXERCISES + '/details/:exerciseId';
export const EXERCISES_PROCEDURES = EXERCISES + '/procedures/:exerciseId';
export const CREATE_EXERCISE = EXERCISES + '/create'
export const DELETE_EXERCISE = EXERCISES + '/delete/:exerciseId'


// Pages
export const PAGES = '/pages';
export const PAGES_ABOUT = PAGES + '/about';
export const PAGES_PRIVACY = PAGES + '/privacy';
export const PAGES_TERMS = PAGES + '/terms';
export const PAGES_HELP = PAGES + '/help';
export const PAGES_CONTACT = PAGES + '/contact';

export const PAGES_NOT_AUTHORIZED = PAGES + '/not-authorized';

// admin routes
export const ADMIN_INVOICES = '/admin/invoices';

// Student
export const STUDENT = '/student';
export const STUDENT_HOME = STUDENT + '/home';
export const STUDENT_MY_ACCOUNT = STUDENT + '/my-account';


// Faculty
export const FACULTY = '/faculty';
export const FACULTY_HOME = FACULTY + '/home';
export const FACULTY_MY_ACCOUNT = FACULTY + '/my-account';
export const FACULTY_GRADING = FACULTY + '/grading/:cubicleLogId';
export const FACULTY_CLASSES = FACULTY + '/classes';
export const FACULTY_CLASSES_CREATE = FACULTY_CLASSES + '/create';
export const FACULTY_CLASSES_UPDATE = FACULTY_CLASSES + '/update/:classId';
export const FACULTY_CLASSES_DELETE = FACULTY_CLASSES + '/delete/:classId';

// Client Admin
export const CLIENT_ADMIN = '/university-admin';
export const CLIENT_ADMIN_USERS = CLIENT_ADMIN + '/users';
export const CLIENT_ADMIN_CREATE_USER = CLIENT_ADMIN_USERS + '/create';
export const CLIENT_ADMIN_EDIT_USER = CLIENT_ADMIN_USERS + '/edit/:userId';
export const CLIENT_ADMIN_DELETE_USER = CLIENT_ADMIN_USERS + '/delete/:userId';

export const CLIENT_ADMIN_CUBICLES = CLIENT_ADMIN + '/cubicles';
export const CLIENT_ADMIN_CREATE_CUBICLE = CLIENT_ADMIN_CUBICLES + '/create';
export const CLIENT_ADMIN_EDIT_CUBICLE = CLIENT_ADMIN_CUBICLES + '/edit/:cubicleId';
export const CLIENT_ADMIN_DELETE_CUBICLE = CLIENT_ADMIN_CUBICLES + '/delete/:cubicleId';

export const CLIENT_ADMIN_LOGOFF_TIMES = CLIENT_ADMIN + '/loggoff-times';
export const CLIENT_ADMIN_CREATE_LOGOFF_TIME = CLIENT_ADMIN_LOGOFF_TIMES + '/create';
export const CLIENT_ADMIN_EDIT_LOGOFF_TIME = CLIENT_ADMIN_LOGOFF_TIMES + '/edit/:logoffTimeId';
export const CLIENT_ADMIN_DELETE_LOGOFF_TIME = CLIENT_ADMIN_LOGOFF_TIMES + '/delete/:logoffTimeId';

export const CLIENT_ADMIN_CUBICLE_GROUPS = CLIENT_ADMIN + '/cubicle-groups';
export const CLIENT_ADMIN_CREATE_CUBICLE_GROUP = CLIENT_ADMIN_CUBICLE_GROUPS + '/create';
export const CLIENT_ADMIN_EDIT_CUBICLE_GROUP = CLIENT_ADMIN_CUBICLE_GROUPS + '/edit/:cubicleGroupId';
export const CLIENT_ADMIN_DELETE_CUBICLE_GROUP = CLIENT_ADMIN_CUBICLE_GROUPS + '/delete/:cubicleGroupId';

export const CLIENT_ADMIN_FACULTY_REPORT = FACULTY + '/faculty-report';
export const CLIENT_ADMIN_STUDENT_REPORT = FACULTY + '/student-report';

export const CLIENT_ADMIN_ANNOUNCEMENTS = CLIENT_ADMIN + '/announcements';
export const CREATE_ANNOUNCEMENT = CLIENT_ADMIN_ANNOUNCEMENTS + '/create';
export const EDIT_ANNOUNCEMENT = CLIENT_ADMIN_ANNOUNCEMENTS + '/edit/:announcementId';
export const DELETE_ANNOUNCEMENT = CLIENT_ADMIN_ANNOUNCEMENTS + '/delete/:announcementId';

export const STUDENT_EXERCISES = '/student-exercises/:studentUsername';
export const CREATE_STUDENT_EXERCISE = STUDENT_EXERCISES + '/create';
export const EDIT_STUDENT_EXERCISE = STUDENT_EXERCISES + '/edit/:studentExerciseId';
export const DELETE_STUDENT_EXERCISE = STUDENT_EXERCISES + '/delete/:studentExerciseId';

// Webcasts
export const WEBCASTS = '/webcasts';
export const WEBCASTS_LANDING = WEBCASTS + '/landing';
export const WEBCASTS_WATCH = WEBCASTS + '/watch/:webcastId';
export const WEBCASTS_DETAILS = WEBCASTS + '/details/:webcastId';
export const WEBCASTS_ADMIN = WEBCASTS + '/admin';
export const WEBCASTS_ADMIN_CREATE = WEBCASTS_ADMIN + '/create';
export const WEBCASTS_ADMIN_EDIT = WEBCASTS_ADMIN + '/edit/:webcastId';
export const WEBCASTS_ADMIN_DELETE = WEBCASTS_ADMIN + '/delete/:webcastId';
