import { CLEAR_LIST_ANNOUNCEMENTS, GET_ADMIN_LIST_ANNOUNCEMENTS_SUCCESS, GET_LIST_ANNOUNCEMENTS_SUCCESS } from '../actions/announcements.actions';

export const ANNOUNCEMENT_SEVERITY_INFO = 'info';
export const ANNOUNCEMENT_SEVERITY_WARNING = 'warning';
export const ANNOUNCEMENT_SEVERITY_DANGER = 'danger';

export const ANNOUNCEMENT_SEVERITY_UI_NAMES = {
    [ANNOUNCEMENT_SEVERITY_INFO]: 'Info',
    [ANNOUNCEMENT_SEVERITY_WARNING]: 'Warning',
    [ANNOUNCEMENT_SEVERITY_DANGER]: 'Alert',
};

const initialAnnouncementsState = {
    announcementList: [],
    next: null,
    previous: null,
    count: 0
}

export const AnnouncementReducer = (state = initialAnnouncementsState, action) => {
    switch (action.type) {
        case GET_LIST_ANNOUNCEMENTS_SUCCESS:
            return {
                ...state,
                count: action.payload.count,
                next: action.payload.next,
                previous: action.payload.previous,
                announcementList: action.payload.results
            }
        case CLEAR_LIST_ANNOUNCEMENTS:
            return {
                ...initialAnnouncementsState
            }
        default:
            return state;
    }
}

const initialAdminAnnouncementsState = {
    adminAnnouncementList: [],
    next: null,
    previous: null,
    count: 0
}

export const AdminAnnouncementReducer = (state = initialAdminAnnouncementsState, action) => {
    switch (action.type) {
        case GET_ADMIN_LIST_ANNOUNCEMENTS_SUCCESS:
            return {
                ...state,
                count: action.payload.count,
                next: action.payload.next,
                previous: action.payload.previous,
                adminAnnouncementList: action.payload.results
            }
        default:
            return state;
    }
}