import React, { useEffect } from "react";
import { Container, Row, Col } from 'reactstrap';
import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPen, faTrash } from '@fortawesome/free-solid-svg-icons'
import AppBreadcrumb from '../../../components/ui/AppBreadcrumb';
import { useDispatch, useSelector } from 'react-redux';
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import { Link, useParams } from "react-router-dom";
import { CLIENT_ADMIN_USERS, CREATE_STUDENT_EXERCISE, DELETE_STUDENT_EXERCISE, EDIT_STUDENT_EXERCISE } from '../../../navigation/ROUTE_CONSTANTS';
import { setNavigateTo } from "../../../redux/actions/ui.actions";
import { getAdminListStudentExercises } from "../../../redux/actions/student_exercises.actions";
import { customStyles } from '../../../theme/assets/dataTableCustomStyles';
import { getListExercises } from "../../../redux/actions/exercises.actions";
import { isoStringToFormatted } from "../../../utils/date_time";
import { STUDENT_EXERCISE_STATUS_UI_NAMES } from "../../../redux/reducers/student_exercises.reducers";

const StudentExercises = () => {
    const { studentUsername } = useParams();

    const adminStudentExercisesList = useSelector((state) => state.adminStudentExercises.adminStudentExercisesList);
    const exercisesMap = useSelector((state) => state.exercises.exercisesMap);

    const dispatch = useDispatch();

    const createStudentExercise = () => {
        dispatch(setNavigateTo(CREATE_STUDENT_EXERCISE.replace(':studentUsername', studentUsername)));
    }

    useEffect(() => {
        document.title = process.env.REACT_APP_SITE_TITLE + ' - Student Exercises';
    });

    useEffect(() => {
        dispatch(getListExercises());
        dispatch(getAdminListStudentExercises({ studentUsername }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const columns = [
        {
            name: 'Exercise',
            selector: row => exercisesMap[row.exercise_id],
            sortable: true,
        },
        {
            name: 'Due Date',
            center: true,
            selector: row => isoStringToFormatted(row.due_date),
            sortable: true,
        },
        {
            name: 'Started',
            selector: row => row.started_at ? isoStringToFormatted(row.started_at) : '',
            sortable: true,
        },
        {
            name: 'Grade',
            center: true,
            selector: row => row.procedure_grade,
            sortable: true,
        },
        {
            name: 'Status',
            center: true,
            selector: row => STUDENT_EXERCISE_STATUS_UI_NAMES[row.status],
        },
        {
            name: 'Actions',
            center: true,
            cell: (row) => (<>
                <Link
                    className='text-dark btn btn-link btn-light'
                    to={EDIT_STUDENT_EXERCISE.replace(':studentExerciseId', row.id).replace(':studentUsername', studentUsername)}
                >
                    <FontAwesomeIcon icon={faPen} title="Edit" />
                </Link>
                <Link
                    className='text-dark btn btn-link btn-light'
                    to={DELETE_STUDENT_EXERCISE.replace(':studentExerciseId', row.id).replace(':studentUsername', studentUsername)}
                >
                    <FontAwesomeIcon icon={faTrash} title="Delete" />
                </Link>
            </>),
        }
    ];

    return (
        <Container className="min-vh-72">
            <Row>
                <Col>
                    <AppBreadcrumb headText={studentUsername + ' Exercises'} crumbList={[
                        { linkTo: CLIENT_ADMIN_USERS, label: 'Users' }
                    ]} />
                </Col>
            </Row>
            <Row>
                <Col>
                </Col>
            </Row>
            <Row className='pb-1 mb-2 mt-2'>
                <Col md={6}>
                    <h3 className='mb-4 mt-2'>Exercises of Student: {studentUsername}</h3>
                </Col>
                <Col md={{ offset: 3, size: 3 }}>
                    <Button color={'success'} onClick={createStudentExercise} block>
                        Add Exercise
                    </Button>
                </Col>
            </Row>
            <Row className='mb-5'>
                <Col>
                    {adminStudentExercisesList && adminStudentExercisesList.length === 0 ? (
                        <div className="alert alert-primary mt-2">
                            Empty list
                        </div>
                    ) : (
                        <DataTableExtensions filter={true} export={false} print={false} filterPlaceholder="Search student exercise"
                            columns={columns}
                            data={adminStudentExercisesList} >
                            <DataTable
                                columns={columns}
                                data={adminStudentExercisesList}
                                noHeader
                                pagination
                                highlightOnHover
                                customStyles={customStyles}
                            />
                        </DataTableExtensions>
                    )}
                </Col >
            </Row>
        </Container>
    )
};

export default StudentExercises;