import { Button, Col, Row } from 'reactstrap';
import { useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark, faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import { hideAnnouncement } from '../../redux/actions/announcements.actions';
import { ANNOUNCEMENT_SEVERITY_DANGER, ANNOUNCEMENT_SEVERITY_INFO, ANNOUNCEMENT_SEVERITY_UI_NAMES, ANNOUNCEMENT_SEVERITY_WARNING } from '../../redux/reducers/announcements.reducers';

const mapSeverityTypeToColor = {
    [ANNOUNCEMENT_SEVERITY_INFO]: 'text-success',
    [ANNOUNCEMENT_SEVERITY_WARNING]: 'text-orange',
    [ANNOUNCEMENT_SEVERITY_DANGER]: 'text-danger',
}

const Announcement = ({ announcement }) => {
    const dispatch = useDispatch();

    const handleCloseAnnouncement = () => {
        dispatch(hideAnnouncement(announcement.id));
    }

    return (
        <Row>
            <Col>
                <div className='position-relative w-100 p-4 d-flex background-orange border-orange'>
                    <FontAwesomeIcon className={'my-auto me-3 ' + mapSeverityTypeToColor[announcement.announcement.severity]} size='3x' icon={faTriangleExclamation} title={ANNOUNCEMENT_SEVERITY_UI_NAMES[announcement.announcement.severity]} />
                    <div>
                        <Button color='transparent' onClick={handleCloseAnnouncement} className='position-absolute' style={{ top: 5, right: 0 }}>
                            <FontAwesomeIcon icon={faXmark} title="Close" />
                        </Button>
                        <div className='fw-bold'>{announcement.announcement.title}</div>
                        <div>{announcement.announcement.message}</div>
                    </div>
                </div>
            </Col>
        </Row>
    );
}

export default Announcement;