import axios from "axios";
import { LOGIN_FORM } from "../navigation/ROUTE_CONSTANTS";
import { setToast } from "../redux/actions/ui.actions";
import { TOAST_SEVERITY_ERROR } from "../redux/reducers/ui.reducer";
import { TOKEN_REFRESH } from "../services/API_CONSTANTS";

export async function addSessionInterceptor(config) {
    try {
        // Return original config if skip interceptor flag is present
        if ('skipAddSessionInterceptor' in config && config.skipAddSessionInterceptor) {
            return config;
        }

        const token = localStorage.getItem('ca-access')
        if (token) {
            config.headers.Authorization = 'Bearer ' + token;
        }
        return config
    } catch (e) {
        return config
    }
}

let isAlreadyFetchingAccessToken = false;
let subscribers = [];

function onAccessTokenFetched(access_token) {
    subscribers.forEach(callback => callback(access_token));
    subscribers = [];
}

function addSubscriber(callback) {
    subscribers.push(callback);
}

export async function invalidTokenInterceptor(error) {
    // Property appData must be set only on first declared interceptor !
    error.appData = {
        redirectToLogin: false,
        setToast: null
    };

    if (error.response && error.response.status === 401 && error.response.data.code === 'token_not_valid') {
        try {
            const { response: errorResponse } = error;
            const refreshToken = localStorage.getItem('ca-refresh');

            if (!refreshToken) {
                error.appData.redirectToLogin = true;
                error.appData.setToast = {
                    severity: TOAST_SEVERITY_ERROR,
                    summary: 'Session expired',
                    detail: 'Your session has expired. Please login again.',
                }
                return Promise.reject(error);
            }

            const retryOriginalRequest = new Promise(resolve => {
                addSubscriber(access_token => {
                    errorResponse.config.headers.Authorization = 'Bearer ' + access_token;
                    resolve(axios(errorResponse.config));
                });
            });

            if (!isAlreadyFetchingAccessToken) {
                isAlreadyFetchingAccessToken = true;
                const axiosInstance = axios.create({
                    baseURL: process.env.REACT_APP_DEFAULT_ENDPOINT,
                });

                let responseData = null;
                let responseStatus = null;
                try {
                    const response = await axiosInstance({
                        method: 'post',
                        url: TOKEN_REFRESH,
                        data: { refresh: refreshToken }
                    });
                    responseData = response.data;
                    responseStatus = response.status;
                } catch (e) {
                    responseStatus = e.response?.status;
                }

                if (responseStatus === 401 && window.location.pathname !== LOGIN_FORM) {
                    error.appData.redirectToLogin = true;
                    error.appData.setToast = {
                        severity: TOAST_SEVERITY_ERROR,
                        summary: 'Session expired',
                        detail: 'Your session has expired. Please login again.',
                    }
                    return Promise.reject(error);
                }

                if (!responseData) {
                    error.appData.redirectToLogin = true;
                    error.appData.setToast = {
                        severity: TOAST_SEVERITY_ERROR,
                        summary: 'Session expired',
                        detail: 'Your session has expired. Please login again or contact support.',
                    }
                    return Promise.reject(error);
                }

                const newAccessToken = responseData.access;
                localStorage.setItem('ca-access', newAccessToken);
                isAlreadyFetchingAccessToken = false;
                onAccessTokenFetched(newAccessToken);
            }

            return retryOriginalRequest;

        } catch (err) {
            error.appData.setToast = {
                severity: TOAST_SEVERITY_ERROR,
                summary: 'Authentication error',
                detail: JSON.stringify(err),
            }
            return Promise.reject(error);
        }
    }

    return Promise.reject(error);
}

export function networkErrorInterceptor(error) {
    if (!!error.isAxiosError && !error.response) { // Network Error
        error.appData.setToast = {
            severity: TOAST_SEVERITY_ERROR,
            summary: 'Connection error',
            detail: 'Please check your internet connection and try again.',
            life: 8000,
        }
    }

    return Promise.reject(error);
}

export function otherErrorsInterceptor(error) {
    // Catch any error not handled by previous declared interceptors
    if (!error.appData.setToast) {
        let errorMessage = '';
        for (const [key, value] of Object.entries(error.response.data)) {
            // Remove key from error message when it is not related to a field
            if (key !== 'non_field_errors') {
                errorMessage += key + ': ';
            }

            if (Array.isArray(value)) {
                errorMessage += value.join('\n');
            } else {
                errorMessage += value + '\n';
            }
        }

        let summary = 'Error From Server';
        if (error.response && error.response.status === 400) {
            summary = 'Validation message';
        }

        error.appData.setToast = {
            severity: TOAST_SEVERITY_ERROR,
            summary,
            detail: errorMessage,
            life: 5000
        }
    }

    return Promise.reject(error);
}

export function handleAppDataInterceptor(error, store) {
    if (error.appData.setToast) {
        store.dispatch(setToast(error.appData.setToast));
    }

    if (error.appData.redirectToLogin) {
        localStorage.clear();
        window.location.href = LOGIN_FORM;
    }

    return Promise.reject(error);
}