import { CREATE_USER_SUCCESS, GET_FACULTY_LIST_SUCCESS, GET_LIST_STUDENTS_SUCCESS, GET_USER_SUCCESS, RESET_USER_STATE, RETRIEVE_FACULTIES_LOGGED_IN_SUCCESS, SET_WEBSOCKET_URL } from "../actions/users.actions";
import { USER_ROLE_CLIENT_ADMIN, USER_ROLE_FACULTY, USER_ROLE_STUDENT } from "./auth.reducer";

const initialUsersState = {
    emailRegister: null,
    userLogged: null,
    isRoleClientAdmin: false,
    isRoleFaculty: false,
    isRoleStudent: false,
    facultyList: [],
    websocketUrl: null,
    studentsList: [],
};

export const UsersReducer = (state = initialUsersState, action) => {
    switch (action.type) {
        case CREATE_USER_SUCCESS:
            return { ...state, emailRegister: action.payload };
        case GET_USER_SUCCESS:
            return {
                ...state,
                userLogged: action.payload,
                isRoleClientAdmin: action.payload.role === USER_ROLE_CLIENT_ADMIN,
                isRoleFaculty: action.payload.role === USER_ROLE_FACULTY,
                isRoleStudent: action.payload.role === USER_ROLE_STUDENT,
            };
        case GET_FACULTY_LIST_SUCCESS:
            return { ...state, facultyList: action.payload };
        case GET_LIST_STUDENTS_SUCCESS:
            return { ...state, studentsList: action.payload };
        case SET_WEBSOCKET_URL:
            return { ...state, websocketUrl: action.payload };
        case RESET_USER_STATE:
            return initialUsersState;
        default:
            return state;
    }
}


const initialFacultiesLoggedInState = [];

export const FacultiesLoggedInReducer = (state = initialFacultiesLoggedInState, action) => {
    switch (action.type) {
        case RETRIEVE_FACULTIES_LOGGED_IN_SUCCESS:
            return action.payload;
        default:
            return state;
    }
}
