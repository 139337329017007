import { useEffect, useState } from 'react';
import { Container, Row, Col } from 'reactstrap';
import Search from '../../components/ui/Search';
import AppDatePicker from '../../components/ui/AppDateRangePicker';
import AppBreadcrumb from '../../components/ui/AppBreadcrumb';
import { useDispatch, useSelector } from 'react-redux';
import { getBasicStudentReport } from '../../redux/actions/cubicle_logs.actions';
import { customStyles } from '../../theme/assets/dataTableCustomStyles';
import DataTable from 'react-data-table-component';
import { FORMAT_DATETIME_SHORT, isoStringToFormatted, secondsToDurationFormatted } from '../../utils/date_time';
import { CUBICLE_STATUS_UI_NAMES } from '../../redux/reducers/my_cubicle.reducer';

const AccountStudent = () => {
    const dispatch = useDispatch();
    const userLogged = useSelector(state => state.users.userLogged);
    const reportRows = useSelector(state => state.cubicleLogs.detailedStudentReport.reportRows);
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);

    useEffect(() => {
        document.title = process.env.REACT_APP_SITE_TITLE + ' - My Account';
    });

    useEffect(() => {
        if (fromDate && toDate) {
            let paginationParams = null;
            let fromDateEpoch = fromDate.getTime();
            let toDateEpoch = toDate.getTime();
            dispatch(getBasicStudentReport({ fromDateEpoch, toDateEpoch, paginationParams }));
        }
    }, [dispatch, fromDate, toDate]);

    const handleDateRangeChange = (fromDate, toDate) => {
        setFromDate(fromDate);
        setToDate(toDate);
    }

    const columns = [
        {
            name: 'Start',
            selector: row => row.createdAt,
            cell: row => isoStringToFormatted(row.createdAt, FORMAT_DATETIME_SHORT),
            sortable: true,
        },
        {
            name: 'Request type',
            selector: row => CUBICLE_STATUS_UI_NAMES[row.cubicleStatus],
            sortable: true,
        },
        {
            name: 'Faculty',
            selector: row => row.acceptedByFacultyFullName,
            sortable: true,
        },
        {
            name: 'Procedure(s) completed',
            sortable: false,
            cell: row => row.proceduresCompleted.join(' / '),
        },
        {
            name: 'Duration',
            selector: row => secondsToDurationFormatted(row.totalSeconds),
            right: true,
        },
    ];

    return (
        <Container className='px-4 px-md-auto min-vh-72'>
            <Row>
                <Col>
                    <AppBreadcrumb headText="My account" />
                </Col>
            </Row>
            <Row>
                <Col>
                    <h3 className='mb-4 mt-2'>My account: {userLogged?.first_name} {userLogged?.last_name}</h3>
                </Col>
            </Row>
            <Row className='pb-2 mb-2 mt-2'>
                <Col md={3} className='mb-2 mb-md-0 pb-1 pb-md-0'>
                    <AppDatePicker dateRangeChanged={handleDateRangeChange} />
                </Col>
                <Col md={3} className='mb-2 mb-md-0 pb-1 pb-md-0'>
                    <Search />
                </Col>
            </Row>

            <Row className='mb-5'>
                <Col>
                    {reportRows && reportRows.length === 0 ? (
                        <div className="alert alert-primary mt-2">
                            No rows
                        </div>
                    ) : (
                        <DataTable
                            columns={columns}
                            data={reportRows}
                            noHeader
                            pagination
                            highlightOnHover
                            customStyles={customStyles}
                        />
                    )}
                </Col >
            </Row>

        </Container>
    )
};

export default AccountStudent;
