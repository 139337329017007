import { GET_MY_CUBICLE_SUCCESS, RESET_MY_CUBICLE, SET_MY_CUBICLE_DATA } from "../actions/my_cubicle.actions";

export const CUBICLE_STATUS_UNOCCUPIED = 'unoccupied'
export const CUBICLE_STATUS_OCCUPIED = 'occupied'
export const CUBICLE_STATUS_HELP = 'help'
export const CUBICLE_STATUS_SPECIAL_REQUEST = 'special_request'
export const CUBICLE_STATUS_START_CHECK = 'start_check'
export const CUBICLE_STATUS_CHECKOUT = 'checkout'

export const REQUEST_STATUS_ON_QUEUE = 'on_queue'
export const REQUEST_STATUS_UNDER_WAY = 'under_way'
export const REQUEST_STATUS_COMPLETED = 'completed'
export const REQUEST_STATUS_CANCELLED = 'cancelled'

export const CUBICLE_STATUS_UI_NAMES = {
    [CUBICLE_STATUS_UNOCCUPIED]: 'Unoccupied',
    [CUBICLE_STATUS_OCCUPIED]: 'Occupied',
    [CUBICLE_STATUS_HELP]: 'Help',
    [CUBICLE_STATUS_SPECIAL_REQUEST]: 'Special Request',
    [CUBICLE_STATUS_START_CHECK]: 'Start Check',
    [CUBICLE_STATUS_CHECKOUT]: 'Treatment complete',
};

export const REQUEST_STATUS_UI_NAMES = {
    [REQUEST_STATUS_ON_QUEUE]: 'On Queue',
    [REQUEST_STATUS_UNDER_WAY]: 'Under Way',
    [REQUEST_STATUS_COMPLETED]: 'Completed',
    [REQUEST_STATUS_CANCELLED]: 'Cancelled'
};

export const STATUS_ELIGIBLE_FOR_REQUEST_LOG = [
    CUBICLE_STATUS_HELP,
    CUBICLE_STATUS_SPECIAL_REQUEST,
    CUBICLE_STATUS_START_CHECK,
    CUBICLE_STATUS_CHECKOUT
];

const initialMyCubicleState = {
    id: null,
    name: null,
    last_cubicle_log: null,
    cubicle_status: null,
    request_status: null,
};

export const MyCubicleReducer = (state = initialMyCubicleState, action) => {
    switch (action.type) {
        case GET_MY_CUBICLE_SUCCESS:
            return action.payload;
        case SET_MY_CUBICLE_DATA:
            return {
                ...state,
                ...action.payload
            }
        case RESET_MY_CUBICLE:
            return initialMyCubicleState;
        default:
            return state;
    }
}
