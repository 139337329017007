import { ErrorMessage, Field, Form, Formik } from 'formik';
import { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Button, Col, Container, FormGroup, Input, Label, Row } from 'reactstrap';
import * as Yup from 'yup';
import AttachmentLinks from '../../components/general/AttachmentLinks';
import AppBreadcrumb from '../../components/ui/AppBreadcrumb';
import { FACULTY_HOME } from '../../navigation/ROUTE_CONSTANTS';
import { getCubicleLog } from '../../redux/actions/cubicle_logs.actions';
import { createRequestLog } from '../../redux/actions/request_logs.actions';
import { setFormSubmittedSuccess, setNavigateTo } from '../../redux/actions/ui.actions';
import { REQUEST_STATUS_COMPLETED } from '../../redux/reducers/my_cubicle.reducer';
import Cubicle, { CUBICLE_VARIANT_HEADER } from '../../theme/components/Cubicle';
import { buildProcedureLabel } from '../../utils';

const FacultyGrading = () => {
    const dispatch = useDispatch();
    const { cubicleLogId } = useParams();
    const selectedCubicleLog = useSelector(state => state.cubicleLogs.selectedCubicleLog);
    const formSubmittedSuccess = useSelector(state => state.ui.formSubmittedSuccess);
    const [formSubmitStarted, setFormSubmitStarted] = useState(false);

    useEffect(() => {
        dispatch(getCubicleLog(cubicleLogId));
        dispatch(setFormSubmittedSuccess(false));
    }, [cubicleLogId, dispatch]);

    useEffect(() => {
        if (formSubmittedSuccess && formSubmitStarted) {
            dispatch(setNavigateTo(FACULTY_HOME));
        }
    }, [dispatch, formSubmitStarted, formSubmittedSuccess]);

    const handleSaveGrade = (values) => {
        setFormSubmitStarted(true);
        let requestLogData = {
            cubicle_log: cubicleLogId,
            request_status: REQUEST_STATUS_COMPLETED,
            faculty_comments: values.faculty_comments,
            procedure_grade: values.procedure_grade,
        };
        dispatch(createRequestLog(requestLogData));
    }

    const handleReset = (e) => {
        dispatch(setNavigateTo(FACULTY_HOME));
    };

    return (
        <Container className='mb-5 pb-5'>
            {selectedCubicleLog && (
                <Fragment>
                    <Row>
                        <Col>
                            <AppBreadcrumb headText={`Check ${selectedCubicleLog.cubicle.name}`} />
                        </Col>
                    </Row>
                    <Row className='border-bottom-grey pb-3'>
                        <div className='d-flex align-items-center h-100'>
                            <div className="my-2 w-fit me-3">
                                <Cubicle cubicleData={selectedCubicleLog.cubicle} cubicleLogData={null} variant={CUBICLE_VARIANT_HEADER} />
                            </div>
                            <div className="text-black fw-bold">
                                Check:&nbsp;
                                {selectedCubicleLog.procedures.map(procedure => buildProcedureLabel(procedure, selectedCubicleLog.other_procedure)).join(', ')}
                            </div>
                        </div>
                    </Row>
                    <Row className='mt-4 border-bottom-grey pb-3'>
                        <Col md={6} xs={12}>
                            <div className="background-light-grey p-3 border-bottom-grey fw-semibold fs-5">
                                Supporting files
                            </div>
                            <div className="my-3 mx-2 d-flex">
                                <AttachmentLinks attachments={selectedCubicleLog.attachments} />
                            </div>
                        </Col>
                        <Col md={6} xs={12}>
                            <div className="background-light-grey p-3 border-bottom-grey fw-semibold fs-5">
                                Comments
                            </div>
                            <div className="my-3 mx-2 d-flex ">
                                <pre>
                                    {selectedCubicleLog.student_comments}
                                </pre>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6} className='m-auto'>
                            <div className='fs-5 mt-4 fw-bold w-100 text-center'>Check outcome</div>
                            <Formik
                                enableReinitialize={true}
                                initialValues={{
                                    faculty_comments: '',
                                    procedure_grade: ''
                                }}
                                validationSchema={Yup.object({
                                    faculty_comments: Yup.string()
                                        .nullable(),
                                    procedure_grade: Yup.string()
                                        .max(2, '2 characters max')
                                        .required('Please enter the grade'),
                                })}
                                onSubmit={(values) => {
                                    handleSaveGrade(values)
                                }}
                            >
                                {() => (
                                    <Form>
                                        <Row>
                                            <Col>
                                                <FormGroup>
                                                    <Label for="faculty_comments">Faculty comments</Label>
                                                    <Field id="faculty_comments" name="faculty_comments" type="textarea" rows={8} as={Input} />
                                                    <ErrorMessage component="div" className="text-danger" name="faculty_comments" />
                                                </FormGroup>

                                                <FormGroup>
                                                    <Label for="procedure_grade">Procedure grade</Label>
                                                    <Field id="procedure_grade" type="text" name="procedure_grade" as={Input} />
                                                    <ErrorMessage component="div" className="text-danger" name="procedure_grade" />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col className='text-center'>
                                                <Button color='success' className='mt-2 px-5 w-100 fs-5' type='submit'>
                                                    Complete check
                                                </Button>
                                                <Button color='outline-success' className='mt-2 px-5 w-100 fs-5' type='reset' onClick={handleReset}>
                                                    Student continue work
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Form>
                                )}
                            </Formik>
                        </Col>
                    </Row>
                </Fragment>
            )}
        </Container>
    )
}

export default FacultyGrading;