import axios from 'axios';
import { buildPaginationUrlParams } from '../utils/index';
import { API_STUDENT_EXERCISES, API_STUDENT_EXERCISES_DASHBOARD, API_STUDENT_EXERCISES_IN_PROGRESS } from './API_CONSTANTS';

export const getStudentExercises = (paginationParams = {}) => {
    paginationParams = {
        page: 1,
        limit: 999,
        studentUsername: '',
        ...paginationParams,
    };

    const url = API_STUDENT_EXERCISES + `/${paginationParams.studentUsername}` + buildPaginationUrlParams(paginationParams);

    return new Promise((resolve, reject) => {
        try {
            axios.get(url)
                .then((res) => {
                    console.log('Student exercises loaded: ', res);
                    resolve(res.data);
                })
                .catch((err) => {
                    console.log('Get student exercises error: ', err);
                    reject('Get student exercises error');
                });
        } catch (error) {
            console.error('Get student exercises error: ', error);
            reject('Get student exercises error');
        }
    });
};

export const getStudentExercisesDashboard = () => {
    const url = API_STUDENT_EXERCISES_DASHBOARD;

    return new Promise((resolve, reject) => {
        try {
            axios.get(url)
                .then((res) => {
                    console.log('Student exercises dashboard loaded: ', res);
                    resolve(res.data);
                })
                .catch((err) => {
                    console.log('Get student exercises dashboard error: ', err);
                    reject('Get student exercises dashboard error');
                });
        } catch (error) {
            console.error('Get student exercises dashboard error: ', error);
            reject('Get student exercises dashboard error');
        }
    });
};

export const getOneStudentExercise = ({ studentUsername, studentExerciseId }) => {
    const url = API_STUDENT_EXERCISES + `/${studentUsername}/${studentExerciseId}`;

    return new Promise((resolve, reject) => {
        try {
            axios.get(url)
                .then((res) => {
                    console.log('Student exercise loaded: ', res);
                    resolve(res.data);
                })
                .catch((err) => {
                    console.log('Get one student exercise error: ', err);
                    reject('Get one student exercise error');
                });
        } catch (error) {
            console.error('Get one student exercise error: ', error);
            reject('Get one student exercise error');
        }
    });
}

export const createStudentExercise = (studentExercise) => {
    const url = API_STUDENT_EXERCISES + `/${studentExercise.student_username}`;

    return new Promise((resolve, reject) => {
        try {
            axios.post(url, studentExercise)
                .then((res) => {
                    console.log('Student exercise created: ', res);
                    resolve(res.data);
                })
                .catch((err) => {
                    console.log('Create student exercise error: ', err);
                    reject('Create student exercise error');
                });
        } catch (error) {
            console.error('Create student exercise error: ', error);
            reject('Create student exercise error');
        }
    });
}

export const updateStudentExercise = (studentExercise) => {
    const url = API_STUDENT_EXERCISES + `/${studentExercise.student_username}/${studentExercise.id}`;

    return new Promise((resolve, reject) => {
        try {
            axios.put(url, studentExercise)
                .then((res) => {
                    console.log('Student exercise updated: ', res);
                    resolve(res.data);
                })
                .catch((err) => {
                    console.log('Update student exercise error: ', err);
                    reject('Update student exercise error');
                });
        } catch (error) {
            console.error('Update student exercise error: ', error);
            reject('Update student exercise error');
        }
    });
}

export const removeStudentExercise = (studentExercise) => {
    const url = API_STUDENT_EXERCISES + `/${studentExercise.student_username}/${studentExercise.id}`;

    return new Promise((resolve, reject) => {
        try {
            axios.delete(url)
                .then((res) => {
                    console.log('Student exercise removed: ', res);
                    resolve(res);
                })
                .catch((err) => {
                    console.log('Remove student exercise error: ', err);
                    reject('Remove student exercise error');
                });
        } catch (error) {
            console.error('Remove student exercise error: ', error);
            reject('Remove student exercise error');
        }
    });
}

export const moveToInProgress = (studentExerciseId) => {
    const url = API_STUDENT_EXERCISES_IN_PROGRESS + '/' + studentExerciseId;

    return new Promise((resolve, reject) => {
        try {
            axios.patch(url)
                .then((res) => {
                    console.log('Student exercise moved to in progress: ', res);
                    resolve(res.data);
                })
                .catch((err) => {
                    console.log('Student exercise to in progress error: ', err);
                    reject('Student exercise to in progress error');
                });
        } catch (error) {
            console.error('Student exercise to in progress error: ', error);
            reject('Student exercise to in progress error');
        }
    });
}
