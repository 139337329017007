import { ADD_STUDENT_TO_CLASS, GET_CLASS_SUCCESS, GET_LIST_CLASSES_SUCCESS, REMOVE_STUDENT_FROM_CLASS, RESET_CLASS, UPDATE_STUDENT_IN_CLASS } from "../actions/classes.actions";

export const STUDENT_CLASS_ACTION_ADD = 'student_class_action_add';
export const STUDENT_CLASS_ACTION_REMOVE = 'student_class_action_remove';
export const STUDENT_CLASS_ACTION_UPDATE = 'student_class_action_update';

const mapTypeToAction = {
    [ADD_STUDENT_TO_CLASS]: STUDENT_CLASS_ACTION_ADD,
    [REMOVE_STUDENT_FROM_CLASS]: STUDENT_CLASS_ACTION_REMOVE,
    [UPDATE_STUDENT_IN_CLASS]: STUDENT_CLASS_ACTION_UPDATE,
};

const initialClassesState = {
    classesList: [],
    next: null,
    previous: null,
    count: 0,
    currentFacultyClass: null,
    classStudentDataTable: [],
};

export const FacultyClassesReducer = (state = initialClassesState, action) => {
    switch (action.type) {
        case GET_LIST_CLASSES_SUCCESS:
            return {
                ...state,
                classesList: action.payload.results,
                next: action.payload.next,
                previous: action.payload.previous,
                count: action.payload.count
            }
        case GET_CLASS_SUCCESS:
            return {
                ...state,
                currentFacultyClass: action.payload,
                classStudentDataTable: action.payload.students
            }
        case RESET_CLASS:
            return {
                ...state,
                currentFacultyClass: null,
                classStudentDataTable: [],
            }
        case ADD_STUDENT_TO_CLASS:
        case REMOVE_STUDENT_FROM_CLASS:
        case UPDATE_STUDENT_IN_CLASS:
            let classStudent = {...action.payload};
            let classStudentDataTable = [...state.classStudentDataTable];

            // Assign action to perform according to action type
            classStudent['action'] = mapTypeToAction[action.type];

            let classStudentPosition = classStudentDataTable.findIndex(student => student.student === classStudent.student);
            if (classStudentPosition === -1) {
                classStudentDataTable.push({
                    class_grade: '',
                    ...classStudent
                });
            } else {
                classStudentDataTable[classStudentPosition] = {
                    ...classStudentDataTable[classStudentPosition],
                    ...classStudent
                };
            }

            return {
                ...state,
                classStudentDataTable: [...classStudentDataTable]
            }
        default:
            return state;
    }
}
