import { faCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { Badge, Button, Card, CardImg, CardText, Col, Row } from 'reactstrap';
import { WEBCAST_THUMBNAIL_OPTION_EXTERNAL, WEBCAST_THUMBNAIL_OPTION_UPLOADED } from '../../redux/reducers/webcasts.reducer';
import { FORMAT_DATETIME_MED_WITH_WEEKDAY, isoStringToFormatted } from '../../utils/date_time';
import WebcastItem from '../webcasts/WebcastItem';
import ImageS3 from './ImageS3';

const WebcastsCarousel = ({ webcasts = [] }) => {

    return (
        <Card className='rounded-0 mb-4'>
            <div id='webcasts-carousel-controls' className='carousel carousel-dark slide' data-bs-ride='carousel'>

                <div className='carousel-inner' style={{ minHeight: '23rem' }}>
                    {webcasts && webcasts.length > 0 && webcasts.map((webcastData, index) => (
                        <div className={'carousel-item ' + (index === 0 ? ' active' : '')} key={webcastData.id}>
                            <div className='d-block w-100'>
                                <Row className='g-0 m-3'>
                                    <Col>
                                        <Badge color="primary" className="rounded-5 px-4 py-2 mb-3">UPCOMING</Badge>

                                        <WebcastItem webcastData={webcastData} />

                                        <CardText className='fw-bold my-4'>
                                            Watch live &nbsp;
                                            {webcastData.event_time ? isoStringToFormatted(webcastData.event_time, FORMAT_DATETIME_MED_WITH_WEEKDAY) : ''}
                                        </CardText>

                                        <Link to='dummy' className='btn btn-outline-success btn-sm px-5 py-2'>Send me a calendar invite</Link>
                                    </Col>
                                    <Col>
                                        {webcastData.thumbnail_option === WEBCAST_THUMBNAIL_OPTION_EXTERNAL && webcastData.external_video_thumbnail &&
                                            <CardImg src={webcastData.external_video_thumbnail} className='img-fluid rounded-0' alt={webcastData.title} style={{ maxHeight: '20rem', 'objectFit': 'cover' }} />
                                        }
                                        {webcastData.thumbnail_option === WEBCAST_THUMBNAIL_OPTION_UPLOADED &&
                                            <ImageS3 attachment={webcastData.cover_image} imgAlt={webcastData.title} style={{ maxHeight: '20rem', 'objectFit': 'cover', width: '100%' }} />
                                        }
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    ))}
                </div>

                <Row>
                    <Col className='text-center'>
                        {webcasts && webcasts.length > 0 && webcasts.map((webcastData, index) => (
                            <Button size='sm' color='transparent' data-bs-target='#webcasts-carousel-controls'
                                data-bs-slide-to={index.toString()}
                                aria-label={webcastData.title}
                            >
                                <FontAwesomeIcon className='text-secondary' icon={faCircle} title={webcastData.title} />
                            </Button>
                        ))}
                    </Col>
                </Row>
            </div>
        </Card>
    )
};

export default WebcastsCarousel;
