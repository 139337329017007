import { all } from 'redux-saga/effects';
import adminInvoicesSagas from './effects/admin.invoices.saga';
import adminSettingsSagas from './effects/admin.settings.saga';
import usersByAdminSaga from './effects/admin.users.saga';
import announcementSaga from './effects/announcements.saga';
import authSaga from './effects/auth.saga';
import facultyClassesSaga from './effects/classes.saga';
import clientsSaga from './effects/clients.saga';
import configurationsSaga from './effects/configurations.saga';
import cubiclesSaga from './effects/cubicles.saga';
import cubicleGroupsSaga from './effects/cubicle_groups.saga';
import cubicleLogsSaga from './effects/cubicle_logs.saga';
import exercisesSaga from './effects/exercises.saga';
import filesSaga from './effects/files.saga';
import invoicesSagas from './effects/invoices.saga';
import layoutSaga from './effects/layout.saga';
import logoutTimeSaga from './effects/logoff_times.saga';
import myCubicleSaga from './effects/my_cubicle.saga';
import proceduresSaga from './effects/procedures.saga';
import requestLogsSaga from './effects/request_logs.saga';
import studentExercisesSaga from './effects/student_exercises.saga';
import usersSaga from './effects/users.saga';
import webcastsSaga from './effects/webcasts.saga';

export default function* appSaga() {
    yield all([
        adminInvoicesSagas(),
        adminSettingsSagas(),
        announcementSaga(),
        authSaga(),
        clientsSaga(),
        configurationsSaga(),
        cubicleGroupsSaga(),
        cubicleLogsSaga(),
        cubiclesSaga(),
        exercisesSaga(),
        facultyClassesSaga(),
        filesSaga(),
        invoicesSagas(),
        layoutSaga(),
        logoutTimeSaga(),
        myCubicleSaga(),
        proceduresSaga(),
        requestLogsSaga(),
        studentExercisesSaga(),
        usersByAdminSaga(),
        usersSaga(),
        webcastsSaga(),
    ]);
}
