import { Field, Form, Formik } from 'formik';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { Button, Card, CardBody, Col, Container, FormGroup, Input, Label, Row } from 'reactstrap';
import AppBreadcrumb from '../../../components/ui/AppBreadcrumb';
import SectionTitle from '../../../components/ui/SectionTitle';
import { CREATE_PROCEDURE_FROM_EXERCISE, EXERCISES, EXERCISES_DETAILS } from '../../../navigation/ROUTE_CONSTANTS';
import { getExercise, updateExercise } from '../../../redux/actions/exercises.actions';
import { getProcedures } from "../../../redux/actions/procedures.actions";
import { setFormSubmittedSuccess, setNavigateTo } from '../../../redux/actions/ui.actions';
import { buildExerciseLabel, buildProcedureLabel } from '../../../utils';

const ExerciseProceduresForm = () => {
    const dispatch = useDispatch();
    const { exerciseId } = useParams();
    const selectedExercise = useSelector((state) => state.exercises.selectedExercise);
    const formSubmittedSuccess = useSelector(state => state.ui.formSubmittedSuccess);
    const proceduresList = useSelector((state) => state.procedures.proceduresList);
    const [pageTitle, setPageTitle] = useState('');
    const [formSubmitStarted, setFormSubmitStarted] = useState(false);

    useEffect(() => {
        dispatch(getProcedures());
        dispatch(setFormSubmittedSuccess(false));
        if (exerciseId) {
            dispatch(getExercise(exerciseId));
            setPageTitle('Edit procedures');
        }
    }, [exerciseId, dispatch]);

    useEffect(() => {
        document.title = process.env.REACT_APP_SITE_TITLE + ' - ' + pageTitle;
    }, [pageTitle]);

    useEffect(() => {
        if (formSubmittedSuccess && formSubmitStarted) {
            dispatch(setNavigateTo(EXERCISES_DETAILS.replace(':exerciseId', exerciseId)));
        }
    }, [dispatch, exerciseId, formSubmitStarted, formSubmittedSuccess]);

    const handleSaveExercise = (values) => {
        setFormSubmitStarted(true);
        if (exerciseId) {
            values.id = exerciseId;
            dispatch(updateExercise(values));
        }
    }

    const handleCancel = (e) => {
        e.preventDefault();
        dispatch(setNavigateTo(EXERCISES_DETAILS.replace(':exerciseId', exerciseId)));
    }

    return (
        <Container className='mb-5 pb-5'>
            <Row>
                <Col>
                    <AppBreadcrumb headText={pageTitle} crumbList={[
                        { linkTo: EXERCISES, label: 'Exercises' },
                        { linkTo: EXERCISES_DETAILS.replace(':exerciseId', exerciseId), label: buildExerciseLabel(selectedExercise) }
                    ]}
                    />
                </Col>
            </Row>
            <Formik
                enableReinitialize={true}
                initialValues={{
                    procedures: selectedExercise.procedures || [],
                }}
                onSubmit={handleSaveExercise}
            >
                {({ values, setFieldValue }) => (
                    <Form>
                        <Row className='mb-2'>
                            <Col>
                                <h4 className='mb-4'>{buildExerciseLabel(selectedExercise)}</h4>
                            </Col>
                            <Col className='text-end'>
                                <Button color='success' type='button' outline className='me-3 px-4' size='sm' onClick={handleCancel}>Cancel</Button>
                                <Button color='success' type='submit' className='me-3 px-4' size='sm'>Save procedures</Button>
                            </Col>

                        </Row>
                        <Row>
                            <Col sm={12} md={6}>
                                <SectionTitle title='Procedures'>
                                    <Link className='btn btn-outline-success btn-sm ms-2 px-4' to={
                                        CREATE_PROCEDURE_FROM_EXERCISE.replace(':exerciseId', exerciseId)
                                    }>Add Procedure</Link>
                                </SectionTitle>
                                <Card className='border-0'>
                                    <CardBody className='overflow-auto mx-2 p-0' style={{ maxHeight: '25rem' }}>
                                        {proceduresList && proceduresList.map(procedure =>
                                            <FormGroup key={procedure.id}>
                                                <Label className={'text-green ' + (values.procedures.some((id) => id === procedure.id) ? 'fw-semibold' : '')}>
                                                    <Field type="checkbox" name="procedures" className="me-2" as={Input}
                                                        value={procedure.id}
                                                        checked={values.procedures.includes(procedure.id)}
                                                        onChange={(e) => {
                                                            if (e.target.checked) {
                                                                setFieldValue('procedures', [...values.procedures, procedure.id])
                                                            } else {
                                                                setFieldValue('procedures', values.procedures.filter((id) => id !== procedure.id))
                                                            }
                                                        }}
                                                    />
                                                    {buildProcedureLabel(procedure)}
                                                </Label>
                                            </FormGroup>
                                        )}
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Form>
                )}
            </Formik>
        </Container >
    )
}

export default ExerciseProceduresForm;