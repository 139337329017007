import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Input } from 'reactstrap';
import { Row, Col } from 'reactstrap';
import {
    getAdminListConfigurations,
    updateAdminConfiguration,
} from '../../redux/actions/configurations.actions';
import {
    SESSION_TIME_FACULTY,
    SESSION_TIME_STUDENT,
} from '../../redux/reducers/admin.configurations.reducers';

const CONFIG_UI_NAMES = {
    [SESSION_TIME_STUDENT]: 'Student',
    [SESSION_TIME_FACULTY]: 'Faculty',
};

const ClientAdminSessionTimesTable = () => {
    const dispatch = useDispatch();
    const configurations = useSelector((state) => state.adminConfigurations);
    const [valuesConfigurations, setValuesConfigurations] = useState({});

    const handleValueChange = (e, configKey) => {
        if (e.target.value <= 0) {
            return;
        }
        e.preventDefault();
        let value = +e.target.value * 60;
        dispatch(updateAdminConfiguration({ configKey, value }));
    };

    useEffect(() => {
        dispatch(getAdminListConfigurations(SESSION_TIME_FACULTY));
        dispatch(getAdminListConfigurations(SESSION_TIME_STUDENT));
    }, [dispatch]);

    useEffect(() => {
        setValuesConfigurations(
            Object.entries(configurations).map((x) => {
                return { configKey: x[0], value: x[1] / 60 };
            })
        );
    }, [configurations]);

    return (
        <Fragment>
            <Row className="pb-1 mb-2 mt-2">
                <Col md={3}>
                    <h3 className="mb-4 mt-2">Session Times</h3>
                </Col>
            </Row>
            <Row className="table-header container">
                <Col>Role</Col>
                <Col>Enable</Col>
                <Col>Max session time (hours)</Col>
            </Row>
            {valuesConfigurations && valuesConfigurations.length > 0
                ? valuesConfigurations.map((x) => (
                    <Row className="table-rows container" key={x.configKey}>
                        <Col>{CONFIG_UI_NAMES[x.configKey]}</Col>
                        <Col>
                            <Input
                                type="checkbox"
                                checked={x.value > 0 ? true : false}
                                disabled
                            />
                        </Col>
                        <Col>
                            <Input
                                className='w-50'
                                type="text"
                                key={x.configKey}
                                defaultValue={x.value}
                                onChange={(e) =>
                                    handleValueChange(e, x.configKey)
                                }
                            ></Input>
                        </Col>
                    </Row>
                ))
                : ''}
        </Fragment>
    );
};

export default ClientAdminSessionTimesTable;
