import { createAction } from "@reduxjs/toolkit";

// Classes type
export const CREATE_CLASS = '[CLASS] Class created';
export const GET_CLASS = '[CLASS] Getting class ...';
export const GET_CLASS_SUCCESS = '[CLASS] Get class success';
export const UPDATE_CLASS = '[CLASS] Class updated';
export const DELETE_CLASS = '[CLASS] Class deleted';
export const UPDATE_CLASS_STUDENTS = '[CLASS] Updating class students ...';
export const RESET_CLASS = '[CLASS] Reset class';
export const GET_LIST_CLASSES = '[CLASSES] Getting list classes';
export const GET_LIST_CLASSES_SUCCESS = '[CLASSES] Getting list classes success!';

export const ADD_STUDENT_TO_CLASS = '[CLASS] Adding student to class';
export const REMOVE_STUDENT_FROM_CLASS = '[CLASS] Removing student from class';
export const UPDATE_STUDENT_IN_CLASS = '[CLASS] Updating student in class';

// Classes actions
export const createClass = createAction(CREATE_CLASS);
export const getClass = createAction(GET_CLASS);
export const getClassSuccess = createAction(GET_CLASS_SUCCESS);
export const updateClass = createAction(UPDATE_CLASS);
export const deleteClass = createAction(DELETE_CLASS);
export const updateClassStudents = createAction(UPDATE_CLASS_STUDENTS);
export const resetClass = createAction(RESET_CLASS);
export const getListClasses = createAction(GET_LIST_CLASSES);
export const getListClassesSuccess = createAction(GET_LIST_CLASSES_SUCCESS);

export const addStudentToClass = createAction(ADD_STUDENT_TO_CLASS);
export const removeStudentFromClass = createAction(REMOVE_STUDENT_FROM_CLASS);
export const updateStudentInClass = createAction(UPDATE_STUDENT_IN_CLASS);