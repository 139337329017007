import AppBreadcrumb from '../../components/ui/AppBreadcrumb';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Row, Col, Button, FormGroup, Input } from 'reactstrap';
import { Formik, Form, Field } from 'formik';
import Select from "react-select";

import {
    USER_ROLE_UI_NAMES,
} from '../../redux/reducers/auth.reducer';
import { updateUserProfile } from "../../redux/actions/users.actions";

const EditProfile = () => {
    const dispatch = useDispatch();
    const userLogged = useSelector(state => state.users.userLogged);

    const handleSaveProfile = (values) => {
        dispatch(updateUserProfile({
            first_name: values.first_name,
            last_name: values.last_name,
            username: values.username,
            email: values.email
        }))
    }

    return (
        <Container className='mb-5 pb-5'>
            <Row>
                <Col>
                    <AppBreadcrumb headText="Profile" />
                </Col>
            </Row>
            <Row>
                <Col>
                    <div className='vstack mb-2'>
                        <h4 className='pt-2 pb-3 text-center'>Profile</h4>
                    </div>
                </Col>
            </Row>
            <Row className='mt-2'>
                <div className='col-md-6 mx-auto '>
                    <Formik
                        enableReinitialize={true}
                        initialValues={{
                            first_name: userLogged?.first_name ? userLogged.first_name : '',
                            last_name: userLogged?.last_name ? userLogged.last_name : '',
                            username: userLogged?.username ? userLogged.username : '',
                            email: userLogged?.email ? userLogged.email : '',
                            role: userLogged?.role ? userLogged.role : ''
                        }}
                        onSubmit={(values) => {
                            handleSaveProfile(values)
                        }}
                    >
                        {({ resetForm }) => (
                            <Form>
                                <Row>
                                    <Col>
                                        <FormGroup>
                                            <Field type="text" name="first_name" placeholder="First name" as={Input} />
                                        </FormGroup>
                                        <FormGroup>
                                            <Field type="text" name="last_name" placeholder="Last Name" as={Input} />
                                        </FormGroup>
                                        <FormGroup>
                                            <Field type="text" name="username" disabled placeholder="Username" as={Input} />
                                        </FormGroup>
                                        <FormGroup>
                                            <Field type="text" name="email" placeholder="Email" as={Input} />
                                        </FormGroup>
                                        <FormGroup>
                                            <Field id="role" name="role" as={Select}
                                                isDisabled={true}
                                                value={userLogged.role ? { value: userLogged.role, label: USER_ROLE_UI_NAMES[userLogged.role] } : ''}
                                                onChange={() => { }}
                                                placeholder="Role"
                                                options={Object.entries(USER_ROLE_UI_NAMES).map((role) => {
                                                    return { value: role[0], label: role[1] }
                                                })}
                                            />
                                        </FormGroup>
                                        <Row>
                                            <Col className="text-center col-md-6 col-sm-12">
                                                <Button color='success' className='mt-2 px-5 w-100' type='submit'>
                                                    <span className='d-inline-block fs-5 px-3'>Save profile</span>
                                                </Button>
                                            </Col>
                                            <Col className="text-cwenter col-md-6 col-sm-12">
                                                <Button color='secondary' className='mt-2 px-5 w-100' type='reset'>
                                                    <span className='d-inline-block fs-5 px-3' onClick={resetForm}>Cancel</span>
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Form>
                        )}
                    </Formik>
                </div>

            </Row>
        </Container>
    )
}

export default EditProfile;