import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from "react-router-dom";
import { Button, Col, Container, DropdownItem, DropdownMenu, DropdownToggle, Label, Row, UncontrolledDropdown } from 'reactstrap';
import WebcastsCarousel from '../../components/general/WebcastsCarousel';
import AppBreadcrumb from '../../components/ui/AppBreadcrumb';
import Search from '../../components/ui/Search';
import WebcastItem from '../../components/webcasts/WebcastItem';
import { WEBCASTS_ADMIN_CREATE, WEBCASTS_WATCH } from '../../navigation/ROUTE_CONSTANTS';
import { setNavigateTo } from '../../redux/actions/ui.actions';
import { getListPublicWebcasts } from '../../redux/actions/webcasts.actions';
import { parseToDateTime } from '../../utils/date_time';

const WEBCASTS_ORDER_TITLE = 'title';
const WEBCASTS_ORDER_AUTHOR = 'author';
const WEBCASTS_ORDER_EVENT = '-event_time';


const WebcastsLanding = () => {

    const webcastsList = useSelector((state) => state.webcasts.publicWebcastsList);

    const [upcomingWebcastsList, setUpcomingWebcastsList] = useState(null);
    const [pastWebcastsList, setPastWebcastsList] = useState([]);
    const [dropdownToggleText, setDropdownToggleText] = useState('Event Date');
    const [paginationParams, setPaginationParams] = useState({ sortBy: WEBCASTS_ORDER_EVENT });

    const isRoleClientAdmin = useSelector((state) => state.users.isRoleClientAdmin);

    const dispatch = useDispatch();

    useEffect(() => {
        document.title = process.env.REACT_APP_SITE_TITLE + ' - Webcasts';
    }, []);

    useEffect(() => {
        dispatch(getListPublicWebcasts({ ...paginationParams }));
    }, [dispatch, paginationParams]);

    useEffect(() => {
        if (webcastsList) {
            if (webcastsList.length > 0 && upcomingWebcastsList === null) {
                const upcomingWebcasts = webcastsList.filter((webcast) =>
                    webcast.event_time && parseToDateTime(webcast.event_time) >= new Date()
                );
                setUpcomingWebcastsList(upcomingWebcasts);
            }

            const pastWebcastsList = webcastsList.filter((webcast) =>
                webcast.event_time && parseToDateTime(webcast.event_time) < new Date()
            );
            setPastWebcastsList(pastWebcastsList);
        }
    }, [upcomingWebcastsList, webcastsList]);

    const CustomDropdownItem = ({ text, value }) => {
        return (
            <DropdownItem
                className='px-3 py-2 mt-1 mb-1'
                onClick={(e) => handleClickDropdownItem(e, value)}
            >{text}</DropdownItem>
        );
    };

    const handleClickDropdownItem = (e, value) => {
        const text = e.target.textContent;
        setDropdownToggleText(text);

        setPaginationParams({
            ...paginationParams,
            sortBy: value
        })
    }

    const handleSearchClicked = (search) => {
        setPaginationParams({
            ...paginationParams,
            filter: search,
        })
    }

    const createNewWebcast = () => {
        dispatch(setNavigateTo(WEBCASTS_ADMIN_CREATE));
    }

    return (<Container className="min-vh-72">
        <Row>
            <Col>
                <AppBreadcrumb headText="Webcasts" />
            </Col>
        </Row>
        <Row>
            <Col>
            </Col>
        </Row>
        <Row className='pb-1 mb-2 mt-2'>
            <Col md={3}>
                <h3 className='mb-4 mt-2'>Webcasts</h3>
            </Col>
            <Col md={{ offset: 6, size: 3 }}>
                {isRoleClientAdmin && <Button color={'success'} onClick={createNewWebcast} block>
                    Create new webcast
                </Button>}
            </Col>
        </Row>

        {upcomingWebcastsList && upcomingWebcastsList.length > 0 &&
            <WebcastsCarousel webcasts={upcomingWebcastsList} />
        }

        <Row className='background-light-grey p-2 m-1 border-bottom-grey h5'>
            <Col className='align-items-center'>
                <Label className='me-2'>Sort by</Label>
                <UncontrolledDropdown className='d-inline'>
                    <DropdownToggle className='bg-white border rounded-0 text-body text-start' caret>
                        {dropdownToggleText}
                    </DropdownToggle>
                    <DropdownMenu className='w-100 rounded-0'>
                        <CustomDropdownItem text="Title" value={WEBCASTS_ORDER_TITLE} />
                        <CustomDropdownItem text="Event Date" value={WEBCASTS_ORDER_EVENT} />
                        <CustomDropdownItem text="Author" value={WEBCASTS_ORDER_AUTHOR} />
                    </DropdownMenu>
                </UncontrolledDropdown>
                <div className="vr mx-4" style={{ height: '1rem' }}></div>
                <Search handleSearch={handleSearchClicked} inputPlaceHolder='Search all webinars' />
            </Col>
        </Row>

        <Row className='mb-5'>
            <Col>
                {(!pastWebcastsList || pastWebcastsList.length === 0) ? (
                    <div className="alert alert-primary mt-2">
                        There are no webcasts available.
                    </div>
                ) : (
                    pastWebcastsList.map((webcast) => (
                        <Row className='my-4 mx-3 pb-4 border-bottom-grey' key={webcast.id}>
                            <Col md={9}>
                                <WebcastItem webcastData={webcast} />
                            </Col>
                            <Col md={3}>
                                <Link className='w-75 btn btn-success m-4' to={WEBCASTS_WATCH.replace(':webcastId', webcast.id)} >
                                    Watch
                                </Link>
                            </Col>
                        </Row>
                    ))
                )}
            </Col >
        </Row>
    </Container>)
}

export default WebcastsLanding;