import React, { Fragment, useEffect } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faTrash } from '@fortawesome/free-solid-svg-icons';
import AppBreadcrumb from '../../components/ui/AppBreadcrumb';
import { useDispatch, useSelector } from 'react-redux';
import DataTable from 'react-data-table-component';
import { Link } from 'react-router-dom';
import {
    CLIENT_ADMIN_CREATE_LOGOFF_TIME,
    CLIENT_ADMIN_DELETE_LOGOFF_TIME,
    CLIENT_ADMIN_EDIT_LOGOFF_TIME,
} from '../../navigation/ROUTE_CONSTANTS';
import { setNavigateTo } from '../../redux/actions/ui.actions';
import { getAdminListLogoffTimes } from '../../redux/actions/logoff_times.actions';
import { customStyles } from '../../theme/assets/dataTableCustomStyles';
import {
    FREQUENCY_UI_NAMES,
    LOGOFF_ROLE_UI_NAMES,
} from '../../redux/reducers/logoff_times.reducers';
import {
    FORMAT_DATETIME_SHORT,
    FORMAT_DATE_MED_WITH_WEEKDAY,
    FORMAT_TIME_24_SIMPLE,
    isoStringToFormatted,
} from '../../utils/date_time';
import ClientAdminSessionTimesTable from '../../components/client_admins/SessionTimesTable';

const ClientAdminLoggoffTimes = () => {
    const adminLogoffTimesList = useSelector(
        (state) => state.adminLogoffTimes.adminLogoffTimesList
    );

    const dispatch = useDispatch();

    const createLogoffTime = () => {
        dispatch(setNavigateTo(CLIENT_ADMIN_CREATE_LOGOFF_TIME));
    };

    useEffect(() => {
        document.title = process.env.REACT_APP_SITE_TITLE + ' - Logoff Times';
    });

    useEffect(() => {
        dispatch(getAdminListLogoffTimes());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const columns = [
        {
            name: 'Base Date',
            selector: (row) => isoStringToFormatted(row.base_dt_utc, FORMAT_DATE_MED_WITH_WEEKDAY),
            sortable: true,
        },
        {
            name: 'Logoff At',
            selector: (row) => isoStringToFormatted(row.base_dt_utc, FORMAT_TIME_24_SIMPLE),
            sortable: true,
            center: true,
        },
        {
            name: 'Frequency',
            center: true,
            cell: (row) => FREQUENCY_UI_NAMES[row.frequency],
        },
        {
            name: 'Logoff Role',
            cell: (row) => LOGOFF_ROLE_UI_NAMES[row.logoff_role],
        },
        {
            name: 'Next run at',
            selector: (row) => row.next_run_at ? isoStringToFormatted(row.next_run_at, FORMAT_DATETIME_SHORT) : '',
            sortable: true,
            wrap: true,
        },
        {
            name: 'Actions',
            center: true,
            cell: (row) => (
                <Fragment>
                    <Link className="text-dark btn btn-link btn-light" to={CLIENT_ADMIN_EDIT_LOGOFF_TIME.replace(':logoffTimeId', row.id)} >
                        <FontAwesomeIcon icon={faPen} title="Edit" />
                    </Link>
                    <Link className="text-dark btn btn-link btn-light" to={CLIENT_ADMIN_DELETE_LOGOFF_TIME.replace(':logoffTimeId', row.id)} >
                        <FontAwesomeIcon icon={faTrash} title="Delete" />
                    </Link>
                </Fragment>
            ),
        },
    ];

    return (
        <Container className="min-vh-72">
            <Row>
                <Col>
                    <AppBreadcrumb headText="Logoff Times" />
                </Col>
            </Row>
            <Row className='mb-5'>
                <Col>
                    <ClientAdminSessionTimesTable />
                </Col>
            </Row>
            <Row className="pb-1 mb-2 mt-2">
                <Col md={3}>
                    <h3 className="mb-4 mt-2">Logoff Times</h3>
                </Col>
                <Col md={{ offset: 6, size: 3 }}>
                    <Button color={'success'} onClick={createLogoffTime} block>
                        New loggof time
                    </Button>
                </Col>
            </Row>
            <Row className="mb-5">
                <Col>
                    {adminLogoffTimesList &&
                        adminLogoffTimesList.length === 0 ? (
                        <div className="alert alert-primary mt-2">
                            Empty list
                        </div>
                    ) : (
                        <DataTable
                            columns={columns}
                            data={adminLogoffTimesList}
                            noHeader
                            pagination
                            highlightOnHover
                            customStyles={customStyles}
                        />
                    )}
                </Col>
            </Row>
        </Container>
    );
};

export default ClientAdminLoggoffTimes;
