import { ErrorMessage, Field, Form, Formik } from 'formik';
import { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { Button, Card, CardBody, Col, Container, FormGroup, Input, ListGroup, ListGroupItem, Row } from 'reactstrap';
import * as Yup from 'yup';
import AttachmentLinks from '../../../components/general/AttachmentLinks';
import AppBreadcrumb from '../../../components/ui/AppBreadcrumb';
import DragAndDrop from '../../../components/ui/DragAndDrop';
import SectionTitle from '../../../components/ui/SectionTitle';
import { EXERCISES, EXERCISES_PROCEDURES, PROCEDURE_DETAILS_FROM_EXERCISE } from '../../../navigation/ROUTE_CONSTANTS';
import { clearSelectedExercise, createExercise, getExercise, updateExercise } from '../../../redux/actions/exercises.actions';
import { getProcedures } from "../../../redux/actions/procedures.actions";
import { buildExerciseLabel } from '../../../utils';

const ExerciseForm = () => {
    const dispatch = useDispatch();
    const { exerciseId } = useParams();
    const selectedExercise = useSelector((state) => state.exercises.selectedExercise);
    const { proceduresMap } = useSelector((state) => state.procedures);
    const isAllowedToEdit = useSelector((state) => state.users.isRoleClientAdmin);
    const formSubmittedSuccess = useSelector(state => state.ui.formSubmittedSuccess);

    const [editMode, setEditMode] = useState(false);
    const [pageTitle, setPageTitle] = useState('');
    const [uploading, setUploading] = useState(false);
    const [formSubmitStarted, setFormSubmitStarted] = useState(false);

    useEffect(() => {
        dispatch(getProcedures());
        if (exerciseId) {
            dispatch(getExercise(exerciseId));
            setEditMode(false);
        } else {
            dispatch(clearSelectedExercise());
            setEditMode(true);
        }
    }, [exerciseId, dispatch]);

    useEffect(() => {
        if (exerciseId) {
            if (editMode) {
                setPageTitle('Edit ' + selectedExercise.name);
            } else {
                setPageTitle(buildExerciseLabel(selectedExercise));
            }
        } else {
            setPageTitle('Create Exercise');
        }
    }, [editMode, exerciseId, selectedExercise]);

    useEffect(() => {
        if (formSubmittedSuccess && formSubmitStarted) {
            setFormSubmitStarted(false);
            setEditMode(false);
        }
    }, [dispatch, formSubmitStarted, formSubmittedSuccess]);

    useEffect(() => {
        document.title = process.env.REACT_APP_SITE_TITLE + ' - ' + pageTitle;
    }, [pageTitle]);

    const startUploadAndSubmit = (e) => {
        e.preventDefault();
        setUploading(true);
    }

    const handleSaveExercise = (values) => {
        setFormSubmitStarted(true);
        if (exerciseId) {
            values.id = exerciseId;
            dispatch(updateExercise(values));
        } else {
            dispatch(createExercise(values));
        }
    }

    return (
        <Container className='mb-5 pb-5'>
            <Row>
                <Col>
                    <AppBreadcrumb headText={pageTitle} crumbList={[{ linkTo: EXERCISES, label: 'Exercises' }]} />
                </Col>
            </Row>
            <Formik
                enableReinitialize={true}
                initialValues={{
                    name: selectedExercise.name || '',
                    short_name: selectedExercise.short_name || '',
                    description: selectedExercise.description || '',
                    procedures: selectedExercise.procedures || [],
                    resources: (selectedExercise.resources || []).map((resource) => resource.file_uuid),
                }}
                validationSchema={Yup.object({
                    name: Yup.string()
                        .min(1, 'Name must be at least 1 characters length.')
                        .max(120, 'Name must be smaller than 120 characters length.')
                        .required('Please enter a name'),
                    short_name: Yup.string()
                        .max(32, 'Name must be smaller than 32 characters length.')
                        .nullable()
                        .notRequired()
                })}
                onSubmit={handleSaveExercise}
            >
                {({ setFieldValue, submitForm }) => (
                    <Form>
                        <Row className='mb-2'>
                            {editMode &&
                                <Fragment>
                                    <Col md={6}>
                                        <Field type="text" name="name" placeholder="Name" as={Input} />
                                        <ErrorMessage component="div" className="text-danger" name="name" />
                                    </Col>
                                    <Col md={2}>
                                        <Field type="text" name="short_name" placeholder="Short Name" as={Input} />
                                        <ErrorMessage component="div" className="text-danger" name="short_name" />
                                    </Col>
                                </Fragment>
                            }
                            {!editMode &&
                                <Col>
                                    <h4 className='mb-4'>{pageTitle}</h4>
                                </Col>
                            }
                            {isAllowedToEdit &&
                                <Col className='text-end'>
                                    {selectedExercise.id &&
                                        <Button color='success' type='button' outline className='me-3 px-4' size='sm' onClick={() => setEditMode(!editMode)}>{editMode ? 'Cancel' : 'Edit Exercise'}</Button>
                                    }
                                    {editMode &&
                                        <Button color='success' type='button' onClick={startUploadAndSubmit} className='me-3 px-4' size='sm'>Save Exercise</Button>
                                    }
                                </Col>
                            }
                        </Row>
                        <Row>
                            <Col sm={12} md={6}>
                                <SectionTitle title='Description' />
                                {editMode &&
                                    <FormGroup>
                                        <Field id="description" name="description" type="textarea" rows={12} as={Input} />
                                    </FormGroup>
                                }
                                {!editMode &&
                                    <Card className='border-0'>
                                        <CardBody>
                                            {selectedExercise.description}
                                        </CardBody>
                                    </Card>
                                }
                            </Col>
                            <Col sm={12} md={6}>
                                <SectionTitle title='Resources' />
                                <div className="my-3 mx-2">
                                    {editMode &&
                                        <Field name="resources" as={DragAndDrop} {...{ submitForm, uploading, setFieldValue, setUploading }} />
                                    }
                                    {!editMode &&
                                        <AttachmentLinks attachments={selectedExercise.resources} />
                                    }
                                </div>
                                <SectionTitle title='Procedures'>
                                    {isAllowedToEdit && !editMode && exerciseId &&
                                        <Link
                                            className='btn btn-outline-success btn-sm ms-2 px-4'
                                            to={EXERCISES_PROCEDURES.replace(':exerciseId', exerciseId)}
                                        >
                                            Edit Procedures
                                        </Link>
                                    }
                                </SectionTitle>
                                <Card className='border-0'>
                                    <CardBody className='overflow-auto mx-2 p-0' style={{ maxHeight: '25rem' }}>
                                        <ListGroup flush>
                                            {selectedExercise.procedures?.map(procedure =>
                                                <ListGroupItem key={procedure}>
                                                    <Link
                                                        className='text-decoration-none text-green'
                                                        to={PROCEDURE_DETAILS_FROM_EXERCISE.replace(':procedureId', procedure).replace(':exerciseId', exerciseId)}
                                                    >
                                                        {proceduresMap[procedure]}
                                                    </Link>
                                                </ListGroupItem>
                                            )}
                                        </ListGroup>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Form>
                )}
            </Formik>
        </Container >
    )
}

export default ExerciseForm;