import { ErrorMessage, Field, Form, Formik } from 'formik';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Button, FormGroup, Input, Label, Modal, ModalBody, ModalHeader
} from 'reactstrap';
import * as Yup from 'yup';
import DragAndDrop from '../../components/ui/DragAndDrop';
import { createCubicleLog } from '../../redux/actions/cubicle_logs.actions';
import { CUBICLE_STATUS_CHECKOUT } from '../../redux/reducers/my_cubicle.reducer';
import { buildProcedureLabel, checkFullScreen } from '../../utils';


const TreatmentCompleteModal = ({ modalOpen, setModalClosed, selectedStudentExerciseId }) => {
    const dispatch = useDispatch();
    const isFullScreen = checkFullScreen();
    const proceduresList = useSelector(state => state.procedures.proceduresList);
    const studentExercisesDashboard = useSelector(state => state.studentExercises.studentExercisesDashboard);
    const formDialogSubmitSuccess = useSelector(state => state.ui.formDialogSubmitSuccess);
    const [orderedProcedures, setOrderedProcedures] = useState([]);
    const [uploading, setUploading] = useState(false);

    useEffect(() => {
        let currentStudentExercise = studentExercisesDashboard.find(studentExercise => studentExercise.id === selectedStudentExerciseId);
        let currentExerciseProcedures = currentStudentExercise ? currentStudentExercise.exercise.procedures : [];
        let orderedProcedures = proceduresList.filter(procedure => currentExerciseProcedures.includes(procedure.id));

        orderedProcedures.sort((a, b) => {
            const concatA = a.need_details.toString() + a.name;
            const concatB = b.need_details.toString() + b.name;
            return concatA.localeCompare(concatB);
        });
        setOrderedProcedures(orderedProcedures);
    }, [proceduresList, selectedStudentExerciseId, studentExercisesDashboard]);

    const startUploadAndSubmit = (e) => {
        e.preventDefault();
        setUploading(true);
    }

    const handleSubmit = (values) => {
        const cubicleLogData = {
            student_exercise_id: selectedStudentExerciseId,
            cubicle_status: CUBICLE_STATUS_CHECKOUT,
            student_comments: values.student_comments,
            procedures: values.procedures.map(procedureId => parseInt(procedureId)),
            attachments: values.attachments,
        };
        dispatch(createCubicleLog(cubicleLogData));
    }

    if (formDialogSubmitSuccess) {
        setModalClosed();
    }

    return (
        <Modal size="lg" isOpen={modalOpen} fullscreen={isFullScreen}>
            <ModalHeader toggle={setModalClosed} className="m-2" />
            <ModalBody className='m-auto'>
                <div className='text-center'>
                    <h3>Checkout</h3>
                    <h5 className='mt-3'>Select the procedure that you need reviewing</h5>
                </div>
                <Formik
                    initialValues={{
                        procedures: [],
                        other_procedure: '',
                        student_comments: '',
                        attachments: [],
                    }}
                    validationSchema={Yup.object({
                        other_procedure: Yup.string()
                            .nullable(),
                        student_comments: Yup.string()
                            .nullable()
                    })}
                    onSubmit={(values) => {
                        handleSubmit(values);
                    }}
                >
                    {({ submitForm, setFieldValue }) => (
                        <Form className="mt-5 justify-content-center">
                            {orderedProcedures.map((procedure) => {
                                let procedureFormElement = (
                                    <FormGroup key={procedure.id} className={procedure.need_details ? 'mb-2' : 'mt-3'} check>
                                        <Field type='checkbox' name='procedures' value={procedure.id.toString()} id={'procedure-' + procedure.id} as={Input} />
                                        <Label for={'procedure-' + procedure.id} check>{buildProcedureLabel(procedure)}</Label>
                                    </FormGroup>
                                );

                                if (procedure.need_details) {
                                    return (
                                        <div key={procedure.id} className='d-flex align-items-center mt-2'>
                                            <div>
                                                {procedureFormElement}
                                            </div>
                                            <FormGroup className='ms-3'>
                                                <Field type='text' name='other_procedure' placeholder='Please specify' as={Input} />
                                                <ErrorMessage name='other_procedure' />
                                            </FormGroup>

                                        </div>
                                    );
                                } else {
                                    return procedureFormElement;
                                }
                            })}
                            <div className='border border-1 my-4'></div>
                            <p>Upload any images, video or documents to support your procedure review</p>

                            <Field name="attachments" as={DragAndDrop} {...{ submitForm, uploading, setFieldValue, setUploading }} />

                            <FormGroup className='mt-4'>
                                <Label>Add any comments</Label>
                                <Field type='textarea' name='student_comments' rows={5} as={Input} />
                                <ErrorMessage component="div" className="text-danger" name='student_comments' />
                            </FormGroup>

                            <Button color='success' type='button' onClick={startUploadAndSubmit} block className='mb-5'>Submit treatment complete request</Button>
                        </Form>
                    )}
                </Formik>
            </ModalBody>
        </Modal>
    );
};

export default TreatmentCompleteModal;
