import { faBars, faChevronRight, faCircleDot, faDesktop, faGear, faMicrophone, faTowerBroadcast, faUpRightAndDownLeftFromCenter, faVideo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from "react-router-dom";
import { Badge, Button, Card, CardBody, CardImg, CardText, Col, Container, Row } from 'reactstrap';
import ChatUI from '../../components/general/ChatUI';
import AppBreadcrumb from '../../components/ui/AppBreadcrumb';
import { WEBCASTS_LANDING } from '../../navigation/ROUTE_CONSTANTS';
import { getPublicWebcast } from '../../redux/actions/webcasts.actions';
import { FORMAT_DATETIME_MED_WITH_WEEKDAY, isoStringToFormatted } from '../../utils/date_time';

const WebcastsViewer = () => {
    const { webcastId } = useParams();
    const selectedWebcast = useSelector((state) => state.webcasts.selectedPublicWebcast);

    const [pageTitle, setPageTitle] = useState('');

    const dispatch = useDispatch();

    useEffect(() => {
        if (webcastId) {
            dispatch(getPublicWebcast(webcastId));
        }
    }, [webcastId, dispatch]);


    useEffect(() => {
        if (webcastId) {
            setPageTitle(selectedWebcast.title);
        }
    }, [webcastId, selectedWebcast]);

    useEffect(() => {
        document.title = process.env.REACT_APP_SITE_TITLE + ' - ' + pageTitle;
    }, [pageTitle]);

    return (
        <Container className="min-vh-72">
            <Row>
                <Col>
                    <AppBreadcrumb headText={pageTitle} crumbList={[{ linkTo: WEBCASTS_LANDING, label: 'Webcasts' }]} />
                </Col>
            </Row>

            <Row className='pb-1 mb-2 mt-2'>
                <Col>
                    <h3 className='mb-4 mt-2'>{selectedWebcast.title}</h3>
                </Col>
            </Row>

            {selectedWebcast &&
                <Fragment>
                    <Row className="g-0">
                        <Col md={9}>
                            <Card className='border-0 mb-4'>
                                <CardBody className='p-0'>
                                    <CardText>
                                        Author: {selectedWebcast.author}
                                        <span className="px-3">|</span>
                                        {selectedWebcast.event_time ? isoStringToFormatted(selectedWebcast.event_time, FORMAT_DATETIME_MED_WITH_WEEKDAY) : ''}
                                    </CardText>

                                    <CardText>
                                        {selectedWebcast.description}
                                    </CardText>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} lg={9}>
                            <Card className='rounded-0 mb-4'>
                                <CardBody className='m-0 p-0'>
                                    <div className="border-bottom-grey">
                                        <Button color='link' type='button' className='ms-6 me-3 px-3 py-1 text-decoration-none text-reset fs-6' size='sm'>
                                            <FontAwesomeIcon icon={faTowerBroadcast} className='me-2' /> 01:47:19
                                        </Button>
                                        <Button color='danger' type='button' outline className='my-2 me-3 px-3 py-1 text-uppercase' size='sm'>End session</Button>
                                        <div className='float-end'>
                                            <Button color='link' type='button' className='py-2 px-3 py-1 text-decoration-none text-reset fs-5 border-start' size='sm'>
                                                <FontAwesomeIcon icon={faGear} />
                                            </Button>
                                            <Button color='link' type='button' className='py-2 px-3 py-1 text-decoration-none text-reset fs-5 border-start' size='sm'>
                                                <FontAwesomeIcon icon={faUpRightAndDownLeftFromCenter} />
                                            </Button>
                                            <Button color='link' type='button' className='py-2 px-3 py-1 text-decoration-none text-reset fs-5 border-start' size='sm'>
                                                <FontAwesomeIcon icon={faChevronRight} />
                                            </Button>
                                        </div>
                                    </div>

                                    {!selectedWebcast.external_video_permalink && selectedWebcast.external_video_thumbnail &&
                                        <CardImg src={selectedWebcast.external_video_thumbnail} className="img-fluid rounded-0" alt={selectedWebcast.title} style={{ maxHeight: '20rem', 'objectFit': 'cover' }} />
                                    }
                                    {selectedWebcast.external_video_permalink &&
                                        <iframe src={selectedWebcast.external_video_permalink} width="100%" height="600" frameBorder="0" allowFullScreen title={selectedWebcast.title}></iframe>
                                    }

                                    <Row className="g-0">
                                        <Col lg={4} sm={6}>
                                            <Badge color='dark' pill className='m-3 py-2 px-4 fs-7'>
                                                <FontAwesomeIcon icon={faBars} className="fs-3 float-start me-2" />
                                                <div className='vstack gap-1 text-start'>
                                                    <span>24 Attendees</span>
                                                    <span className='text-muted'>Manage People</span>
                                                </div>
                                            </Badge>
                                        </Col>
                                        <Col lg={4} sm={6} className="text-center">
                                            <Button color='light' outline className='m-2 shadow-sm bg-body rounded-circle'>
                                                <FontAwesomeIcon icon={faMicrophone} style={{ fontSize: '1.1rem' }} />
                                            </Button>
                                            <Button color='light' outline className='m-2 shadow-sm bg-body rounded-circle'>
                                                <FontAwesomeIcon icon={faVideo} style={{ fontSize: '1.1rem' }} />
                                            </Button>
                                            <Button color='light' outline className='m-2 shadow-sm bg-body rounded-circle'>
                                                <FontAwesomeIcon icon={faDesktop} style={{ fontSize: '1.1rem' }} />
                                            </Button>
                                        </Col>
                                        <Col lg={4} sm={6} className='text-end'>
                                            <Badge color='dark' pill className='m-3 py-2 px-2'>
                                                <FontAwesomeIcon icon={faCircleDot} className="fs-4 float-start me-2" />
                                                <div className='mt-1 me-5 fs-7'>Book a One-on-One Demo</div>
                                            </Badge>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col xs={12} lg={3}>
                            <ChatUI location="WebcastsViewer" />
                        </Col>
                    </Row>
                </Fragment>
            }
        </Container>
    )
}

export default WebcastsViewer;