import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { confirmFileSaved } from "../../services/files.services";
import { UPDATE_FILES } from "../actions/files.actions";
import { genericError } from '../actions/error.actions';
import { disableOverlay, enableOverlay } from '../actions/ui.actions';

function* updateFiles({ payload }) {
    try {
        yield put(enableOverlay('Updating file ...'));
        yield call(confirmFileSaved, payload);
    } catch (err) {
        console.log('error:', err);
        yield put(genericError(err));
    }
    yield put(disableOverlay());
}

export function* watchFilesUpdate() {
    yield takeEvery(UPDATE_FILES, updateFiles);
}

function* filesSaga() {
    yield all([
        fork(watchFilesUpdate)
    ])
}

export default filesSaga;