import { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from "react-router-dom";
import { Container, Row, Col } from 'reactstrap';
import { Button } from 'reactstrap';
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import { setNavigateTo } from "../../../redux/actions/ui.actions";
import { getListExercises } from '../../../redux/actions/exercises.actions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPen, faTrash } from '@fortawesome/free-solid-svg-icons'
import AppBreadcrumb from '../../../components/ui/AppBreadcrumb';
import { CREATE_EXERCISE, DELETE_EXERCISE, EXERCISES_DETAILS } from '../../../navigation/ROUTE_CONSTANTS';
import { customStyles } from '../../../theme/assets/dataTableCustomStyles';

const Exercises = () => {

    const exercisesList = useSelector((state) => state.exercises.exercisesList);
    const isRoleClientAdmin = useSelector((state) => state.users.isRoleClientAdmin);
    const [data, setData] = useState([]);

    const dispatch = useDispatch();

    useEffect(() => {
        document.title = process.env.REACT_APP_SITE_TITLE + ' - Exercises';
        dispatch(getListExercises())
    }, [dispatch]);

    useEffect(() => {
        setData(exercisesList)
    }, [exercisesList]);

    const columns = [
        {
            name: 'Name',
            selector: row => row.name,
            sortable: true,
            cell: (row) => (
                <Link to={EXERCISES_DETAILS.replace(':exerciseId', row.id)} className='text-decoration-none'>
                    {row.name}
                </Link>),
            minWidth: '40%'
        },
        {
            name: 'Short name',
            selector: row => row.short_name,
            sortable: true,
            center: true,
            className: 'text-body'
        },
        {
            name: 'Procedures',
            center: true,
            selector: row => row.procedures.length
        },
        {
            name: 'Actions',
            center: true,
            cell: (row) => (
                <Fragment key={row.id}>
                    <Link className='text-dark btn btn-link btn-light' to={EXERCISES_DETAILS.replace(':exerciseId', row.id)} >
                        <FontAwesomeIcon icon={faPen} title="Edit" />
                    </Link>
                    <Link className='text-dark btn btn-link btn-light' to={DELETE_EXERCISE.replace(':exerciseId', row.id)} >
                        <FontAwesomeIcon icon={faTrash} title="Delete" />
                    </Link>
                </Fragment>
            ),
            omit: !isRoleClientAdmin,
        }
    ];

    const tableData = {
        columns,
        data
    };

    const createNewExercise = () => {
        dispatch(setNavigateTo(CREATE_EXERCISE));
    }

    return (<Container className="min-vh-72">
        <Row>
            <Col>
                <AppBreadcrumb headText="Exercises" />
            </Col>
        </Row>
        <Row>
            <Col>
            </Col>
        </Row>
        <Row className='pb-1 mb-2 mt-2'>
            <Col md={3}>
                <h3 className='mb-4 mt-2'>Exercises</h3>
            </Col>
            <Col md={{ offset: 6, size: 3 }}>
                {isRoleClientAdmin && <Button color={'success'} onClick={createNewExercise} block>
                    Create new exercise
                </Button>}
            </Col>
        </Row>
        <Row className='mb-5'>
            <Col>
                {(!data || data.length === 0) ? (
                    <div className="alert alert-primary mt-2">
                        There are no exercises available, please add one.
                    </div>
                ) : (
                    <DataTableExtensions filter={true} export={false} print={false} filterPlaceholder="Search exercise" {...tableData}>
                        <DataTable
                            columns={columns}
                            data={data ? data : []}
                            noHeader
                            pagination
                            highlightOnHover
                            customStyles={customStyles}
                        />
                    </DataTableExtensions>
                )}
            </Col >
        </Row>
    </Container>)
}

export default Exercises;