export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
export const USERS_API = '/users';
export const LOGIN = USERS_API + '/token/create';
export const LOGIN_BY_HASH = USERS_API + '/token/by_hash';
export const TOKEN_REFRESH = USERS_API + '/token/refresh';
export const TOKEN_INVALIDATE = USERS_API + '/token/invalidate';
export const TOKEN_LOGOUT_ALL = USERS_API + '/token/logout_all';
export const GET_CUSTOMERS = '/contacts';
export const CHECK_USER_REGISTRATION = '/register';
export const USER_UPDATE = '/update';
export const PASSWORD_RESET_BY_EMAIL = '/password_reset';
export const PASSWORD_RESET = '/password_reset/confirm';
export const INVOICES = '/invoices';
export const AMOUNTS_PER_STATUS = '/amountsperstatus';

export const API_CLIENTS = '/clients';
export const API_CUBICLES = '/cubicles';
export const API_CUBICLE_LOGS = '/cubicle_logs';
export const API_REQUEST_LOGS = '/request_logs';
export const API_MY_CUBICLE = '/my_cubicle';
export const API_CUBICLES_MY_QUEUE = '/cubicles/my_queue';
export const API_CUBICLES_UNASSIGNED = '/cubicles/unassigned';
export const API_CUBICLE_GROUPS = '/cubicle_groups';
export const API_PROCEDURES = '/procedures';
export const API_EXERCISES = '/exercises';
export const API_WEBCASTS = '/webcasts';
export const API_WEBCASTS_START_UPLOAD = API_WEBCASTS + '/start_video_upload';
export const API_WEBCASTS_ADMIN = '/webcasts_admin';
export const API_ANNOUNCEMENTS = '/announcements';
export const API_FACULTY_CLASSES = '/faculty_classes';
export const API_FACULTY_CLASSES_STUDENTS = API_FACULTY_CLASSES + '/students';
export const API_STUDENT_EXERCISES = '/student_exercises';
export const API_STUDENT_EXERCISES_DASHBOARD = API_STUDENT_EXERCISES + '/dashboard';
export const API_STUDENT_EXERCISES_IN_PROGRESS = API_STUDENT_EXERCISES + '/in_progress';

export const API_USERS_STUDENTS = USERS_API + '/student_list';
export const API_USERS_ADMIN = USERS_API + '/admin';
export const API_USERS_ADMIN_SINGLE_LOGIN_HASH = USERS_API + '/admin/single_login_hash';
export const API_FACULTY_LIST = USERS_API + '/faculty_list';
export const API_FACULTIES_LOGGED_IN = USERS_API + '/faculties_logged_in/';
export const API_LOGOFF_TIMES = USERS_API + '/logoff_times';
export const API_SESSION_TIMES = USERS_API + '/logoff_times';
export const API_ANNOUNCEMENTS_ADMIN = API_ANNOUNCEMENTS + '/admin';

export const API_CONFIGURATIONS = '/configurations';

export const API_URL_ON_GOING_QUEUE = API_CUBICLE_LOGS + '/ongoing_queue';

export const API_FILES_UPLOADS = '/file_uploads';
export const API_FILE_DETAILS = '/file_details';

// Reports
export const API_URL_BASIC_FACULTY_REPORT = API_CUBICLE_LOGS + '/basic_faculty_report';
export const API_URL_BASIC_STUDENT_REPORT = API_CUBICLE_LOGS + '/basic_student_report';

// Message broker urls
export const MB_BASE_URL = process.env.REACT_APP_MESSAGE_BROKER_URL;
export const MB_WS_MESSAGES = '/messages/ws';
export const MB_RESOURCE_CUBICLES_QUEUE = 'CUBICLES_QUEUE';
export const MB_RESOURCE_CUBICLE_LOGS_QUEUE = 'CUBICLE_LOGS_QUEUE';
export const MB_RESOURCE_FACULTIES_LOGGED_IN = 'FACULTIES_LOGGED_IN';
export const MB_RESOURCE_AUTOMATED_LOGOUT = 'AUTOMATED_LOGOUT';
export const MB_RESOURCE_ANNOUNCEMENT = 'ANNOUNCEMENT_CREATED';
