import { Fragment, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Row, Col } from 'reactstrap';
import Cubicle, { CUBICLE_VARIANT_CUBICLE_MAP } from '../../theme/components/Cubicle';
import { cubiclesSortedByName } from '../../utils';

export const CUBICLE_GRID_TYPE_MY_LIST = 'my_list';
export const CUBICLE_GRID_TYPE_SPECIAL_REQUEST = 'special_request';
export const CUBICLE_GRID_TYPE_ASSISTANCE_REQUEST = 'assistance_request';
export const CUBICLE_GRID_TYPE_OCCUPIED = 'occupied';
export const CUBICLE_GRID_TYPE_CUBICLE_MAP = 'cubicle_map';

const CubicleGroupGrid = ({ cubicleGroupId }) => {
    const intCubicleGroupId = parseInt(cubicleGroupId) || null;
    const cubicleGroupsList = useSelector(state => state.cubicleGroups.cubicleGroupsList);
    const cubicleQueue = useSelector(state => state.cubicleQueue);
    const [gridCubicles, setGridCubicles] = useState([]);
    const [headTitle, setHeadTitle] = useState('');
    const [headSubTitle, setHeadSubTitle] = useState('');

    useEffect(() => {
        let cubicleGroup = cubicleGroupsList.find(cubicleGroup => cubicleGroup.id === intCubicleGroupId);
        if (cubicleGroup) {
            setHeadTitle(cubicleGroup.name);
            setHeadSubTitle(cubicleGroup.heading_text);
        }
    }, [cubicleGroupsList, intCubicleGroupId]);

    useEffect(() => {
        let gridCubicles = [];
        if (cubicleQueue.length > 0) {
            gridCubicles = cubicleQueue.filter(cubicle => cubicle.group === intCubicleGroupId);
            gridCubicles.sort(cubiclesSortedByName);
        }

        setGridCubicles(gridCubicles);
    }, [cubicleQueue, intCubicleGroupId]);

    return (
        gridCubicles.length > 0 && (
            <Fragment>
                <Row className='mt-3 border-bottom'>
                    <Col>
                        <h5 className='lh-base mt-2 mb-2'>{headTitle}</h5>
                        {headSubTitle &&
                            <p className='lh-lg fs-7'>{headSubTitle}</p>
                        }
                    </Col>
                </Row>
                <Row className='mt-3 mb-3 pb-1'>
                    {gridCubicles.map(cubicle => (
                        <Col key={cubicle.id} xs={3} md={1}>
                            <Cubicle cubicleData={cubicle} cubicleLogData={cubicle.last_cubicle_log} variant={CUBICLE_VARIANT_CUBICLE_MAP} />
                        </Col>
                    ))}
                </Row>
            </Fragment>
        )
    );
};

export default CubicleGroupGrid;