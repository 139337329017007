import { createAction } from "@reduxjs/toolkit";

// UI action types
export const ENABLE_OVERLAY = '[UI] Enable Overlay';
export const DISABLE_OVERLAY = '[UI] Disable Overlay';
export const UPDATE_MODAL_PROGRESS = '[UI] Update modal progress';
export const CLOSE_MODAL_PROGRESS = '[UI] Close modal progress';
export const NEW_NOTIFICATION_MODAL = '[UI] Opening new notification modal';
export const CLOSE_NOTIFICATION_MODAL = '[UI] Closing new notification modal';
export const SET_TOAST = '[UI] Setting toast';
export const UPDATE_HEADER_FROM_MY_CUBICLE = '[UI] Updating header from my cubicle data';
export const SET_HEADER_DATA = '[UI] Setting header data';
export const SET_NAVIGATE_TO = '[UI] Navigating to';
export const SET_HOME_TARGET_URL = '[UI] Setting home target url';
export const UPDATE_SHOW_DROPDOWN_MOBILE = '[UI] Updating show dropdown mobile';
export const UPDATE_INDEX_DROPDOWN_MOBILE = '[UI] Updating index dropdown mobile';
export const SET_FORM_DIALOG_SUBMIT_SUCCESS = '[UI] Form dialog submit success set';
export const SET_FORM_SUBMITTED_SUCCESS = '[UI] Form submitted success set';
export const SET_START_LOGOUT_PROCESS = '[UI] Start logout process set';
export const SET_COMETCHAT_INIT_COMPLETED = '[UI] CometChat init completed';

// UI actions
export const enableOverlay = createAction(ENABLE_OVERLAY);
export const disableOverlay = createAction(DISABLE_OVERLAY);

export const updateModalProgress = createAction(UPDATE_MODAL_PROGRESS);
export const closeModalProgress = createAction(CLOSE_MODAL_PROGRESS);

export const openNotificationModal = createAction(NEW_NOTIFICATION_MODAL);
export const closeNotificationModal = createAction(CLOSE_NOTIFICATION_MODAL);

export const setToast = createAction(SET_TOAST);
export const updateHeaderFromMyCubicle = createAction(UPDATE_HEADER_FROM_MY_CUBICLE);

export const setHeaderData = createAction(SET_HEADER_DATA);

export const setNavigateTo = createAction(SET_NAVIGATE_TO);
export const setHomeTargetUrl = createAction(SET_HOME_TARGET_URL);

export const updateShowDropdownMobile = createAction(UPDATE_SHOW_DROPDOWN_MOBILE);
export const updateIndexDropdownMobile = createAction(UPDATE_INDEX_DROPDOWN_MOBILE);

export const setFormDialogSubmitSuccess = createAction(SET_FORM_DIALOG_SUBMIT_SUCCESS);
export const setFormSubmittedSuccess = createAction(SET_FORM_SUBMITTED_SUCCESS);
export const setStartLogoutProcess = createAction(SET_START_LOGOUT_PROCESS);

export const setCometChatInitCompleted = createAction(SET_COMETCHAT_INIT_COMPLETED);