import { ErrorMessage, Field, Form, Formik } from 'formik';
import { Fragment, useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Select from "react-select";
import { Button, Card, CardBody, Col, Container, FormGroup, FormText, Input, Label, Row } from 'reactstrap';
import * as Yup from 'yup';
import ImageS3 from '../../components/general/ImageS3';
import AppBreadcrumb from '../../components/ui/AppBreadcrumb';
import DragAndDrop from '../../components/ui/DragAndDrop';
import SectionTitle from '../../components/ui/SectionTitle';
import WebcastDropzone from '../../components/ui/WebcastDropzone';
import { WEBCASTS_ADMIN } from '../../navigation/ROUTE_CONSTANTS';
import { setFormSubmittedSuccess, setNavigateTo } from '../../redux/actions/ui.actions';
import { clearSelectedWebcast, createWebcast, getWebcast, updateWebcast } from '../../redux/actions/webcasts.actions';
import { WEBCAST_SERVICE_TYPE_UI_NAMES, WEBCAST_SERVICE_TYPE_VOD, WEBCAST_STATUS_DRAFT, WEBCAST_STATUS_UI_NAMES, WEBCAST_THUMBNAIL_OPTION_EXTERNAL, WEBCAST_THUMBNAIL_OPTION_UI_NAMES, WEBCAST_THUMBNAIL_OPTION_UPLOADED } from '../../redux/reducers/webcasts.reducer';
import { createFromParams, dateTimeToAPIDateTime, dateTimeToFormatted, FORMAT_DATETIME_SHORT, isoStringToFormatted, parseToDateTime } from '../../utils/date_time';

const convertFromFormEventTime = (formEventTime) => {
    if (!formEventTime && typeof formEventTime != 'object') {
        return null;
    }

    return createFromParams({
        year: formEventTime.getFullYear(),
        month: formEventTime.getMonth() + 1,
        day: formEventTime.getDate(),
        hour: formEventTime.getHours(),
        minute: formEventTime.getMinutes(),
    });
}

const convertToFormEventTime = (dateTime) => {
    return new Date(dateTime.year, dateTime.month - 1, dateTime.day, dateTime.hour, dateTime.minute);;
}

const initialEventTime = createFromParams({ hour: 12, minute: 0 });
const initialEventTimeJSDateLocalTimezone = convertToFormEventTime(initialEventTime);

const WEBCAST_STATUS_OPTIONS = Object.keys(WEBCAST_STATUS_UI_NAMES);
const WEBCAST_SERVICE_TYPE_OPTIONS = Object.keys(WEBCAST_SERVICE_TYPE_UI_NAMES);
const WEBCAST_THUMBNAIL_OPTION_OPTIONS = Object.keys(WEBCAST_THUMBNAIL_OPTION_UI_NAMES);

const baseInitialFormValues = {
    title: '',
    author: '',
    description: '',
    event_code: '',
    event_time: initialEventTimeJSDateLocalTimezone,
    announcement_time_before: '',
    cover_image: '',
    external_video_permalink: '',
    external_video_thumbnail: '',
    external_video_title: '',
    thumbnail_option: WEBCAST_THUMBNAIL_OPTION_EXTERNAL,
    status: WEBCAST_STATUS_DRAFT,
    service_type: WEBCAST_SERVICE_TYPE_VOD,
};


const dacastApiIntegrationEnabled = (process.env.REACT_APP_DACAST_API_INTEGRATION === 'true');

// Remove the thumbnail option if the dacast integration is not enabled
if (!dacastApiIntegrationEnabled) {
    WEBCAST_THUMBNAIL_OPTION_OPTIONS.splice(WEBCAST_THUMBNAIL_OPTION_OPTIONS.indexOf(WEBCAST_THUMBNAIL_OPTION_EXTERNAL), 1);
}

const WebcastsForm = () => {
    const dispatch = useDispatch();
    const { webcastId } = useParams();
    const selectedWebcast = useSelector((state) => state.webcasts.selectedWebcast);
    const isAllowedToEdit = useSelector((state) => state.users.isRoleClientAdmin);
    const formSubmittedSuccess = useSelector(state => state.ui.formSubmittedSuccess);
    const [formSubmitStarted, setFormSubmitStarted] = useState(false);

    const [initialFormValues, setInitialFormValues] = useState(baseInitialFormValues);

    const [editMode, setEditMode] = useState(false);
    const [pageTitle, setPageTitle] = useState('');
    const [uploading, setUploading] = useState(false);
    const [uploadingThumbnail, setUploadingThumbnail] = useState(false);
    const [uploadCompleted, setUploadCompleted] = useState(false);

    useEffect(() => {
        dispatch(setFormSubmittedSuccess(false));
        if (webcastId) {
            dispatch(getWebcast(webcastId));
            setEditMode(false);
        } else {
            dispatch(clearSelectedWebcast());
            setEditMode(true);
        }
    }, [webcastId, dispatch]);

    useEffect(() => {
        if (webcastId) {

            let eventTimeJSDateLocalTimezone;
            if (selectedWebcast.event_time) {
                let eventTime = parseToDateTime(selectedWebcast.event_time);

                // DatePicker works only with JS Date in browser default timezone
                // But we need to work with the Client (tenant/university) timezone
                // So we create JS Date on browser default timezone and set the date and time Client timezone
                eventTimeJSDateLocalTimezone = convertToFormEventTime(eventTime);
            } else {
                eventTimeJSDateLocalTimezone = initialEventTimeJSDateLocalTimezone;
            }

            setInitialFormValues({
                title: selectedWebcast.title,
                author: selectedWebcast.author,
                description: selectedWebcast.description,
                event_code: selectedWebcast.event_code,
                event_time: eventTimeJSDateLocalTimezone,
                announcement_time_before: selectedWebcast.announcement_time_before,
                cover_image: selectedWebcast.cover_image,
                external_video_permalink: selectedWebcast.external_video_permalink,
                external_video_thumbnail: selectedWebcast.external_video_thumbnail,
                external_video_title: selectedWebcast.external_video_title,
                thumbnail_option: selectedWebcast.thumbnail_option,
                status: selectedWebcast.status,
                service_type: selectedWebcast.service_type,
            });

            if (editMode) {
                setPageTitle('Edit ' + selectedWebcast.title);
            } else {
                setPageTitle(selectedWebcast.title);
            }
        } else {
            setInitialFormValues(baseInitialFormValues);
            setPageTitle('Create Webcast');
        }
    }, [editMode, webcastId, selectedWebcast]);

    useEffect(() => {
        document.title = process.env.REACT_APP_SITE_TITLE + ' - ' + pageTitle;
    }, [pageTitle]);

    useEffect(() => {
        if (formSubmittedSuccess && formSubmitStarted) {
            setFormSubmitStarted(false);
            setUploadCompleted(false);
            dispatch(setFormSubmittedSuccess(false));
            if (dacastApiIntegrationEnabled) {
                setUploading(true);
            } else {
                setEditMode(false);
            }
        }
    }, [dispatch, formSubmitStarted, formSubmittedSuccess]);

    useEffect(() => {
        if (uploadCompleted && selectedWebcast.id) {
            dispatch(setNavigateTo(WEBCASTS_ADMIN));
        }
    }, [dispatch, uploadCompleted, selectedWebcast.id]);

    const handleSaveWebcast = (values) => {
        setFormSubmitStarted(true);

        let toSubmitValues = { ...values };

        // Removing fields that must not be updated when Dacast API integration is disabled
        if (!dacastApiIntegrationEnabled) {
            delete toSubmitValues.external_video_thumbnail;
            delete toSubmitValues.external_video_title;
        }

        if (!toSubmitValues.cover_image) {
            // Remove field cover_image if it is empty
            delete toSubmitValues.cover_image;
        } else if (Array.isArray(toSubmitValues.cover_image)) {
            // DragAndDrop return an array, but we need only one thumbnail
            toSubmitValues.cover_image = toSubmitValues.cover_image[0]
        }

        const toSubmitEventTime = convertFromFormEventTime(toSubmitValues.event_time);
        toSubmitValues.event_time = dateTimeToAPIDateTime(toSubmitEventTime);

        if (webcastId) {
            toSubmitValues.id = webcastId;
            dispatch(updateWebcast(toSubmitValues));
        } else {
            dispatch(createWebcast(toSubmitValues));
        }
    }

    const startThumbnailUploadAndSubmit = (e, submitForm, values) => {
        e.preventDefault();
        if (values.thumbnail_option === WEBCAST_THUMBNAIL_OPTION_UPLOADED) {
            setUploadingThumbnail(true);
        } else {
            submitForm();
        }
    }

    return (
        <Container className='mb-5 pb-5'>
            <Row>
                <Col>
                    <AppBreadcrumb headText={pageTitle} crumbList={[{ linkTo: WEBCASTS_ADMIN, label: 'Webcasts' }]} />
                </Col>
            </Row>
            <Formik
                enableReinitialize={true}
                initialValues={initialFormValues}
                validationSchema={Yup.object({
                    title: Yup.string()
                        .min(1, 'Title must be at least 1 characters length.')
                        .max(100, 'Title must be smaller than 100 characters length.')
                        .required('Please enter a title'),
                    description: Yup.string()
                        .max(350, 'Description must be smaller than 350 characters length.')
                        .nullable()
                        .notRequired(),
                    author: Yup.string()
                        .max(60, 'Author must be smaller than 60 characters length.')
                        .nullable()
                        .notRequired(),
                    status: Yup.string()
                        .required('Please select a status')
                        .oneOf(WEBCAST_STATUS_OPTIONS, 'Please select a status'),
                    service_type: Yup.string()
                        .required('Please select a service type')
                        .oneOf(WEBCAST_SERVICE_TYPE_OPTIONS, 'Please select a service type'),
                    thumbnail_option: Yup.string()
                        .required('Please select a thumbnail option')
                        .oneOf(WEBCAST_THUMBNAIL_OPTION_OPTIONS, 'Please select a thumbnail option'),
                    event_code: Yup.string()
                        .max(25, 'Event code must be smaller than 25 characters length.')
                        .nullable()
                        .notRequired(),
                    external_video_permalink: Yup.string()
                        .url('Video permalink must be a valid url.')
                        .max(256, 'Video permalink must be smaller than 256 characters length.')
                        .nullable()
                        .notRequired(),
                    external_video_thumbnail: Yup.string()
                        .url('Video thumbnail must be a valid url.')
                        .max(256, 'Video thumbnail must be smaller than 256 characters length.')
                        .nullable()
                        .notRequired(),
                    announcement_time_before: Yup.string()
                        .max(25, 'Announcement time before must be smaller than 25 characters length.')
                        .nullable()
                        .notRequired(),
                })}
                onSubmit={handleSaveWebcast}
            >
                {({ values, setFieldValue, submitForm }) => (
                    <Form>
                        <Row className='mb-2'>
                            {editMode &&
                                <Fragment>
                                    <Col md={6}>
                                        <Field type="text" name="title" placeholder="Title" as={Input} />
                                        <ErrorMessage component="div" className="text-danger" name="title" />
                                    </Col>
                                </Fragment>
                            }
                            {!editMode &&
                                <Col>
                                    <h4 className='mb-4'>{pageTitle}</h4>
                                </Col>
                            }
                            {isAllowedToEdit &&
                                <Col className='text-end'>
                                    {selectedWebcast.id &&
                                        <Button color='success' type='button' outline className='me-3 px-4' size='sm' onClick={() => setEditMode(!editMode)}>{editMode ? 'Cancel' : 'Edit Webcast'}</Button>
                                    }
                                    {editMode &&
                                        <Button color='success' type='button' onClick={(e) => startThumbnailUploadAndSubmit(e, submitForm, values)} className='me-3 px-4' size='sm'>Save Webcast</Button>
                                    }
                                </Col>
                            }
                        </Row>
                        <Row>
                            <Col sm={12} md={6}>
                                <SectionTitle title='Basic Information' />
                                {editMode &&
                                    <Fragment>
                                        <FormGroup>
                                            <Field id="description" name="description" type="textarea" rows={12} as={Input} />
                                            <ErrorMessage component="div" className="text-danger" name="description" />
                                        </FormGroup>
                                        <FormGroup>
                                            <Field type="text" name="author" placeholder="Author" as={Input} />
                                            <ErrorMessage component="div" className="text-danger" name="author" />
                                        </FormGroup>

                                        {!dacastApiIntegrationEnabled &&
                                            <FormGroup>
                                                <Field type="text" name="external_video_permalink" placeholder="Video Permalink" as={Input} />
                                                <ErrorMessage component="div" className="text-danger" name="external_video_permalink" />
                                            </FormGroup>
                                        }

                                        <FormGroup row>
                                            <Label sm={4} for="status" className='pt-1'>Status</Label>
                                            <Col sm={8}>
                                                <Field id="status" name="status" as={Select}
                                                    placeholder="Status"
                                                    value={values.status ? { value: values.status, label: WEBCAST_STATUS_UI_NAMES[values.status] } : ''}
                                                    onChange={option => setFieldValue('status', option.value)}
                                                    options={WEBCAST_STATUS_OPTIONS.map(status => ({ value: status, label: WEBCAST_STATUS_UI_NAMES[status] }))}
                                                    isSearchable={false}
                                                />
                                                <ErrorMessage component="div" className="text-danger" name="status" />
                                            </Col>
                                        </FormGroup>

                                        <FormGroup row>
                                            <Label sm={4} for="service_type" className='pt-1'>Service Type</Label>
                                            <Col sm={8}>
                                                <Field id="service_type" name="service_type" as={Select}
                                                    placeholder="Service Type"
                                                    value={values.service_type ? { value: values.service_type, label: WEBCAST_SERVICE_TYPE_UI_NAMES[values.service_type] } : ''}
                                                    onChange={option => setFieldValue('service_type', option.value)}
                                                    options={WEBCAST_SERVICE_TYPE_OPTIONS.map(serviceType => ({ value: serviceType, label: WEBCAST_SERVICE_TYPE_UI_NAMES[serviceType] }))}
                                                    isSearchable={false}
                                                />
                                                <ErrorMessage component="div" className="text-danger" name="service_type" />
                                            </Col>
                                        </FormGroup>

                                        <FormGroup row>
                                            <Label sm={4} for="thumbnail_option" className='pt-1'>Thumbnail Option</Label>
                                            <Col sm={8}>
                                                <Field id="thumbnail_option" name="thumbnail_option" as={Select}
                                                    placeholder="Thumbnail Option"
                                                    value={values.thumbnail_option ? { value: values.thumbnail_option, label: WEBCAST_THUMBNAIL_OPTION_UI_NAMES[values.thumbnail_option] } : ''}
                                                    onChange={option => setFieldValue('thumbnail_option', option.value)}
                                                    options={WEBCAST_THUMBNAIL_OPTION_OPTIONS.map(thumbnailOption => ({ value: thumbnailOption, label: WEBCAST_THUMBNAIL_OPTION_UI_NAMES[thumbnailOption] }))}
                                                    isSearchable={false}
                                                />
                                                <ErrorMessage component="div" className="text-danger" name="thumbnail_option" />
                                            </Col>
                                        </FormGroup>

                                        {!dacastApiIntegrationEnabled && values.thumbnail_option === WEBCAST_THUMBNAIL_OPTION_EXTERNAL &&
                                            <FormGroup>
                                                <Field type="text" name="external_video_thumbnail" placeholder="Video Thumbnail URL" as={Input} />
                                                <ErrorMessage component="div" className="text-danger" name="external_video_thumbnail" />
                                            </FormGroup>
                                        }
                                        {values.thumbnail_option === WEBCAST_THUMBNAIL_OPTION_UPLOADED &&
                                            <Fragment>
                                                <SectionTitle title='Video Thumbnail' />
                                                <div className="my-3 mx-2">
                                                    <FormGroup>
                                                        <Field name="cover_image" as={DragAndDrop}
                                                            accept='image/*'
                                                            maxFiles={1}
                                                            uploading={uploadingThumbnail} setUploading={setUploadingThumbnail}
                                                            {...{ submitForm, setFieldValue }}
                                                        />
                                                        <hr />
                                                    </FormGroup>
                                                    <ImageS3 attachment={selectedWebcast.cover_image} imgAlt={selectedWebcast.title} className='img-thumbnail' />
                                                </div>
                                            </Fragment>
                                        }
                                    </Fragment>
                                }
                                {!editMode &&
                                    <Card className='border-0'>
                                        <CardBody>
                                            <Row>
                                                <Col>
                                                    <p>Description</p>
                                                    <p className='border p-3 mb-4'>{selectedWebcast.description}</p>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <p>Author</p>
                                                </Col>
                                                <Col>
                                                    <p>{selectedWebcast.author}</p>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <p>Status</p>
                                                </Col>
                                                <Col>
                                                    <p>{WEBCAST_STATUS_UI_NAMES[selectedWebcast.status]}</p>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <p>Service Type</p>
                                                </Col>
                                                <Col>
                                                    <p>{WEBCAST_SERVICE_TYPE_UI_NAMES[selectedWebcast.service_type]}</p>
                                                </Col>
                                            </Row>
                                            <Row className='mb-3'>
                                                <Col>
                                                    <p className='mb-0'>Video Permalink</p>
                                                    <code>{selectedWebcast.external_video_permalink}</code>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <p>Thumbnail Option</p>
                                                </Col>
                                                <Col>
                                                    <p>{WEBCAST_THUMBNAIL_OPTION_UI_NAMES[selectedWebcast.thumbnail_option]}</p>
                                                </Col>
                                            </Row>
                                            <Row className='mb-3'>
                                                <Col>
                                                    {selectedWebcast.thumbnail_option === WEBCAST_THUMBNAIL_OPTION_EXTERNAL &&
                                                        <Fragment>
                                                            <p className='mb-0'>Video Thumbnail Url</p>
                                                            <code>{selectedWebcast.external_video_thumbnail}</code>
                                                        </Fragment>
                                                    }
                                                    {selectedWebcast.thumbnail_option === WEBCAST_THUMBNAIL_OPTION_UPLOADED &&
                                                        <Fragment>
                                                            <p className='mb-0'>Video Thumbnail</p>
                                                            <ImageS3 attachment={selectedWebcast.cover_image} imgAlt={selectedWebcast.title} className='img-thumbnail' />
                                                        </Fragment>
                                                    }
                                                </Col>
                                            </Row>
                                            {dacastApiIntegrationEnabled &&
                                                <Row>
                                                    <Col>
                                                        <p>Video Title</p>
                                                    </Col>
                                                    <Col>
                                                        <p>{selectedWebcast.external_video_title}</p>
                                                    </Col>
                                                </Row>
                                            }
                                        </CardBody>
                                    </Card>
                                }
                            </Col>
                            <Col sm={12} md={6}>
                                <SectionTitle title='Event' />
                                <Card className='border-0'>
                                    <CardBody className='mx-2 p-0'>
                                        {editMode &&
                                            <Fragment>
                                                <FormGroup>
                                                    <Field type="text" name="event_code" placeholder="Event Code" as={Input} />
                                                    <ErrorMessage component="div" className="text-danger" name="event_code" />
                                                </FormGroup>
                                                <FormGroup row>
                                                    <Label sm={6} for="event_time" className='pt-1'>Selected event time</Label>
                                                    <Col sm={6}>
                                                        <Field type='text' name='event_time' readOnly as={Input}
                                                            value={(values.event_time && typeof values.event_time == 'object') ? dateTimeToFormatted(convertFromFormEventTime(values.event_time), FORMAT_DATETIME_SHORT) : ''}
                                                        />
                                                    </Col>
                                                </FormGroup>
                                                <FormGroup>
                                                    <DatePicker
                                                        placeholderText="Event date and time"
                                                        selected={values.event_time}
                                                        onChange={(dateTime) => setFieldValue('event_time', dateTime)}
                                                        showTimeSelect
                                                        timeFormat='HH:mm'
                                                        timeIntervals={30}
                                                        inline
                                                        fixedHeight
                                                    />
                                                    <FormText>
                                                        Pick date and time for this Webcast.
                                                    </FormText>
                                                </FormGroup>
                                                <FormGroup row>
                                                    <Label sm={4} for="status" className='pt-1'>Announcement Time Before</Label>
                                                    <Col sm={8}>
                                                        <Field type="text" name="announcement_time_before" placeholder="Announcement Time Before" as={Input} />
                                                        <ErrorMessage component="div" className="text-danger" name="announcement_time_before" />
                                                    </Col>
                                                </FormGroup>
                                            </Fragment>
                                        }
                                        {!editMode &&
                                            <Card className='border-0'>
                                                <CardBody>
                                                    <Row>
                                                        <Col>
                                                            <p>Event Code</p>
                                                        </Col>
                                                        <Col>
                                                            <p>{selectedWebcast.event_code}</p>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            <p>Event Time</p>
                                                        </Col>
                                                        <Col>
                                                            <p>{selectedWebcast.event_time ? isoStringToFormatted(selectedWebcast.event_time, FORMAT_DATETIME_SHORT) : ''}</p>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            <p>Announcement Time Before</p>
                                                        </Col>
                                                        <Col>
                                                            <p>{selectedWebcast.announcement_time_before}</p>
                                                        </Col>
                                                    </Row>
                                                </CardBody>
                                            </Card>
                                        }

                                    </CardBody>
                                </Card>

                                <SectionTitle title='Webcast video' />
                                <div className="my-3 mx-2">
                                    {dacastApiIntegrationEnabled && editMode &&
                                        <FormGroup>
                                            <Field name="external_video_title" as={WebcastDropzone}
                                                webcastId={selectedWebcast.id}
                                                {...{ setUploadCompleted, uploading, setFieldValue, setUploading }}
                                            />
                                            <hr />
                                        </FormGroup>
                                    }
                                    {values.external_video_permalink &&
                                        <iframe src={values.external_video_permalink} width="100%" height="400" allowFullScreen title={values.title}></iframe>
                                    }
                                </div>

                            </Col>
                        </Row>
                    </Form>
                )}
            </Formik>
        </Container >
    )
}

export default WebcastsForm;