import { createAction } from "@reduxjs/toolkit";

//Exercises types
export const CREATE_EXERCISE = '[EXERCISE] Exercise created';
export const CREATE_EXERCISE_SUCCESS = '[EXERCISE] Exercise created successfully';
export const UPDATE_EXERCISE = '[EXERCISE] Exercise updated';
export const UPDATE_EXERCISE_SUCCESS = '[EXERCISE] Exercise updated successfully';
export const DELETE_EXERCISE = '[EXERCISE] Exercise deleted';
export const DELETE_EXERCISE_SUCCESS = '[EXERCISE] Exercise deleted successfully';
export const GET_LIST_EXERCISES = '[EXERCISES] Getting list exercises ';
export const GET_LIST_EXERCISES_SUCCESS = '[EXERCISES] Getting list exercises success!';
export const GET_EXERCISE = '[EXERCISE] Getting exercise info';
export const GET_EXERCISE_SUCCESS = '[EXERCISE] Getting exercise info success!';
export const CLEAR_SELECTED_EXERCISE = '[EXERCISE] Clear selected exercise';

//Exercise actions
export const getExercise = createAction(GET_EXERCISE);
export const getExerciseSuccess = createAction(GET_EXERCISE_SUCCESS);
export const getListExercises = createAction(GET_LIST_EXERCISES);
export const getListExercisesSuccess = createAction(GET_LIST_EXERCISES_SUCCESS);

export const createExercise = createAction(CREATE_EXERCISE);
export const createExerciseSuccess = createAction(CREATE_EXERCISE_SUCCESS);
export const updateExercise = createAction(UPDATE_EXERCISE);
export const updateExerciseSuccess = createAction(UPDATE_EXERCISE_SUCCESS);
export const deleteExercise = createAction(DELETE_EXERCISE);
export const deleteExerciseSuccess = createAction(DELETE_EXERCISE_SUCCESS);
export const clearSelectedExercise = createAction(CLEAR_SELECTED_EXERCISE);
