import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Row, Col } from 'reactstrap';
import CubicleGroupGrid from '../../components/general/CubicleGroupGrid';
import { getCubicleGroups } from '../../redux/actions/cubicle_groups.actions';

const CubicleMap = () => {
    const dispatch = useDispatch();
    const cubicleGroupMapIdToName = useSelector((state) => state.cubicleGroups.mapIdToName);

    useEffect(() => {
        dispatch(getCubicleGroups());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Container className='mb-5 mt-5 min-vh-60'>
            <Row className=''>
                <Col>
                    <h3 className='mt-1'>Cubicles</h3>
                </Col>
            </Row>
            <CubicleGroupGrid cubicleGroupId={null} />
            {cubicleGroupMapIdToName && Object.keys(cubicleGroupMapIdToName).map((groupId, index) => (
                <CubicleGroupGrid cubicleGroupId={groupId} key={index} />
            ))}

        </Container>
    );
};

export default CubicleMap;