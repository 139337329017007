import { forwardRef, Fragment, useEffect, useState } from 'react';
import { Container, Row, Col, Input } from 'reactstrap';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar } from '@fortawesome/free-solid-svg-icons'
import DatePicker from 'react-datepicker';

import '../../styles/AppDatePicker.scss';
import 'react-datepicker/dist/react-datepicker.css';
import { checkFullScreen, setDateDayBeginning, setDateDayEnding } from '../../utils';

const REPORT_PERIOD_ANY_TIME = 'any-time';
const REPORT_PERIOD_PAST_24_HOURS = 'past-24-hours';
const REPORT_PERIOD_PAST_WEEK = 'past-week';
const REPORT_PERIOD_PAST_MONTH = 'past-month';
const REPORT_PERIOD_PAST_YEAR = 'past-year';
const REPORT_PERIOD_CUSTOM = 'custom';

const DateInputWithButton = forwardRef(({ value, onClick, onChange }, ref) => {
    const [inputValue, setInputValue] = useState(false);

    useEffect(() => {
        setInputValue(value);
    }, [value]);

    const handleInputOnChange = (e) => {
        let targetValue = e.target.value;
        setInputValue(targetValue);
        if (targetValue.length >= 10) {
            onChange(e);
        }
    };

    return (
        <Fragment>
            <Input className='pe-5 rounded-0' onChange={handleInputOnChange} onClick={onClick} value={inputValue} ref={ref} />
            <Button className='position-absolute top-50 end-0 translate-middle-y' color='link' size='sm' onClick={onClick} >
                <FontAwesomeIcon className='fs-4 mt-1' icon={faCalendar} />
            </Button>
        </Fragment>
    )
});

const AppDateRangePicker = ({ dateRangeChanged }) => {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [dropdownToggleText, setDropdownToggleText] = useState('Past week');
    const [dropdownToggleValue, setDropdownToggleValue] = useState(REPORT_PERIOD_PAST_WEEK);
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedDateFrom, setSelectedDateFrom] = useState(new Date(0));
    const [selectedDateTo, setSelectedDateTo] = useState(new Date());

    const handleClickDropdownItem = (e, value) => {
        const text = e.target.textContent;
        setDropdownToggleText(text);
        setDropdownToggleValue(value);
    }

    const handleToggleModalHeader = () => {
        setModalOpen(false);
    }

    useEffect(() => {
        if (dropdownToggleValue === REPORT_PERIOD_CUSTOM) {
            setModalOpen(true);
        } else {
            let dateFrom = new Date();
            let dateTo = new Date();
            if (dropdownToggleValue === REPORT_PERIOD_PAST_24_HOURS) {
                dateFrom.setHours(dateFrom.getHours() - 24);
            } else if (dropdownToggleValue === REPORT_PERIOD_PAST_WEEK) {
                dateFrom.setDate(dateFrom.getDate() - 7);
            } else if (dropdownToggleValue === REPORT_PERIOD_PAST_MONTH) {
                dateFrom.setMonth(dateFrom.getMonth() - 1);
            } else if (dropdownToggleValue === REPORT_PERIOD_PAST_YEAR) {
                dateFrom.setFullYear(dateFrom.getFullYear() - 1);
            } else if (dropdownToggleValue === REPORT_PERIOD_ANY_TIME) {
                // Set beginning of unix time stamp
                dateFrom = new Date(0);
            }
            setSelectedDateFrom(dateFrom);
            setSelectedDateTo(dateTo)

            dateRangeChanged(dateFrom, dateTo);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dropdownToggleValue]);

    const handleSearch = () => {
        let localDateFrom = selectedDateFrom.toLocaleDateString([], { dateStyle: 'short' });
        let localDateTo = selectedDateTo.toLocaleDateString([], { dateStyle: 'short' });
        setDropdownToggleText(`${localDateFrom} - ${localDateTo}`);

        dateRangeChanged(selectedDateFrom, selectedDateTo);
        setModalOpen(false);
    };

    const CustomDropdownItem = ({ text, value }) => {
        return (
            <DropdownItem
                className='px-3 py-2 mt-1 mb-1'
                onClick={(e) => handleClickDropdownItem(e, value)}
            >{text}</DropdownItem>
        );
    };

    const isFullScreen = checkFullScreen();

    return (
        <>
            <Dropdown toggle={() => { setDropdownOpen(!dropdownOpen) }} isOpen={dropdownOpen}>
                <DropdownToggle className='w-100 bg-white border rounded-0 text-body text-start' caret>
                    {dropdownToggleText}
                </DropdownToggle>
                <DropdownMenu className='w-100 rounded-0'>
                    <CustomDropdownItem text="Any time" value={REPORT_PERIOD_ANY_TIME} />
                    <CustomDropdownItem text="Past 24 hours" value={REPORT_PERIOD_PAST_24_HOURS} />
                    <CustomDropdownItem text="Past week" value={REPORT_PERIOD_PAST_WEEK} />
                    <CustomDropdownItem text="Past month" value={REPORT_PERIOD_PAST_MONTH} />
                    <CustomDropdownItem text="Past year" value={REPORT_PERIOD_PAST_YEAR} />
                    <DropdownItem divider />
                    <CustomDropdownItem text="Custom date range" value={REPORT_PERIOD_CUSTOM} />
                </DropdownMenu>
            </Dropdown>
            <Modal size='lg' isOpen={modalOpen} fullscreen={isFullScreen}>
                <ModalHeader toggle={handleToggleModalHeader} />
                <ModalBody>
                    <Container className='px-md-5' fluid>
                        <Row className='mt-1'>
                            <Col>
                                <h3 className='text-center mb-4'>Custom date range</h3>
                            </Col>
                        </Row>
                        <Row className='align-items-center mt-1'>
                            <Col xs={3} md={1} className='text-end mb-4 mb-sm-0'>From:</Col>
                            <Col xs={9} md={5} className='position-relative mb-4 mb-sm-0'>
                                <DatePicker
                                    className='w-100 px-2 py-2'
                                    selected={selectedDateFrom}
                                    onChange={(date) => setSelectedDateFrom(setDateDayBeginning(date))}
                                    customInput={<DateInputWithButton />}
                                    todayButton={'Today'}
                                />
                            </Col>
                            <Col xs={3} md={1} className='text-end'>To:</Col>
                            <Col xs={9} md={5} className='position-relative'>
                                <DatePicker
                                    className='w-100 px-2 py-2'
                                    selected={selectedDateTo}
                                    onChange={(date) => setSelectedDateTo(setDateDayEnding(date))}
                                    customInput={<DateInputWithButton />}
                                    todayButton={'Today'}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col md={{ offset: 3, size: 6 }} className='px-md-5 pt-1 mt-4 mb-5'>
                                <Button color='success' block onClick={handleSearch}>Search dates</Button>
                            </Col>
                        </Row>
                    </Container>
                </ModalBody>
            </Modal>
        </>
    );
};

export default AppDateRangePicker;