import { useSelector } from "react-redux";
import { CometChat } from "@cometchat-pro/chat";
import { CometChatUI } from '../comet_chat';
import { useEffect, useState } from "react";
import { userFullName } from "../../utils";

const ChatUI = ({ location }) => {
    const currentUser = useSelector(state => state.users.userLogged);
    const cometchatInitCompleted = useSelector(state => state.ui.cometchatInitCompleted);
    const [userLoggedIn, setUserLoggedIn] = useState(false);
    const [userCreated, setUserCreated] = useState(false);

    useEffect(() => {
        if (currentUser.username && cometchatInitCompleted) {
            let authKey = process.env.REACT_APP_COMETCHAT_AUTH_KEY;
            var uid = currentUser.username;

            CometChat.login(uid, authKey).then(
                () => {
                    setUserLoggedIn(true);
                },
                error => {
                    console.log("Login failed with exception:", { error });

                    // Check the reason for error and take appropriate action.
                    if (error.code === "ERR_UID_NOT_FOUND") {
                        // User with this UID does not exist. Create a new user.
                        var user = new CometChat.User(uid);
                        user.setName(userFullName(currentUser));
                        CometChat.createUser(user, authKey).then(
                            user => {
                                setUserCreated(true);
                                console.log("user created", user);
                            }, error => {
                                console.log("error", error);
                            }
                        )
                    }
                }
            );
        }
    }, [currentUser, cometchatInitCompleted, userCreated]);

    return (
        userLoggedIn && <CometChatUI location={location} />
    )
}

export default ChatUI;