import { useDispatch } from 'react-redux';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { Container, Row, Col } from 'reactstrap';
import { Button } from 'reactstrap';
import { clearSingleLoginButton, setSingleLoginButton } from '../../redux/actions/admin.users.actions';
import { checkFullScreen } from '../../utils';

const SingleLoginButtonModal = ({ modalOpen, setModalClosed, userData }) => {
    const dispatch = useDispatch();
    const isFullScreen = checkFullScreen();
    const singleButtonLogin = JSON.parse(localStorage.getItem('ca-sbl'));

    const handleButtonEnable = () => {
        dispatch(setSingleLoginButton(userData.id));
    }

    const handleButtonDisable = () => {
        dispatch(clearSingleLoginButton(userData.id));
    }

    return (
        <Modal size='lg' isOpen={modalOpen} fullscreen={isFullScreen}>
            <ModalHeader toggle={setModalClosed} />
            <ModalBody>
                <Container className='px-md-5' fluid>
                    <Row className='mt-1'>
                        <Col>
                            <h3 className='text-center mb-2'>Single login button</h3>
                        </Col>
                    </Row>
                    <Row className='align-items-center mt-2'>
                        {singleButtonLogin ? (
                            <Col md={{ offset: 1, size: 10 }} className='px-md-5 mb-2'>
                                <p className='text-center'>Currently this browser is configured with single login for user: {singleButtonLogin.username} </p>
                            </Col>
                        ) : (
                            <Col md={{ offset: 1, size: 10 }} className='px-md-5 mb-2'>
                                <p className='text-center'>This browser is not configured for single login. To enable it click on button below.</p>
                            </Col>
                        )}
                    </Row>
                    <Row>
                        <Col className='px-md-5 mb-5 text-center'>
                            {userData && (!singleButtonLogin || userData.username !== singleButtonLogin.username) &&
                                <Button color='success' className="mx-2" onClick={handleButtonEnable}>Enable for {userData.username}</Button>
                            }
                            {singleButtonLogin &&
                                <Button color='warning' className="mx-2" onClick={handleButtonDisable}>Disable single login</Button>
                            }
                        </Col>
                    </Row>
                </Container>
            </ModalBody>
        </Modal>
    );
};

export default SingleLoginButtonModal;
