import { Button } from 'reactstrap';
import { useDispatch } from 'react-redux';
import { studentCancelRequestLog } from '../../redux/actions/request_logs.actions';

const ButtonCancelRequest = ({cubicleLogId, ...props}) => {
    const dispatch = useDispatch();

    const handleCancelRequest = (e) => {
        e.preventDefault();
        dispatch(studentCancelRequestLog({cubicleLogId: cubicleLogId}));
    };

    return (
        <Button size='sm' onClick={handleCancelRequest} {...props} >
            Cancel request
        </Button>
    );
}

export default ButtonCancelRequest;