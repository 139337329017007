import { useEffect, useState } from 'react'
import { Container, Row, Col, Button } from 'reactstrap';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import AppBreadcrumb from '../../../components/ui/AppBreadcrumb';
import { setNavigateTo } from '../../../redux/actions/ui.actions';
import { getOneStudentExercise } from '../../../services/studentExercisesService';
import { CLIENT_ADMIN_USERS, STUDENT_EXERCISES } from '../../../navigation/ROUTE_CONSTANTS';
import { deleteStudentExercise } from '../../../redux/actions/student_exercises.actions';
import { isoStringToFormatted } from '../../../utils/date_time';
import { STUDENT_EXERCISE_STATUS_UI_NAMES } from '../../../redux/reducers/student_exercises.reducers';

function StudentExerciseDelete() {
    const dispatch = useDispatch();
    const pageTitle = 'Delete Student Exercise';

    const exercisesMap = useSelector((state) => state.exercises.exercisesMap);

    const { studentUsername, studentExerciseId } = useParams();
    const [rowData, setRowData] = useState('');

    useEffect(() => {
        document.title = process.env.REACT_APP_SITE_TITLE + ' - ' + pageTitle;
    });

    useEffect(() => {
        getOneStudentExercise({ studentUsername, studentExerciseId }).then((data) => setRowData(data))
    }, [studentUsername, studentExerciseId]);

    const handleCancel = () => {
        dispatch(setNavigateTo(STUDENT_EXERCISES.replace(':studentUsername', rowData.student_username)));
    }

    const handleDelete = () => {
        dispatch(deleteStudentExercise(rowData));
    }

    return (
        <Container className='mb-5 pb-5'>
            <Row>
                <Col>
                    <AppBreadcrumb headText={pageTitle} crumbList={[
                        { linkTo: CLIENT_ADMIN_USERS, label: 'Users' },
                        { linkTo: STUDENT_EXERCISES.replace(':studentUsername', rowData.student_username), label: rowData.student_username }
                    ]} />
                </Col>
            </Row>
            <Row>
                <Col>
                    <div className='vstack col-md-4 mx-auto mt-4 text-center'>
                        <h4 className='pt-2 pb-3 text-center'>{pageTitle}</h4>
                        <h5 className='mt-3 mb-4 pt-2 pb-2'>Are you sure you want to delete this student exercise ?</h5>
                        <p>Student: <strong>{rowData.student_username}</strong></p>
                        <p>Exercise: <strong>{exercisesMap[rowData.exercise_id]}</strong></p>
                        <p>Due Date: <strong>{isoStringToFormatted(rowData.due_date)}</strong></p>
                        <p>Grade: <strong>{rowData.procedure_grade}</strong></p>
                        <p>Status: <strong>{STUDENT_EXERCISE_STATUS_UI_NAMES[rowData.status]}</strong></p>
                    </div>
                </Col>
            </Row>
            <Row>
                <p className="mt-4 mb-4 text-center">
                    <Button color={'success'} className="me-2" onClick={handleDelete}>
                        {pageTitle}
                    </Button>
                    <Button color={'secondary'} onClick={handleCancel} >
                        Cancel
                    </Button>
                </p>
            </Row>
        </Container>
    )
}

export default StudentExerciseDelete
