import {createAction} from "@reduxjs/toolkit";

// Procedure type
export const CREATE_PROCEDURE = '[PROCEDURE] Procedure created';
export const UPDATE_PROCEDURE = '[PROCEDURE] Procedure updated';
export const DELETE_PROCEDURE = '[PROCEDURE] Procedure deleted';
export const GET_LIST_PROCEDURES = '[PROCEDURES] Getting list procedures';
export const GET_LIST_PROCEDURES_SUCCESS = '[PROCEDURES] Getting procedures success!';
export const GET_ADMIN_LIST_PROCEDURES = '[PROCEDURES] Getting admin list procedures';
export const GET_ADMIN_LIST_PROCEDURES_SUCCESS = '[PROCEDURES] Getting admin procedures success!';

export const GET_SELECTED_PROCEDURE = '[PROCEDURE] Getting selected procedure';
export const GET_SELECTED_PROCEDURE_SUCCESS = '[PROCEDURE] Getting selected procedure success!';
export const CLEAR_SELECTED_PROCEDURE = '[PROCEDURE] Clear selected procedure';

// Procedure actions
export const createProcedure = createAction(CREATE_PROCEDURE);
export const updateProcedure = createAction(UPDATE_PROCEDURE);
export const deleteProcedure = createAction(DELETE_PROCEDURE);
export const getProcedures = createAction(GET_LIST_PROCEDURES);
export const getProceduresSuccess = createAction(GET_LIST_PROCEDURES_SUCCESS);
export const getAdminListProcedures = createAction(GET_ADMIN_LIST_PROCEDURES);
export const getAdminListProceduresSuccess = createAction(GET_ADMIN_LIST_PROCEDURES_SUCCESS);

export const getSelectedProcedure = createAction(GET_SELECTED_PROCEDURE);
export const getSelectedProcedureSuccess = createAction(GET_SELECTED_PROCEDURE_SUCCESS);
export const clearSelectedProcedure = createAction(CLEAR_SELECTED_PROCEDURE);