import { CLEAR_SELECTED_WEBCAST, GET_LIST_PUBLIC_WEBCASTS_SUCCESS, GET_LIST_WEBCASTS_SUCCESS, GET_PUBLIC_WEBCAST_SUCCESS, GET_WEBCAST_SUCCESS } from "../actions/webcasts.actions"

export const WEBCAST_STATUS_DRAFT = 'draft';
export const WEBCAST_STATUS_PUBLISHED = 'published';
export const WEBCAST_STATUS_DISABLED = 'disabled';

export const WEBCAST_STATUS_UI_NAMES = {
    [WEBCAST_STATUS_DRAFT]: 'Draft',
    [WEBCAST_STATUS_PUBLISHED]: 'Published',
    [WEBCAST_STATUS_DISABLED]: 'Disabled',
};


export const WEBCAST_THUMBNAIL_OPTION_NONE = 'none';
export const WEBCAST_THUMBNAIL_OPTION_EXTERNAL = 'external';
export const WEBCAST_THUMBNAIL_OPTION_UPLOADED = 'uploaded';

export const WEBCAST_THUMBNAIL_OPTION_UI_NAMES = {
    [WEBCAST_THUMBNAIL_OPTION_NONE]: 'None',
    [WEBCAST_THUMBNAIL_OPTION_EXTERNAL]: 'External',
    [WEBCAST_THUMBNAIL_OPTION_UPLOADED]: 'Uploaded',
};


export const WEBCAST_SERVICE_TYPE_VOD = 'vod';
export const WEBCAST_SERVICE_TYPE_LIVE = 'live';

export const WEBCAST_SERVICE_TYPE_UI_NAMES = {
    [WEBCAST_SERVICE_TYPE_VOD]: 'VOD',
    [WEBCAST_SERVICE_TYPE_LIVE]: 'Live',
};

const initialWebcastsState = {
    webcastsList: null,
    publicWebcastsList: null,
    next: null,
    previous: null,
    count: 0,
    selectedWebcast: {},
    selectedPublicWebcast: {},
}

export const WebcastReducer = (state = initialWebcastsState, action) => {
    switch (action.type) {
        case GET_LIST_WEBCASTS_SUCCESS:
            return {
                ...state,
                webcastsList: action.payload.results,
                next: action.payload.next,
                previous: action.payload.previous,
                count: action.payload.count,
            }
        case GET_LIST_PUBLIC_WEBCASTS_SUCCESS:
            return {
                ...state,
                publicWebcastsList: action.payload.results,
                next: action.payload.next,
                previous: action.payload.previous,
                count: action.payload.count,
            }
        case GET_WEBCAST_SUCCESS:
            return {
                ...state,
                selectedWebcast: action.payload
            }
        case GET_PUBLIC_WEBCAST_SUCCESS:
            return {
                ...state,
                selectedPublicWebcast: action.payload
            }
        case CLEAR_SELECTED_WEBCAST:
            return {
                ...state,
                selectedWebcast: {}
            }
        default:
            return state;
    }
}