import Cubicle, { CUBICLE_VARIANT_STUDENT_QUEUE } from '../../theme/components/Cubicle';
import { Row, Col } from 'reactstrap';
import { Fragment, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { buildProcedureLabel, numberToOrdinal } from '../../utils';
import { CUBICLE_STATUS_CHECKOUT, CUBICLE_STATUS_UI_NAMES } from '../../redux/reducers/my_cubicle.reducer';
import ButtonCancelRequest from './ButtonCancelRequest';

const StudentCubicleQueue = ({ onGoingCubicleLog }) => {
    const myCubicleId = useSelector(state => state.myCubicle.id);
    const queueForFacultyVisit = useSelector(state => state.cubicleLogs.queueForFacultyVisit);
    const [cubiclePosition, setCubiclePosition] = useState(false);
    const [thisRequestFacultyVisitQueue, setThisRequestFacultyVisitQueue] = useState([]);

    useEffect(() => {
        let thisRequestFacultyVisitQueue = [];
        if (onGoingCubicleLog.accepted_by_faculty) {
            thisRequestFacultyVisitQueue = onGoingCubicleLog.accepted_by_faculty.username in queueForFacultyVisit ? queueForFacultyVisit[onGoingCubicleLog.accepted_by_faculty.username] : [];
            // Find my cubicle position in the queue
            const myCubiclePosition = thisRequestFacultyVisitQueue.findIndex(cubicleLog => cubicleLog.id === onGoingCubicleLog.id);
            if (myCubiclePosition !== -1) {
                setCubiclePosition(numberToOrdinal(myCubiclePosition + 1));
            } else {
                setCubiclePosition(false);
            }
        }
        setThisRequestFacultyVisitQueue(thisRequestFacultyVisitQueue);
    }, [onGoingCubicleLog, queueForFacultyVisit, myCubicleId]);

    return (
        <Fragment>
            <Row className='mt-2'>
                <Col>
                    <div className='float-end'>
                        <ButtonCancelRequest color='success' outline cubicleLogId={onGoingCubicleLog.id}>
                            Cancel request
                        </ButtonCancelRequest>
                    </div>
                    <div className='fs-5 fw-semibold mt-1'>{CUBICLE_STATUS_UI_NAMES[onGoingCubicleLog.cubicle_status]}</div>
                    {onGoingCubicleLog.cubicle_status === CUBICLE_STATUS_CHECKOUT && onGoingCubicleLog.procedures.length > 0 && (
                        <div className='fs-5 fw-semibold mt-1'>
                            {onGoingCubicleLog.procedures.map(procedure => (
                                buildProcedureLabel(procedure, onGoingCubicleLog.other_procedure)
                            )).join(', ')}
                        </div>
                    )}
                </Col>
            </Row>
            {thisRequestFacultyVisitQueue.length > 0 ? (
                <Fragment>
                    <Row className='my-1'>
                        <Col>
                            <div className='fs-5 lh-base'>
                                {cubiclePosition && (
                                    <Fragment>You are <span className='fw-semibold'>{cubiclePosition}</span> in the queue</Fragment>
                                )}
                            </div>
                        </Col>
                    </Row>
                    <Row className='my-3'>
                        <Col>
                            {thisRequestFacultyVisitQueue.map((cubicleLog) => (
                                <Cubicle
                                    key={cubicleLog.id}
                                    cubicleData={cubicleLog.cubicle}
                                    cubicleLogData={cubicleLog}
                                    variant={CUBICLE_VARIANT_STUDENT_QUEUE}
                                />
                            ))}
                            <hr />
                        </Col>
                    </Row>
                </Fragment>
            ) : (
                <Row className='mt-1 mb-3 pb-2'>
                    <Col>
                        <div className='fs-5 lh-base'>
                            Your request has not been selected by the faculty yet.
                            <hr />
                        </div>
                    </Col>
                </Row>
            )}
        </Fragment>
    );
};

export default StudentCubicleQueue;
