import { Fragment, useEffect, useState } from 'react';

// Layout Related Components
import Header from './Header';
import Navbar from './Navbar';
import Footer from './Footer';
import { PAGES_ABOUT, PAGES_CONTACT, PAGES_HELP, PAGES_PRIVACY, PAGES_TERMS, STUDENT_HOME, USER_HOME } from '../../navigation/ROUTE_CONSTANTS';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { USER_ROLE_STUDENT } from '../../redux/reducers/auth.reducer';
import { setNavigateTo } from '../../redux/actions/ui.actions';
import Announcement from '../../components/general/Announcement';
import { Container } from 'reactstrap';

export const MainLayout = (props) => {
    const location = useLocation();
    const history = useHistory();
    const dispatch = useDispatch();

    const uiNavigateTo = useSelector(state => state.ui.navigateTo);
    const userLogged = useSelector(state => state.users.userLogged);
    const announcements = useSelector(state => state.announcements.announcementList);

    const [headerDisplay, setHeaderDisplay] = useState(false);

    useEffect(() => {
        if (!userLogged || userLogged.role !== USER_ROLE_STUDENT) {
            setHeaderDisplay(false);
        } else {
            const pagesWithHeader = [
                USER_HOME, // It is rendered as STUDENT_HOME
                STUDENT_HOME,
            ];

            if (pagesWithHeader.includes(location.pathname)) {
                setHeaderDisplay(true);
            } else {
                setHeaderDisplay(false);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.pathname, userLogged]);

    useEffect(() => {
        if (uiNavigateTo && uiNavigateTo !== location.pathname) {
            history.push(uiNavigateTo);
            dispatch(setNavigateTo(null));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [uiNavigateTo]);

    return (
        <Fragment>
            <Navbar />
            {headerDisplay && <Header />}
            {announcements && announcements.length > 0 &&
                <Container>
                    {announcements.map(a => <div className='mt-4' key={a.id}><Announcement announcement={a} /></div>)}
                </Container>
            }

            {props.children}

            <Footer
                legend={'© Medical Clinic App. All rights reserved.'}
                items={[
                    { href: PAGES_ABOUT, text: 'About' },
                    { href: PAGES_PRIVACY, text: 'Privacy' },
                    { href: PAGES_TERMS, text: 'Terms & Conditions' },
                    { href: PAGES_HELP, text: 'Help' },
                    { href: PAGES_CONTACT, text: 'Contact' }
                ]}
            />
        </Fragment>
    );
}

export default MainLayout;
