import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Button, Col, Container, Row } from 'reactstrap';
import AppBreadcrumb from '../../components/ui/AppBreadcrumb';
import { WEBCASTS_ADMIN } from '../../navigation/ROUTE_CONSTANTS';
import { setNavigateTo } from '../../redux/actions/ui.actions';
import { deleteWebcast, getWebcast } from '../../redux/actions/webcasts.actions';


const WebcastsDelete = () => {
    const dispatch = useDispatch();

    const { webcastId } = useParams();
    const selectedWebcast = useSelector((state) => state.webcasts.selectedWebcast);

    useEffect(() => {
        document.title = process.env.REACT_APP_SITE_TITLE + ' - Delete webcast ';
    });

    useEffect(() => {
        if (webcastId) {
            dispatch(getWebcast(webcastId));
        }
    }, [webcastId, dispatch]);

    const handleCancel = () => {
        dispatch(setNavigateTo(WEBCASTS_ADMIN));
    }

    const handleDeleteWebcast = () => {
        dispatch(deleteWebcast(webcastId))
    }

    return (<Container className='mb-5 pb-5'>
        <Row>
            <Col>
                <AppBreadcrumb headText="Delete Webcast" crumbList={[{ linkTo: WEBCASTS_ADMIN, label: 'Webcast' }]} />
            </Col>
        </Row>
        <Row>
            <Col>
                <div className='vstack col-md-4 mx-auto mt-4 text-center'>
                    <h4 className='pt-2 pb-3 text-center'>Delete Webcast</h4>
                    <h5 className='mt-3 mb-4 pt-2 pb-2'>Are you sure you want to remove this webcast?</h5>
                    <p>Title:<strong> {selectedWebcast?.title}</strong></p>
                    <p>Author:<strong> {selectedWebcast?.author}</strong></p>
                    <p>Description:<strong> {selectedWebcast?.description}</strong></p>
                </div>
            </Col>
        </Row>
        <Row>
            <p className="mt-4 mb-4 text-center">
                <Button color={'success'} className=" me-2" onClick={handleDeleteWebcast}>
                    Delete Webcast
                </Button>
                <Button color={'secondary'} onClick={handleCancel} >
                    Cancel
                </Button>
            </p>
        </Row>
    </Container>)
}

export default WebcastsDelete;