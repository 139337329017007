import { Dropzone, FileItem } from "@dropzone-ui/react";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { uploadFileToDacast } from "../../services/webcastsService";

const WebcastDropzone = ({ webcastId, setUploadCompleted, uploading, name, setFieldValue, setUploading }) => {
    const dispatch = useDispatch();
    const [files, setFiles] = useState([]);
    const [uploadedFiles, setUploadedFiles] = useState([]);

    useEffect(() => {
        setFiles([]);
    }, [])

    useEffect(() => {
        if (uploading && webcastId) {
            // Select files for upload if not already uploaded
            let forUploadFiles = files.filter(file => {
                let fileFound = uploadedFiles.find(uploadedFile => uploadedFile.id === file.id);
                return !fileFound || !fileFound.uploaded
            });

            let currentUploadedFiles = [...uploadedFiles];
            uploadFileToDacast(webcastId, forUploadFiles, dispatch).then((filesSaved) => {
                // Add uploaded files to the list of uploaded files
                filesSaved.forEach(fileSaved => {
                    currentUploadedFiles.push(fileSaved);
                });
                setUploadedFiles(currentUploadedFiles);

            }).catch((error) => {
                console.log(error);
            }).finally(() => {
                let formFieldValue = '';
                // Dropzone is set to allow only one file
                if (currentUploadedFiles.length > 0) {
                    formFieldValue = currentUploadedFiles[0].external_video_title;
                }
                setFieldValue(name, formFieldValue);
                setUploading(false);
                setUploadCompleted(true);
            });
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [uploading, webcastId])

    const updateFiles = (incomingFiles) => {
        setFiles(incomingFiles);
    };

    const removeFile = (id) => {
        setFiles(files.filter((file) => file.id !== id));
        setUploadedFiles(uploadedFiles.filter((uploadedFile) => uploadedFile.id !== id));
    };

    return (
        <Dropzone
            minHeight={'8rem'}
            onChange={updateFiles}
            value={files}
            label='Drop your video here or click to upload'
            accept="video/*"
            maxFiles={1}
        >
            {files.length > 0 &&
                files.map((file) => (
                    <FileItem {...file} onDelete={removeFile} key={file.id} />
                ))}
        </Dropzone>
    )
}

export default WebcastDropzone;
