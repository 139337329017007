import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { AccordionItem, AccordionHeader, AccordionBody } from 'reactstrap';
import { CUBICLE_STATUS_CHECKOUT, CUBICLE_STATUS_HELP, CUBICLE_STATUS_SPECIAL_REQUEST, CUBICLE_STATUS_START_CHECK, CUBICLE_STATUS_UNOCCUPIED, REQUEST_STATUS_ON_QUEUE } from '../../redux/reducers/my_cubicle.reducer';
import Cubicle, { CUBICLE_VARIANT_FACULTY_HOME } from '../../theme/components/Cubicle';
import { cubiclesSortedByName } from '../../utils';

export const CUBICLE_GRID_TYPE_MY_LIST = 'my_list';
export const CUBICLE_GRID_TYPE_SPECIAL_REQUEST = 'special_request';
export const CUBICLE_GRID_TYPE_ASSISTANCE_REQUEST = 'assistance_request';
export const CUBICLE_GRID_TYPE_OCCUPIED = 'occupied';

// Head title map
const headTitleMap = {
    [CUBICLE_GRID_TYPE_MY_LIST]: 'My cubicle list',
    [CUBICLE_GRID_TYPE_SPECIAL_REQUEST]: 'Special requests',
    [CUBICLE_GRID_TYPE_ASSISTANCE_REQUEST]: 'Assistance requested',
    [CUBICLE_GRID_TYPE_OCCUPIED]: 'Occupied cubicles',
}

// Head subtitle map
const headSubtitleMap = {
    [CUBICLE_GRID_TYPE_MY_LIST]: 'When completed, select to clear the cubicle from your list',
    [CUBICLE_GRID_TYPE_SPECIAL_REQUEST]: 'Select from below to move to your cubicle list',
    [CUBICLE_GRID_TYPE_ASSISTANCE_REQUEST]: 'Select from below to move to your cubicle list',
    [CUBICLE_GRID_TYPE_OCCUPIED]: null,
}

// See All link map
const seeAllLinkMap = {
    [CUBICLE_GRID_TYPE_MY_LIST]: false,
    [CUBICLE_GRID_TYPE_SPECIAL_REQUEST]: false,
    [CUBICLE_GRID_TYPE_ASSISTANCE_REQUEST]: true,
    [CUBICLE_GRID_TYPE_OCCUPIED]: true,
}

const FacultyHomeCubicleGrid = ({ gridType }) => {

    const cubicleQueue = useSelector(state => state.cubicleQueue);
    const onGoingQueue = useSelector(state => state.cubicleLogs.onGoingQueue);
    const userLoggedUserName = useSelector(state => state.users.userLogged?.username);
    const [gridCubicles, setGridCubicles] = useState([]);

    useEffect(() => {
        let gridCubicles = [];

        if (gridType === CUBICLE_GRID_TYPE_OCCUPIED) {
            gridCubicles = cubicleQueue.filter(cubicle => (cubicle.cubicle_status !== CUBICLE_STATUS_UNOCCUPIED && cubicle.is_enabled));
            gridCubicles = gridCubicles.map(cubicle => ({ cubicleData: cubicle, cubicleLogData: cubicle.last_cubicle_log }));
        } else {
            gridCubicles = onGoingQueue.filter(cubicleLog => {
                let isCubicleInThisGrid = false;

                if (cubicleLog.accepted_by_faculty) {
                    // If the cubicle is accepted by this faculty, it should be in the my list, otherwise it is not displayed anywhere
                    if (cubicleLog.accepted_by_faculty.username === userLoggedUserName) {
                        isCubicleInThisGrid = (gridType === CUBICLE_GRID_TYPE_MY_LIST);
                    }
                } else if (
                    cubicleLog.cubicle_status === CUBICLE_STATUS_SPECIAL_REQUEST &&
                    cubicleLog.request_status === REQUEST_STATUS_ON_QUEUE
                ) {
                    isCubicleInThisGrid = (gridType === CUBICLE_GRID_TYPE_SPECIAL_REQUEST);

                } else if (
                    [CUBICLE_STATUS_START_CHECK, CUBICLE_STATUS_CHECKOUT, CUBICLE_STATUS_HELP].includes(cubicleLog.cubicle_status) &&
                    cubicleLog.request_status === REQUEST_STATUS_ON_QUEUE
                ) {
                    isCubicleInThisGrid = (gridType === CUBICLE_GRID_TYPE_ASSISTANCE_REQUEST);
                }

                return isCubicleInThisGrid;
            });
            gridCubicles = gridCubicles.map(cubicleLog => ({ cubicleData: cubicleLog.cubicle, cubicleLogData: cubicleLog }));
        }

        if (gridType === CUBICLE_GRID_TYPE_OCCUPIED) {
            gridCubicles.sort(cubiclesSortedByName);
        }

        setGridCubicles(gridCubicles);
    }, [cubicleQueue, gridType, onGoingQueue, userLoggedUserName]);

    return (
        <AccordionItem>
            <AccordionHeader targetId={gridType}>
                <div className='my-1 w-100'>
                    <span className='fw-semibold fs-5'>{headTitleMap[gridType]}</span>
                    {headSubtitleMap[gridType] &&
                        (<div className='d-none d-md-inline'>
                            <span className='mx-2'>|</span>
                            <span className='fs-8 text-grey'>{headSubtitleMap[gridType]}</span>
                        </div>)
                    }
                    {seeAllLinkMap[gridType] && (
                        <span className='float-end text-green mx-3'>
                            {'See all >'}
                        </span>
                    )}
                    {headSubtitleMap[gridType] &&
                        (<div className='d-block d-md-none'>
                            <span className='fs-8 text-grey'>{headSubtitleMap[gridType]}</span>
                        </div>)
                    }
                </div>
            </AccordionHeader>
            <AccordionBody accordionId={gridType} className='mt-3 mb-3'>
                {gridCubicles.map(cubicle => (
                    <Cubicle key={cubicle.cubicleData.id + '-' + cubicle.cubicleLogData?.id} cubicleData={cubicle.cubicleData} cubicleLogData={cubicle.cubicleLogData} variant={CUBICLE_VARIANT_FACULTY_HOME} gridType={gridType} />
                ))}
            </AccordionBody>
        </AccordionItem>
    )
};

export default FacultyHomeCubicleGrid;