import {createAction} from "@reduxjs/toolkit";

// Student exercise type
export const CREATE_STUDENT_EXERCISE = '[STUDENT_EXERCISE] Student exercise created';
export const UPDATE_STUDENT_EXERCISE = '[STUDENT_EXERCISE] Student exercise updated';
export const DELETE_STUDENT_EXERCISE = '[STUDENT_EXERCISE] Student exercise deleted';
export const GET_LIST_STUDENT_EXERCISES = '[STUDENT_EXERCISES] Getting list student exercises';
export const GET_LIST_STUDENT_EXERCISES_SUCCESS = '[STUDENT_EXERCISES] Getting student exercises success!';
export const GET_STUDENT_EXERCISES_DASHBOARD = '[STUDENT_EXERCISES] Getting student exercises dashboard';
export const GET_STUDENT_EXERCISES_DASHBOARD_SUCCESS = '[STUDENT_EXERCISES] Getting student exercises dashboard success!';
export const GET_ADMIN_LIST_STUDENT_EXERCISES = '[STUDENT_EXERCISES] Getting admin list student exercises';
export const GET_ADMIN_LIST_STUDENT_EXERCISES_SUCCESS = '[STUDENT_EXERCISES] Getting admin student exercises success!';
export const MOVE_TO_IN_PROGRESS_STUDENT_EXERCISE = '[STUDENT_EXERCISE] Moving to in progress';

// Student exercise actions
export const createStudentExercise = createAction(CREATE_STUDENT_EXERCISE);
export const updateStudentExercise = createAction(UPDATE_STUDENT_EXERCISE);
export const deleteStudentExercise = createAction(DELETE_STUDENT_EXERCISE);
export const getStudentExercises = createAction(GET_LIST_STUDENT_EXERCISES);
export const getStudentExercisesSuccess = createAction(GET_LIST_STUDENT_EXERCISES_SUCCESS);
export const getStudentExercisesDashboard = createAction(GET_STUDENT_EXERCISES_DASHBOARD);
export const getStudentExercisesDashboardSuccess = createAction(GET_STUDENT_EXERCISES_DASHBOARD_SUCCESS);
export const getAdminListStudentExercises = createAction(GET_ADMIN_LIST_STUDENT_EXERCISES);
export const getAdminListStudentExercisesSuccess = createAction(GET_ADMIN_LIST_STUDENT_EXERCISES_SUCCESS);
export const moveToInProgressStudentExercise = createAction(MOVE_TO_IN_PROGRESS_STUDENT_EXERCISE);
