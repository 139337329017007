import { faFile, faFileImage, faFilePdf, faFileVideo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Fragment } from 'react';
import { useDispatch } from 'react-redux';
import { Button, UncontrolledTooltip } from 'reactstrap';
import { disableOverlay, enableOverlay } from '../../redux/actions/ui.actions';
import { getOneFile } from '../../services/files.services';

const AttachmentLinks = ({ attachments }) => {
    const dispatch = useDispatch();

    const getIcon = (attachment) => {
        let icon = faFile;
        if (attachment.mime_type === 'application/pdf') {
            icon = faFilePdf;
        } else if (attachment.mime_type.match(/^image\//)) {
            icon = faFileImage;
        } else if (attachment.mime_type.match(/^video\//)) {
            icon = faFileVideo;
        }

        return icon;
    }

    const launchWindowOpen = (fileUrl) => {
        window.open(fileUrl, '_blank');
    }

    const handleOpenFile = (e, attachment) => {
        e.preventDefault();
        let element = e.currentTarget;
        // Store file url in data attribute
        let fileUrl = element['data-file-url'] || false;
        if (fileUrl) {
            launchWindowOpen(fileUrl);
        } else {
            dispatch(enableOverlay('Retrieving file ...'));
            getOneFile(attachment.file_uuid).then((response) => {
                element['data-file-url'] = response.file_url;
                launchWindowOpen(response.file_url);
            }).finally(() => {
                dispatch(disableOverlay());
            });
        }
    }

    return (
        <Fragment>
            {attachments && attachments.map((attachment) => (
                <Fragment key={attachment.file_uuid}>
                    <Button color='secondary' outline className="m-2" id={'b-image-' + attachment.file_uuid}
                        onClick={(e) => handleOpenFile(e, attachment)}
                    >
                        <FontAwesomeIcon size='3x' icon={getIcon(attachment)} />
                    </Button>
                    <UncontrolledTooltip target={'b-image-' + attachment.file_uuid} >
                        {attachment.file_name}
                    </UncontrolledTooltip>
                </Fragment>
            ))}
        </Fragment>
    );
}

export default AttachmentLinks;