import axios from 'axios';
import { buildPaginationUrlParams } from '../utils/index';
import { API_ANNOUNCEMENTS, API_ANNOUNCEMENTS_ADMIN } from './API_CONSTANTS';


export const getAdminListAnnouncements = (paginationParams = {}) => {
    paginationParams = {
        ...paginationParams,
        page: 1,
        limit: 99,
    };

    const url = API_ANNOUNCEMENTS_ADMIN + buildPaginationUrlParams(paginationParams);

    return new Promise((resolve, reject) => {
        try {
            axios.get(url)
                .then((res) => {
                    console.log('Announcements loaded: ', res);
                    resolve(res.data);
                })
                .catch((err) => {
                    console.log('Get announcements error: ', err);
                    reject('Get announcements error');
                });
        } catch (error) {
            console.error('Get announcements error: ', error);
            reject('Get announcements error');
        }
    });

}

export const getListAnnouncements = (paginationParams = {}) => {
    paginationParams = {
        ...paginationParams,
        page: 1,
        limit: 99,
    };

    const url = API_ANNOUNCEMENTS + '/unread' + buildPaginationUrlParams(paginationParams);

    return new Promise((resolve, reject) => {
        try {
            axios.get(url)
                .then((res) => {
                    console.log('Announcements loaded: ', res);
                    resolve(res.data);
                })
                .catch((err) => {
                    console.log('Get announcements error: ', err);
                    reject('Get announcements error');
                });
        } catch (error) {
            console.error('Get announcements error: ', error);
            reject('Get announcements error');
        }
    });

}

export const getOneAnnouncement = (announcementId) => {
    const url = API_ANNOUNCEMENTS_ADMIN + '/' + announcementId;

    return new Promise((resolve, reject) => {
        try {
            axios.get(url)
                .then((res) => {
                    console.log('Announcement loaded: ', res);
                    resolve(res.data);
                })
                .catch((err) => {
                    console.log('Get one announcement error: ', err);
                    reject('Get one announcement error');
                });
        } catch (error) {
            console.error('Get one announcement error: ', error);
            reject('Get one announcement error');
        }
    });
}

export const hideAnnouncement = ( payload) => {
    const url = API_ANNOUNCEMENTS + '/mark-as-read/' + payload;

    return new Promise((resolve, reject) => {
        try {
            axios.patch(url)
                .then((res) => {
                    console.log('Announcement hidden: ', res);
                    resolve(res.data);
                })
                .catch((err) => {
                    console.log('Announcement hidden error: ', err);
                    reject('Announcement hidden error');
                });
        } catch (error) {
            console.error('Announcement hidden error: ', error);
            reject('Announcement hidden error');
        }
    });
}

export const createAnnouncement = (announcement) => {
    const url = API_ANNOUNCEMENTS_ADMIN;

    return new Promise((resolve, reject) => {
        try {
            axios.post(url, announcement)
                .then((res) => {
                    console.log('Announcement created: ', res);
                    resolve(res.data);
                })
                .catch((err) => {
                    console.log('Create announcement error: ', err);
                    reject('Create announcement error');
                });
        } catch (error) {
            console.error('Create announcement error: ', error);
            reject('Create announcement error');
        }
    });
}

export const updateAnnouncement = (announcement) => {
    const url = API_ANNOUNCEMENTS_ADMIN + '/' + announcement.id;

    return new Promise((resolve, reject) => {
        try {
            axios.put(url, announcement)
                .then((res) => {
                    console.log('Announcement updated: ', res);
                    resolve(res.data);
                })
                .catch((err) => {
                    console.log('Update announcement error: ', err);
                    reject('Update announcement error');
                });
        } catch (error) {
            console.error('Update announcement error: ', error);
            reject('Update announcement error');
        }
    });
}

export const removeAnnouncement = (announcementId) => {
    const url = API_ANNOUNCEMENTS_ADMIN + '/' + announcementId;

    return new Promise((resolve, reject) => {
        try {
            axios.delete(url)
                .then((res) => {
                    console.log('Announcement removed: ', res);
                    resolve(res);
                })
                .catch((err) => {
                    console.log('Remove announcement error: ', err);
                    reject('Remove announcement error');
                });
        } catch (error) {
            console.error('Remove announcement error: ', error);
            reject('Remove announcement error');
        }
    });
}
